import { ApolloQueryResult, OperationVariables, WatchQueryOptions } from '@apollo/client';
import { useEffect, useMemo, useRef, useSyncExternalStore } from 'react';

import client from 'src/services/apollo/client';

/**
 * Custom useQuery hook with a selector.
 * The component will re-render only when the selector result changes.
 * @example
 * const productName = useQuerySelector({
 *  query: ProductBySlugDocument,
 *  variables: { slug: 'cycle' },
 *  selector: result => result?.data.product?.name,
 * });
 */
export const useQuerySelector = <TData, TSnapshot extends Primitive, TVariables extends OperationVariables>({
  selector,
  skip,
  variables,
  ...options
}: WatchQueryOptions<TVariables, TData> & {
  selector: (result?: ApolloQueryResult<TData | undefined>) => TSnapshot;
  skip?: boolean;
}) => {
  const selectorRef = useRef(selector);

  useEffect(() => {
    selectorRef.current = selector;
  }, [selector]);

  const {
    subscribe, getSnapshot,
  } = useMemo(() => {
    const observableQuery = skip ? null : client.watchQuery({
      variables,
      ...options,
    });
    return {
      subscribe: (next: VoidFunction) => {
        const subscription = observableQuery?.subscribe({ next });
        return () => subscription?.unsubscribe();
      },
      getSnapshot: () => {
        const result = observableQuery?.getCurrentResult();
        return selectorRef.current(result);
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...Object.values(variables ?? {}), ...Object.values(options), skip]);

  return useSyncExternalStore(subscribe, getSnapshot);
};
