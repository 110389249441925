import { MeFragment, MeNodeDocument } from '@cycle-app/graphql-codegen';

import { useGetAuth } from 'src/reactives/auth.reactive';
import { useInitialLoading } from 'src/reactives/initial.reactive';
import { extract } from 'src/types/graphql.types';

import { useQuerySelector } from './useQuerySelector';

export const useMeSelector = <T extends Primitive>(
  selector: (product?: MeFragment) => T,
) => {
  const initialLoading = useInitialLoading();
  const { userId } = useGetAuth();
  return useQuerySelector({
    query: MeNodeDocument,
    variables: { id: userId as string },
    skip: initialLoading || !userId,
    selector: result => selector(extract('Me', result?.data?.node)),
  });
};

export const useMeError = () => {
  const initialLoading = useInitialLoading();
  const { userId } = useGetAuth();
  return useQuerySelector({
    query: MeNodeDocument,
    variables: { id: userId as string },
    skip: initialLoading || !userId,
    selector: result => result?.error?.message,
  });
};
