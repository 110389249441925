/**
 * This file is generated by running `yarn workspace @cycle-app/ui build:icons`
 */
import brandAirtable from './icons/brand.airtable.icon.svg?no-inline';
import brandFigma from './icons/brand.figma.icon.svg?no-inline';
import brandGithub from './icons/brand.github.icon.svg?no-inline';
import brandHubspot from './icons/brand.hubspot.icon.svg?no-inline';
import brandNotion from './icons/brand.notion.icon.svg?no-inline';
import brandSlack from './icons/brand.slack.icon.svg?no-inline';
import outlineSettings from './icons/outline.settings.icon.svg?no-inline';
import outlineTriShapes from './icons/outline.triShapes.icon.svg?no-inline';
import solidSettings from './icons/solid.settings.icon.svg?no-inline';

export const allIconsMap = {
  'brand/airtable': brandAirtable,
  'brand/figma': brandFigma,
  'brand/github': brandGithub,
  'brand/hubspot': brandHubspot,
  'brand/notion': brandNotion,
  'brand/slack': brandSlack,
  'outline/settings': outlineSettings,
  'outline/triShapes': outlineTriShapes,
  'solid/settings': solidSettings,
} as const;
