/**
 * Useful for creating variants based on a theme option.
 * It's a more visual way to have conditional classes based on a theme option.
 *
 * @example
 * const heroClasses = useThemeVariant(heroSection)
 *
 * <div
 *  className={heroClasse({
 *    SM: 'text-xl',
 *    MD: 'text-2xl',
 *  })}
 * />
 */
export function useThemeVariant<T extends string, U extends { [key in T]?: string }>(themeOption: T) {
  return function (theme: U) {
    return theme[themeOption];
  };
}
