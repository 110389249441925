import { useQuery } from '@apollo/client';
import { FetchProductIntegrationsDocument, IntegrationType, LinearProjectFragment } from '@cycle-app/graphql-codegen';
import { createContext, useContextSelector, ContextSelector, useHasParentContext } from '@fluentui/react-context-selector';
import keyBy from 'lodash/keyBy';
import { FC, useMemo } from 'react';

import { useLinearProjects } from 'src/hooks/api/queries/integrations/useLinearProjects';
import { extract } from 'src/types/graphql.types';

import { useIsRoadmapsEnabled } from '../hooks';

type WorkspaceProviderProps = {
  productId: string;
  productSlug: string;
  productKey: string;
  isChangelogOnly: boolean;
};

type WorkspaceContextValue = {
  productId: string;
  productSlug: string;
  productKey: string;
  isLinearInstalled: boolean;
  linearIntegrationId: string | null;
  linearProviderId: string | null;
  linearProjects: Record<string, LinearProjectFragment>;
  isRoadmapsEnabled: boolean;
  isChangelogOnly: boolean;
};

const WorkspaceContext = createContext<WorkspaceContextValue>({} as WorkspaceContextValue);

export const WorkspaceProvider: FC<React.PropsWithChildren<WorkspaceProviderProps>> = ({
  productId, productSlug, productKey, isChangelogOnly, children,
}) => {
  const integrationsQuery = useQuery(FetchProductIntegrationsDocument, { variables: { productId } });
  const isRoadmapsEnabled = useIsRoadmapsEnabled();

  const linearIntegration = extract('Product', integrationsQuery.data?.node)?.integrations.edges
    .find(e => e.node.type === IntegrationType.Linear)?.node;
  const isLinearInstalled = !!linearIntegration?.provider;
  const linearProjectsQuery = useLinearProjects(linearIntegration?.id, '', { skip: !isLinearInstalled });

  const value = useMemo<WorkspaceContextValue>(() => {
    return {
      productId,
      productSlug,
      productKey,
      isLinearInstalled,
      linearIntegrationId: linearIntegration?.id ?? null,
      linearProviderId: linearIntegration?.provider?.id ?? null,
      linearProjects: keyBy(linearProjectsQuery.projects, 'id'),
      isRoadmapsEnabled,
      isChangelogOnly,
    };
  }, [
    productId, productKey, productSlug,
    isLinearInstalled, linearProjectsQuery.projects,
    linearIntegration?.id, linearIntegration?.provider?.id,
    isRoadmapsEnabled, isChangelogOnly,
  ]);

  return (
    <WorkspaceContext.Provider value={value}>
      {children}
    </WorkspaceContext.Provider>
  );
};

export const useWorkspaceContext = <T = unknown>(selector: ContextSelector<WorkspaceContextValue, T>) => {
  const isWrappedWithContext = useHasParentContext(WorkspaceContext);
  if (!isWrappedWithContext) throw new Error('useWorkspaceContext must be used within a WorkspaceProvider');
  return useContextSelector(WorkspaceContext, selector);
};

export const useIsChangelogOnly = () => useWorkspaceContext(ctx => ctx.isChangelogOnly);