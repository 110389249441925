import { ProductBySlugDocument, ProductBySlugQuery } from '@cycle-app/graphql-codegen';

import { useProductSlug } from 'src/hooks/usePathParams';
import { useInitialLoading } from 'src/reactives/initial.reactive';

import { useQuerySelector } from './useQuerySelector';


export const useProductSelector = <T extends Primitive>(
  selector: (product?: ProductBySlugQuery['product']) => T,
) => {
  const initialLoading = useInitialLoading();
  const productSlug = useProductSlug();
  return useQuerySelector({
    query: ProductBySlugDocument,
    variables: { slug: productSlug as string },
    skip: initialLoading || !productSlug,
    selector: result => selector(result?.data?.product),
  });
};

export const useProductLoading = () => {
  const initialLoading = useInitialLoading();
  const productSlug = useProductSlug();
  return useQuerySelector({
    query: ProductBySlugDocument,
    variables: { slug: productSlug as string },
    skip: initialLoading || !productSlug,
    selector: result => result?.loading,
  });
};
