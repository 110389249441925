import data from '@emoji-mart/data';

import { COMMANDS } from 'src/constants/editor.constants';

export const DEFAULT_EMOJI = 'large_blue_diamond';

export const getNativeEmoji = (emojiKey: string | null | undefined) => {
  if (!emojiKey) return '';

  const emojiKeyParsed = emojiKey.replaceAll(COMMANDS.EMOJI, '');

  // @ts-ignore - emoji-mart/data types are incorrect
  const emoji = data.emojis[emojiKeyParsed];
  
  return emoji?.skins[0]?.native ?? '';
};
