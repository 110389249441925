import { Icon } from '@cycle-app/ui';
import { IntercomIcon, EmailIcon, ZapierIcon, ChromeIcon } from '@cycle-app/ui/icons';
import styled from 'styled-components';

const FeedbackIcons = styled.div`
  display: inline-flex;
  gap: 8px;
  vertical-align: middle;
  margin-left: 8px;

  svg {
    width: 14px;
  }
`;

export const featuresYC = [
  {
    id: 'feedback',
    name: (
      <div style={{ whiteSpace: 'nowrap' }}>
        <span>Plug all your feedback sources</span>
        <FeedbackIcons>
          <Icon name="brand/slack" className="size-4" />
          <IntercomIcon />
          <EmailIcon />
          <ZapierIcon />
          <ChromeIcon />
        </FeedbackIcons>
      </div>
    ),
    tooltip: null,
  },
  {
    id: 'ai',
    name: 'Calibrate your Cycle AI for accurate insight extraction (on autopilot)',
    tooltip: null,
  },
  {
    id: 'dashboard',
    name: 'Customer voice dashboard with insights you’ve never seen before',
    tooltip: null,
  },
  {
    id: 'rt',
    name: 'Real-time collaboration features with unlimited free collaborators',
    tooltip: null,
  },
  {
    id: 'sources',
    name: 'Two-way sync with Linear',
    tooltip: null,
  },
  {
    id: 'transcripts',
    name: 'Unlimited call recordings & transcripts for all members',
    tooltip: null,
  },
  {
    id: 'support',
    name: 'White-glove support (we do reply super fast)',
    tooltip: null,
  },
];
