import { IntegrationType } from '@cycle-app/graphql-codegen/generated';
import { Button, ActionButton, Icon } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';
import { ComponentProps } from 'react';

import { useSlackTour } from 'src/hooks';
import { useProductIntegrations } from 'src/hooks/api/useProductIntegrations';
import { useInstallIntegration } from 'src/hooks/useInstallIntegration';
import { useGetPermission } from 'src/reactives/permission.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { setUserPreferences, useUserPreferencesValue } from 'src/reactives/userPreferences.reactive';

import { Container, Slack, Text, Title, LearnMoreButton } from './SlackInstallCard.styles';

type Props = ComponentProps<typeof Container>;

export const SlackInstallCard = (props: Props) => {
  const { canReadSettings } = useGetPermission();
  const isMobile = useIsMobile();
  if (!canReadSettings || isMobile) return null;
  return <SlackInstallable {...props} />;
};

const SlackInstallable = (props: Props) => {
  const {
    isLoading: isLoadingIntegrations, getIntegration,
  } = useProductIntegrations();
  const install = useInstallIntegration();
  const slackIntegration = getIntegration(IntegrationType.Slack);
  const isSlackInstalled = !!slackIntegration?.provider;
  const { open: openSlackLearnMoreTour } = useSlackTour();
  const { isSlackInstallCardVisible } = useUserPreferencesValue();

  if (isLoadingIntegrations || isSlackInstalled || !isSlackInstallCardVisible) return null;

  return (
    <Container {...props}>
      <Slack>
        <Icon name="brand/slack" className="size-5" />
      </Slack>

      <Text>
        <Title>Capture feedback from Slack</Title>
        <p>Connect Cycle to Slack to supercharge your feedback capture flows</p>
      </Text>

      <LearnMoreButton
        size="M"
        variant="secondary"
        onClick={openSlackLearnMoreTour}
      >
        Learn more
      </LearnMoreButton>

      <Button
        size="M"
        onClick={() => {
          if (!slackIntegration?.type) return;
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          install(slackIntegration.type, slackIntegration);
        }}
      >
        Install Slack
      </Button>

      <ActionButton
        onClick={() => setUserPreferences({ isSlackInstallCardVisible: false })}
        style={{ marginLeft: 8 }}
      >
        <CloseIcon />
      </ActionButton>
    </Container>
  );
};
