import { Input, ActionButton, Tooltip } from '@cycle-app/ui';
import { PenIcon, CloseIcon, AddIcon, QuestionOutlineIcon } from '@cycle-app/ui/icons';
import { isUrl } from '@cycle-app/utilities';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { useChangelogBuilderFormContext } from 'src/hooks/releases/useChangelogBuilderFormContext';

import { PortalModal } from '../PortalModal';
import { Section } from './ChangelogBuilderSidebar.styles';
import { AddButton } from './ChangelogBuilderSocialLink.styles';
import { Title } from './LabelWithReset.styles';

export const ChangelogBuilderSocialLink = () => {
  const {
    watch, setValue,
  } = useChangelogBuilderFormContext();
  const text = watch('socialLinkText');
  const [showEditModal, setShowEditModal] = useState(false);

  const handleRemoveLink = () => {
    setValue('socialLinkText', '');
    setValue('socialLinkURL', '');
  };

  return (
    <Section>
      {showEditModal && <ChangelogBuilderSocialLinkModal onClose={() => setShowEditModal(false)} />}

      <div className="flex items-center gap-2 text-body font-medium">
        Link
        <Tooltip content="Add any link to help people find you" withWrapper={false} placement="top">
          <QuestionOutlineIcon className="text-disabled" />
        </Tooltip>
      </div>
      {text ? (
        <div className="mt-1.5 flex items-center gap-1 rounded-lg bg-grey-200 p-1.5 pl-3 dark:bg-grey-800">
          <span className="mr-auto truncate text-body">{text}</span>

          <ActionButton
            className="shrink-0"
            tooltip="Edit"
            tooltipPlacement="top"
            onClick={() => setShowEditModal(true)}
          >
            <div className="relative flex size-3.5 items-center justify-center">
              <PenIcon size={16} className="absolute" />
            </div>
          </ActionButton>
          <ActionButton
            className="shrink-0"
            tooltip="Remove link"
            tooltipPlacement="top"
            onClick={handleRemoveLink}
          >
            <div className="flex size-3.5 items-center justify-center">
              <CloseIcon size={12} />
            </div>
          </ActionButton>
        </div>
      ) : (
        <AddButton
          onClick={() => setShowEditModal(true)}
          variant="nospace"
          iconStart={<AddIcon />}
          size="M"
        >
          Add link
        </AddButton>
      )}
    </Section>
  );
};

const ChangelogBuilderSocialLinkModal = ({ onClose }: { onClose: VoidFunction }) => {
  const {
    watch, setValue,
  } = useChangelogBuilderFormContext();
  const socialLinkURL = watch('socialLinkURL');
  const socialLinkText = watch('socialLinkText');

  const {
    register, handleSubmit, formState: { errors },
  } = useForm({
    defaultValues: {
      socialLinkURL: socialLinkURL,
      socialLinkText: socialLinkText || 'Follow us on Twitter',
    },
  });

  const handleFormSubmit = handleSubmit((data) => {
    setValue('socialLinkURL', data.socialLinkURL);
    setValue('socialLinkText', data.socialLinkText);
    onClose();
  });

  const isEditing = socialLinkURL || socialLinkText;

  return (
    <PortalModal className="w-96" hide={onClose}>
      <form onSubmit={handleFormSubmit}>
        <div className="space-y-1">
          <Title>URL</Title>
          <Input
            autoFocus
            placeholder="https://example.com"
            error={errors.socialLinkURL?.message}
            {...register('socialLinkURL', {
              required: 'Required',
              validate: (newUrl) => {
                if (!newUrl) return true;
                const isValid = isUrl(newUrl, { strict: false });
                return isValid || 'Invalid URL';
              },
            })}
          />
        </div>

        <div className="mt-4 space-y-1">
          <Title>Title</Title>
          <Input
            placeholder="Follow us on Twitter"
            error={errors.socialLinkText?.message}
            {...register('socialLinkText', { required: 'Title is required' })}
          />
        </div>

        <div className="mt-4 flex items-center justify-end">
          <button className="btn-primary">
            {isEditing ? 'Save' : 'Add link'}
          </button>
        </div>
      </form>
    </PortalModal>
  );
};
