import { IntegrationFullFragment, IntegrationType } from '@cycle-app/graphql-codegen';
import { Tooltip } from '@cycle-app/ui';
import { twJoin } from 'tailwind-merge';

import { UninstallIntegrationModal } from 'src/app/Main/Settings/SettingsIntegrations/UninstallIntegrationModal';
import { integrationsDataMap } from 'src/constants/integrations.constants';
import { useInstallIntegration, useOptimizedBooleanState } from 'src/hooks';
import { executeIntegration } from 'src/utils/integrations.utils';

import { FrontEndIntegration, Integration } from '../../types/integrations.types';

type Props = {
  installed: {
    order: number;
    integration?: IntegrationFullFragment | undefined;
    integrationType: Integration;
  }[];
};

export const InboxZeroSources = ({ installed }: Props) => {
  const [isUninstallModalOpened, uninstallModal] = useOptimizedBooleanState(false);
  const install = useInstallIntegration();
  return (
    <div
      className="mt-10 flex"
    >
      {installed.map((source, index) => {
        const data = integrationsDataMap[source.integrationType];
        let bg = 'dark:bg-grey-800 bg-grey-100';
        if (source.integrationType === FrontEndIntegration.CYCLE) bg = 'bg-blue-100';
        if (source.integrationType === IntegrationType.Zapier) bg = 'bg-[#FFF1EB]';
        return (
          <div
            key={source.integrationType}
            style={{
              zIndex: index,
              marginLeft: index > 0 ? '-4px' : undefined,
            }}
          >
            <Tooltip
              content={source.integrationType === IntegrationType.Meeting
                ? `Click to ${source.integration?.provider?.id ? 'remove' : 'install'}`
                : data.action ?? data.label}
              title={source.integrationType === IntegrationType.Meeting
                ? data.action ?? data.label
                : undefined}
              placement="top"
              withPortal
            >
              <button
                style={index < installed.length - 1 ? {
                  maskImage: 'radial-gradient(circle at 30px 12px,transparent 12px,white 12px)',
                } : undefined}
                className={twJoin('flex size-6 cursor-pointer items-center justify-center rounded-full', bg)}
                type="button"
                onClick={() => {
                  if (source.integrationType === IntegrationType.Meeting) {
                    if (source.integration?.provider) {
                      uninstallModal.setTrueCallback();
                    } else {
                      // eslint-disable-next-line @typescript-eslint/no-floating-promises
                      install(IntegrationType.Meeting, source.integration);
                    }
                    return;
                  }
                  executeIntegration(source.integrationType, source.integration);
                }}
              >
                <div className="flex size-3 flex-none items-center justify-center *:max-w-full">
                  {data.icon}
                </div>
              </button>
              {source.integrationType === IntegrationType.Meeting && source.integration?.id && (
                <UninstallIntegrationModal
                  type={IntegrationType.Meeting}
                  id={source.integration.id}
                  isVisible={isUninstallModalOpened}
                  hide={uninstallModal.setFalseCallback}
                />
              )}
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
};
