import {
  CustomerFragment,
  DocChildFragment,
  DocSourceFragment,
  DoctypeFragment,
  DoctypeType,
} from '@cycle-app/graphql-codegen';
import { toShortLocaleDateString, capitalize } from '@cycle-app/utilities';
import uniq from 'lodash/uniq';

import { getDocType } from 'src/reactives/docTypes.reactive';
import { getDocFromCache } from 'src/utils/cache.utils';

import { getFeedbackCustomerName } from './customers.util';

export const getEditCoverAction = (coverUrl: string | undefined): string => (coverUrl ? 'Update cover' : 'Add cover');

export const canHaveParent = (docType?: DoctypeFragment) => (docType?.parents?.edges ?? []).length > 0;

export const getDocKey = (productKey?: string | null, docPublicId?: number | null) => (
  productKey && docPublicId
    ? `${productKey}-${docPublicId}`
    : undefined
);

export const isCycleFileSource = (source?: Partial<DocSourceFragment> | null) => source?.__typename === 'SourceCycle' && source.fileSize != null;

export const isCycleWithoutFileNorUrlSource = (source?: Partial<DocSourceFragment> | null) => {
  if (source?.__typename !== 'SourceCycle') return false;

  /**
   * The Api is putting the origin url as source by default when we leave the
   * source as `undefined`
   */
  return source.url === window.location.origin && !source.fileSize;
};

export const shouldShowSource = (
  doc?: Partial<DocChildFragment>,
  docType?: DoctypeFragment,
) => {
  if (isCycleFileSource(doc?.source)) return true;
  return !doc?.isDraft &&
    !!doc?.source &&
    !!docType?.customer &&
    docType?.type === DoctypeType.Feedback;
};

export type MinimalDocDocType = { doctype: { id: string } };

export const getIsAtLeastOneInsightFromDocIds = (
  docIds: string[],
  getDoc: (docId: string) => MinimalDocDocType | undefined,
  docTypes: Record<string, DoctypeFragment>,
): boolean => (docIds.length ? docIds.some(id => docTypes[getDoc(id)?.doctype.id ?? '']?.type === DoctypeType.Insight) : false);

export const getIsAtLeastOneFeedbackFromDocIds = (
  docIds: string[],
  getDoc: (docId: string) => MinimalDocDocType | undefined,
  docTypes: Record<string, DoctypeFragment>,
): boolean => (docIds.length ? docIds.some(id => docTypes[getDoc(id)?.doctype.id ?? '']?.type === DoctypeType.Feedback) : false);

export const getIsAtLeastOneInsightFromDocs = (
  docs: MinimalDocDocType[],
  docTypes: Record<string, DoctypeFragment>,
): boolean => (docs.length ? docs.some(doc => docTypes[doc.doctype.id]?.type === DoctypeType.Insight) : false);

export const canHaveInsightChildren = (
  docType?: DoctypeFragment,
) => docType?.type === DoctypeType.Feedback || docType?.children?.edges.some(edge => edge.node.type === DoctypeType.Insight);

export const canHaveChildren = (
  docType?: DoctypeFragment,
) => !!docType?.children?.edges.filter(e => e.node.type !== DoctypeType.Insight).length;

export const getDefaultFeedbackTitle = (customer?: CustomerFragment | null) => {
  const name = getFeedbackCustomerName(customer);
  if (!name) return null;
  return `Feedback from ${name} (${toShortLocaleDateString()})`;
};

export const getDefaultChromeFeedbackTitle = (customer?: Partial<CustomerFragment> | null) => {
  const name = getFeedbackCustomerName(customer);
  if (!name) return null;
  return `Feedback from ${name} in Chrome (${toShortLocaleDateString()})`;
};

export const insightName = ({
  uppercase, plural,
}: {
  uppercase?: boolean;
  plural?: boolean;
} = {}) => {
  let name = 'quote';
  if (plural) name += 's';
  return uppercase ? capitalize(name) : name;
};

/**
 * Get the doc display name for a list of docs
 * @example docName(['docId1', 'docId2']) => 'features'
 * @example docName(['docId1']) => 'feature'
 * @example docName(['feedbackId1']) => 'feedback doc'
 * @example docName(['insightId1']) => 'quote'
 * @example docName(['bugId1', 'bugId2]) => 'bugs'
 */
export const docName = (docIds: string[]) => {
  const names = uniq(docIds.map(docId => {
    const doc = getDocFromCache(docId);
    // 'feature' is the generic doc name
    const name = getDocType(doc?.doctype.id)?.name.toLowerCase() ?? 'feature';
    // 'insight' will be replaced by 'quote'
    if (name === 'insight') return 'quote';
    // 'feedback' is a special case
    if (name === 'feedback') return 'feedback doc';
    return name;
  }));
  // In case of multiple doc types, we use the generic name 'feature'
  const name = names.length === 1 ? names[0] : 'feature';

  // Plural: we assume that adding an 's' is correct
  return docIds.length > 1 ? `${name}s` : name;
};
