import { DocBaseFragment, DoctypeType } from '@cycle-app/graphql-codegen';
import { Badge } from '@cycle-app/ui';

import { useDocChildren, useDocInsightsCount } from 'src/hooks';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { getInsightChildren } from 'src/utils/docType.util';

export const InsightsCount = ({
  doc, className, 
}: {
  doc: DocBaseFragment;
  className?: string;
}) => {
  const docType = useGetDocType(doc.doctype.id);
  const insightChildrenDocType = getInsightChildren(docType);

  const {
    count: docInsightsCount, isLoading: isInsightsCountLoading,
  } = useDocInsightsCount({
    docId: doc.id,
    skip: docType?.type !== DoctypeType.Feedback,
  });

  const {
    count: docInsightChildrenCount, loading: isInsightChildrenCountLoading,
  } = useDocChildren({
    docId: doc.id,
    doctypeId: insightChildrenDocType?.id,
  });

  const insightCountLoading = isInsightsCountLoading || isInsightChildrenCountLoading;

  if (insightCountLoading) return null;

  let insightCount = insightChildrenDocType ? docInsightChildrenCount : docInsightsCount;
  if (insightCount < 0) insightCount = 0;

  return (
    <Badge className={className}>
      {insightCount}
    </Badge>
  );
};
