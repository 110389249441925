import { useSubscription } from '@apollo/client';
import {
  CreatedDocDocument,
  CreatedDocSubscription,
  CreatedDocSubscriptionVariables,
} from '@cycle-app/graphql-codegen';

import client from 'src/services/apollo/client';

import { useMaybeMeV2 } from './useMe';

export const useCreatedDocSubscription = (productId?: string | null) => {
  const { me } = useMaybeMeV2();
  return useSubscription<CreatedDocSubscription, CreatedDocSubscriptionVariables>(
    CreatedDocDocument,
    {
      variables: {
        productId: productId as string,
      },
      onData: ({ data: { data } }) => {
        const doc = data?.createdDoc;
        if (!me || !doc) return;
        client.cache.modify({
          id: client.cache.identify(me),
          fields: {
            docImport: (refs, { toReference }) => {
              const publishedRef = toReference(doc);
              return ({
                ...refs,
                edges: refs.edges.filter((edge: any) => edge.node?.__ref !== publishedRef?.__ref),
              });
            },
          },
        });
      },
    },
  );
};
