import { ReleasePublicStatus } from '@cycle-app/graphql-codegen';
import { Spinner } from '@cycle-app/ui';
import { motion } from 'framer-motion';
import { twJoin } from 'tailwind-merge';

import { useRelease } from 'src/hooks/releases/useRelease';
import { useReleasePublicStatusUpdate } from 'src/hooks/releases/useReleasePublicStatusUpdate';

export const ReleaseNotePublishedBanner = ({ releaseId }: { releaseId: string }) => {
  const { release } = useRelease(releaseId);

  const {
    publish, isPublishLoading,
  } = useReleasePublicStatusUpdate(releaseId);

  if (!release || release.publicStatus !== ReleasePublicStatus.Editing) return null;

  const handleClick = async () => {
    if (isPublishLoading) return;
    await publish();
  };

  return (
    <motion.div
      // eslint-disable-next-line max-len
      className="flex items-center justify-center gap-4 overflow-y-clip border-b border-grey-200 bg-blue-100 py-1 text-center text-primary dark:border-grey-850 dark:bg-grey-900 dark:text-secondary"
      animate={{ y: [2, 0] }}
      transition={{ duration: 0.1 }}
    >
      Changes have been made.
      <button
        // eslint-disable-next-line max-len
        className="relative flex cursor-pointer items-center gap-1 rounded p-0 text-sm font-medium text-cycle outline-offset-2 hover:text-cycle/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-cycle dark:text-primary dark:hover:text-primary/80"
        onClick={handleClick}
      >
        Publish changes
        <Spinner className={twJoin('absolute -right-4 size-3 transition', !isPublishLoading && 'opacity-0')} />
      </button>
    </motion.div>
  );
};
