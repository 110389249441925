import {
  AddNewDocDocument,
  AddNewDocMutationVariables,
  BoardConfigDocument,
  GroupNodeDocument,
  DoctypeRelativeFragment,
  CreateDocPosition,
  AddNewDocMutation,
  AddNewDocAttributeValue,
} from '@cycle-app/graphql-codegen';
import uniqBy from 'lodash/uniqBy';

import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useAssigneeFromBoardConfig } from 'src/hooks/api/useAssigneeFromBoardConfig';
import { useAttributesFromBoardConfig } from 'src/hooks/api/useAttributesFromBoardConfig/useAttributesFromBoardConfig';
import { useParentFromBoardConfig } from 'src/hooks/api/useParentIdFromBoardConfig';
import { useProductBase } from 'src/hooks/api/useProduct';
import { useIsDocInBoard } from 'src/hooks/useIsDocInBoard';
import { useMergeQuery } from 'src/hooks/useMergeQuery';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { setLastDoctypeIdUsed } from 'src/reactives/lastView.reactive';
import { defaultGroupPagination, defaultPagination } from 'src/utils/pagination.util';

import { useCustomerDocFromCache } from '../cache/cacheCustomerDoc';
import { useUpdateChildCache } from '../cache/cacheHierarchy';
import { useCustomerFromBoardConfig } from '../useCustomerFromBoardConfig';

interface UseCreateDocParams {
  from?: 'top' | 'bottom';
  groupId?: string;
  onCreated?: (data: NonNullable<AddNewDocMutation['addNewDoc']>) => void;
  statusId?: string;
  skipCache?: boolean;
}

interface CreateParams {
  title: string;
  doctype: DoctypeRelativeFragment;
  parentId?: string;
  isDraft?: boolean;
  attributes?: AddNewDocAttributeValue[];
}

export const useCreateDoc = ({
  groupId,
  statusId,
  from,
  skipCache,
  onCreated,
}: UseCreateDocParams = {}) => {
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);

  const attributesFromBoardConfig = useAttributesFromBoardConfig();
  const product = useProductBase();
  const [createDoc, {
    loading, data: createdDocData,
  }] = useSafeMutation(AddNewDocDocument, {
    onCompleted: (data) => {
      if (onCreated && data.addNewDoc?.id) {
        onCreated(data.addNewDoc);
      }
    },
  });
  const { addCustomerDoc } = useCustomerDocFromCache();
  const isDocCompatible = useIsDocInBoard();

  const hasDefaultAttributes = attributesFromBoardConfig.length > 0;

  const mergeBoardConfig = useMergeQuery({
    query: BoardConfigDocument,
  });

  const mergeGroup = useMergeQuery({
    query: GroupNodeDocument,
  });

  const { getAssigneeFromBoardConfig } = useAssigneeFromBoardConfig();
  const { firstSelectedCustomerId } = useCustomerFromBoardConfig();
  const parentIdFromBoardConfig = useParentFromBoardConfig();
  const updateChild = useUpdateChildCache();

  const addNewdocCache = async (newDoc: AddNewDocMutation['addNewDoc']) => {
    if (!newDoc) return;
    if (newDoc.customer) {
      addCustomerDoc({
        doc: newDoc,
        customer: newDoc.customer,
        doctypeId: newDoc.doctype.id,
      });
    }

    if (newDoc.parent) {
      await updateChild({
        newParentId: newDoc.parent.id,
        docs: [{
          docId: newDoc.id,
          parentId: undefined,
        }],
      });
    }

    if (isDocCompatible(newDoc)) {
      if (boardConfig?.docQuery.__typename === 'BoardQuery') {
        mergeBoardConfig(
          {
            id: boardConfig?.id ?? '',
            ...defaultPagination,
          },
          {
            node: {
              docQuery: {
                docGroup: {
                  docs: {
                    edges: [{
                      __typename: 'DocEdge',
                      node: newDoc,
                    }],
                  },
                },
              },
            },
          },
          from === 'top' ? 'start' : 'end',
        );
      } else if (
        boardConfig?.docQuery.__typename === 'BoardQueryWithGroupBy' ||
        boardConfig?.docQuery.__typename === 'BoardQueryWithSwimlaneBy'
      ) {
        mergeGroup(
          {
            groupId: groupId ?? '',
            ...defaultGroupPagination,
          },
          {
            node: {
              docs: {
                edges: [{
                  __typename: 'DocEdge',
                  node: newDoc,
                }],
              },
            },
          },
          from === 'top' ? 'start' : 'end',
        );
      }
    }
  };

  function create({
    doctype,
    isDraft,
    attributes,
    ...params
  }: CreateParams) {
    if (!product || !boardConfig) return null;

    const doctypeId = doctype.id;
    const assignee = getAssigneeFromBoardConfig({
      groupId,
      doctypeType: doctype.type,
    });

    const variables: AddNewDocMutationVariables = {
      ...params,
      doctypeId,
      productId: product?.id,
      groupId,
      statusId,
      attributes: uniqBy([
        // attributes params win
        ...(attributes || []),
        ...attributesFromBoardConfig,
      ], (attribute) => attribute.attributeDefinitionId),
      isDraft,
      position: from === 'top' ? CreateDocPosition.Start : CreateDocPosition.End,
      ...!params.parentId && { parentId: parentIdFromBoardConfig },
      ...assignee && { assignee },
      ...firstSelectedCustomerId && { customerId: firstSelectedCustomerId },
    };

    setLastDoctypeIdUsed(doctypeId);

    return createDoc({
      variables,
      update: async (_, { data }) => {
        if (!data?.addNewDoc || skipCache) return;
        await addNewdocCache(data.addNewDoc);
      },
    });
  }

  return {
    addNewdocCache,
    createDoc: create,
    createdDocData,
    hasDefaultAttributes,
    loading,
    attributesFromBoardConfig,
  };
};
