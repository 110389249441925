import { useQuery } from '@apollo/client';
import { FetchBoardsStarredDocument } from '@cycle-app/graphql-codegen';
import { useCallback, useMemo } from 'react';

import { useMaybeMeV2 } from 'src/hooks/api/useMe';
import { useProductBase } from 'src/hooks/api/useProduct';

export const useBoardsStarred = () => {
  const product = useProductBase();
  const { me } = useMaybeMeV2();

  const {
    data, loading,
  } = useQuery(FetchBoardsStarredDocument, {
    skip: !product?.id || !me?.id,
    variables: {
      userId: me?.id as string,
      pId: product?.id as string,
    },
  });

  const boards = useMemo(() => {
    if (data?.node?.__typename !== 'Me') return [];

    return data.node.boards.edges.map(e => e.node) || [];
  }, [data]);

  return {
    boards,
    isLoading: loading,
  };
};

export const useIsBoardStarred = () => {
  const {
    boards, isLoading,
  } = useBoardsStarred();
  return useCallback((id: string) => {
    if (isLoading) return undefined;
    return boards.some(board => board.id === id);
  }, [boards, isLoading]);
};
