import { gql, useSubscription } from '@apollo/client';
import {
  DeletedDocSubscription,
  DeletedDocDocument,
  DeletedDocSubscriptionVariables,
} from '@cycle-app/graphql-codegen';

import { useRemoveDocsFromCache } from './cache/cacheBoardDocsList';
import { refetchProfileDocsCount } from './cache/cacheProfile';

export const useDeletedDocSubscription = (productId?: string | null) => {
  const removeDocsFromCache = useRemoveDocsFromCache();
  return useSubscription<DeletedDocSubscription, DeletedDocSubscriptionVariables>(
    DeletedDocDocument,
    {
      variables: {
        productId: productId as string,
      },
      onData: ({
        data: { data }, client,
      }) => {
        if (data?.deletedDoc) {
          const doc = client.cache.readFragment({
            fragment: gql`fragment Doc on Doc { id }`,
            id: data.deletedDoc,
          });
          if (doc) {
            // Remove only of if the doc is still in the cache.
            removeDocsFromCache([data.deletedDoc]);
          }
          refetchProfileDocsCount();
        }
      },
    },
  );
};
