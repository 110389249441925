import { ReleasePublicStatus } from '@cycle-app/graphql-codegen';
import { Skeleton } from '@cycle-app/ui';
import { CloseIcon, InfoIconOutline } from '@cycle-app/ui/icons';
import { toShortLocaleDateString } from '@cycle-app/utilities';
import { motion } from 'framer-motion';
import { useRef } from 'react';

import { ReleasesPageTitle } from 'src/components/PageTitle/PageTitle';
import { ReleaseNotesProvider } from 'src/contexts/releaseNotesContext';
import { useNavigate } from 'src/hooks';
import { useRelease } from 'src/hooks/releases/useRelease';
import { useReleaseNote } from 'src/hooks/releases/useReleaseNote';
import { useReleasePublicStatusUpdate } from 'src/hooks/releases/useReleasePublicStatusUpdate';
import { useParams } from 'src/hooks/router/useParams';
import { useGetPermission } from 'src/reactives';

import {
  Nav, Action, EditorContainer, EditorHeader, Alert, StyledDocPanelRealtime,
} from './ReleaseNote.styles';
import { ReleaseNoteDocFeature } from './ReleaseNoteDocFeature';
import { ReleaseNoteDocOpen } from './ReleaseNoteDocOpen';
import { ReleaseNoteEditor } from './ReleaseNoteEditor';
import { ReleaseNoteEditorTitle } from './ReleaseNoteEditorTitle';
import { ReleaseNotePrevNext } from './ReleaseNotePrevNext';
import { ReleaseNotePublishedBanner } from './ReleaseNotePublishedBanner';
import { ReleaseNoteTags } from './ReleaseNoteTags';

const useCloseReleaseNote = () => {
  const { releaseId } = useParams();
  const { navigateToRelease } = useNavigate();
  return () => {
    if (!releaseId) return;
    navigateToRelease(releaseId);
  };
};

export const ReleaseNote = () => {
  const { canUpdateReleaseNote } = useGetPermission();
  const {
    releaseId, noteId,
  } = useParams();
  const {
    release, isReleaseLoading, isReadonly,
  } = useRelease(releaseId);

  const {
    releaseNote, isReleaseNoteLoading,
  } = useReleaseNote(noteId);

  const { editPublished } = useReleasePublicStatusUpdate(releaseId);

  const closeReleaseNote = useCloseReleaseNote();
  
  const releaseNoteInitialContent = useRef<string>();

  if (!noteId || !releaseId || !release) return null;

  const isLoading = isReleaseLoading || isReleaseNoteLoading;
  const releaseDate = toShortLocaleDateString(release.date);

  const handleReleaseNoteContentChange = async (html: string) => {
    if (release.publicStatus !== ReleasePublicStatus.Published) return;
    if (!releaseNoteInitialContent.current) {
      releaseNoteInitialContent.current = html;
      return;
    }

    if (releaseNoteInitialContent.current === html) return;

    await editPublished();
  };

  const handleReleaseNoteTitleChange = () => {
    if (release.publicStatus !== ReleasePublicStatus.Published) return;

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    editPublished();
  };

  return (
    <>
      {releaseNote?.title && (
        <ReleasesPageTitle
          releaseDate={releaseDate}
          releaseNoteTitle={releaseNote?.title}
        />
      )}
      <motion.div
        className="relative flex h-full flex-col overflow-hidden"
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={{
          visible: {
            opacity: 1,
            transition: {
              duration: 0.1,
              delay: 0.1,
            },
          },
          hidden: {
            opacity: 0,
            transition: {
              duration: 0.1,
            },
          },
        }}
      >
        <div className="flex flex-wrap items-start justify-between gap-4 border-b border-grey-200 px-5 py-4 dark:border-grey-850">
          {isLoading ? (
            <HeaderSkeleton />
          ) : (
            <>
              <div className="flex items-center gap-3">
                <Nav>
                  <Action onClick={closeReleaseNote}>
                    <CloseIcon />
                  </Action>
                  <ReleaseNotesProvider
                    isReadonly={isReadonly}
                    showBugAndImprovements={release.showBugAndImprovements}
                    publicStatus={release.publicStatus}
                    releaseId={releaseId}
                    isNoValue={null}
                  >
                    <ReleaseNotePrevNext />
                  </ReleaseNotesProvider>
                </Nav>
              </div>
              {noteId && (
                <div className="flex items-center gap-3">
                  <StyledDocPanelRealtime />
                  <ReleaseNoteDocOpen
                    isReadOnly={isReadonly}
                    releaseId={releaseId}
                    noteId={noteId}
                  />
                </div>
              )}
            </>
          )}
        </div>

        <ReleaseNotePublishedBanner releaseId={releaseId} />

        <EditorContainer>
          <EditorHeader>
            <ReleaseNoteAlert releaseNoteId={noteId} />
            {releaseNote?.doc && (
              <ReleaseNoteDocFeature
                releaseId={releaseId}
                noteId={releaseNote.id}
                doc={releaseNote.doc}
              />
            )}
            <ReleaseNoteTags isReadonly={isReadonly} releaseNoteId={noteId} />
            <ReleaseNoteEditorTitle
              releaseNoteId={noteId}
              isReadOnly={!canUpdateReleaseNote}
              onChange={handleReleaseNoteTitleChange}
            />
          </EditorHeader>

          <ReleaseNoteEditor
            releaseNoteId={noteId}
            isReadOnly={!canUpdateReleaseNote}
            onChange={(html) => handleReleaseNoteContentChange(html)}
          />
        </EditorContainer>
      </motion.div>
    </>
  );
};

export const ReleaseNoteAlert = ({ releaseNoteId }: { releaseNoteId: string }) => {
  const { releaseNote } = useReleaseNote(releaseNoteId);
  if (!releaseNote?.isOther) return null;
  return (
    <Alert>
      <p>
        <InfoIconOutline />
      </p>
      <p>
        {'This release note is in the '}
        <strong>Other improvements & bugs section</strong>
        , the description will not appear in the public change log, only the title.
      </p>
    </Alert>
  );
};

const HeaderSkeleton = () => {
  const closeReleaseNote = useCloseReleaseNote();
  return (
    <>
      <div className="flex items-center gap-3 overflow-hidden">
        <Action onClick={closeReleaseNote}>
          <CloseIcon />
        </Action>
        <Skeleton height={20} width={80} />
        <Skeleton height={20} width={60} />
      </div>
      <div className="flex items-center gap-2">
        <Skeleton height={20} width={60} />
        <Skeleton height={20} width={60} />
      </div>
    </>
  );
};
