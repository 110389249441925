import { Property } from '@cycle-app/graphql-codegen';
import { useMemo } from 'react';

import { useBoardConfig } from 'src/contexts/boardConfigContext';

export const GROUP_BY_RULES_WITH_INSIGHT_NO_EDIT_ALLOW: Array<Property['__typename']> = [
  'AssigneeDefinition',
  'BuiltInCompanyDefinition',
  'BuiltInCustomerDefinition',
  'StatusDefinition',
];

export const GROUP_BY_RULES_WITH_DISABLED_CROSS_GROUP: Array<Property['__typename']> = [
  'CreatorDefinition',
  'DoctypeDefinition',
  'SourceDefinition',
];

export const useCrossGroupStrategy = (propertyTypename: Property['__typename']) => {
  const insightDocTypeInfo = useBoardConfig(ctx => ctx.insightDocType);
  // eslint-disable-next-line no-nested-ternary
  return useMemo(() => (insightDocTypeInfo.isTheOnlyOne
    ? [
      ...GROUP_BY_RULES_WITH_DISABLED_CROSS_GROUP,
      ...GROUP_BY_RULES_WITH_INSIGHT_NO_EDIT_ALLOW,
    ].includes(propertyTypename)
      ? 'disabled'
      : 'sorting'
    : GROUP_BY_RULES_WITH_DISABLED_CROSS_GROUP.includes(propertyTypename)
      ? 'disabled'
      : 'sorting'), [propertyTypename, insightDocTypeInfo.isTheOnlyOne]);
};
