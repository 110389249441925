import { DocParentFragment } from '@cycle-app/graphql-codegen';
import { ComponentProps } from 'react';

import DocPanelDocAttributes from 'src/app/Main/Board/DocPanel/DocPanelDocAttributes/DocPanelDocAttributes';
import { Layer } from 'src/types/layers.types';

type Props = Omit<ComponentProps<typeof DocPanelDocAttributes>, 'doc'> & {
  doc: DocParentFragment;
};

export const FeatureCardAttributes = ({
  doc, ...props
}: Props) => {
  return (
    <DocPanelDocAttributes
      doc={doc}
      showDocIdWithDocType
      layer={Layer.DropdownModal}
      hideStatusLabel
      {...props}
    />
  );
};