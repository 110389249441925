import { DocBaseFragment, DocSourceFragment } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { useMaybeMeV2 } from 'src/hooks/api/useMe';
import client from 'src/services/apollo/client';
import { getDocBaseFromCache } from 'src/utils/cache.utils';

const { cache } = client;

/**
 * me.docImport is a list of draft feedback docs created with the dropzone
 * We need to remove a doc from the cache after it has been published or discarded
 */
export const useRemoveDocImportFromCache = () => {
  const { me } = useMaybeMeV2();
  return useCallback((doc?: Partial<{ id: string; __typename: string }> | null) => {
    if (!me || !doc) return;
    cache.modify({
      id: cache.identify(me),
      fields: {
        docImport: (refs, { toReference }) => {
          const publishedRef = toReference(doc);
          return ({
            ...refs,
            edges: refs.edges.filter((edge: any) => edge.node?.__ref !== publishedRef?.__ref),
          });
        },
      },
    });
  }, [me]);
};

/**
 * me.docImport is a list of draft feedback docs created with the dropzone
 * We need to add a doc to the cache after it has been created by the backend
 * The backend pushes created docs with the fileDocImport subscription
 */
export const useAddDocImportToCache = () => {
  const { me } = useMaybeMeV2();
  return useCallback((doc: DocBaseFragment) => {
    if (!me) return;
    cache.modify({
      id: cache.identify(me),
      fields: {
        docImport: (refs, { toReference }) => ({
          ...refs,
          edges: [
            { node: toReference(doc) },
            ...refs.edges,
          ],
        }),
      },
    });
  }, [me]);
};

export const useDocSourceUpdateCache = () => {
  return (docId: string, docSource: DocSourceFragment) => {
    const doc = getDocBaseFromCache(docId);
    if (!doc) return;
    cache.modify({
      id: cache.identify(doc),
      fields: { source: () => docSource },
    });
  };
};
