import { split, ApolloClient, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { SchemaLink } from '@apollo/client/link/schema';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
// @ts-ignore -- no types
// eslint-disable-next-line import/extensions
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { GraphQLSchema } from 'graphql';
import { createClient, ClientOptions } from 'graphql-ws';

import { getAuth } from 'src/reactives/auth.reactive';

import { cache } from './cache';

const ERROR_STATUS_CODES_WITH_RETRY = [
  undefined, // CORS error
  408, // Request Timeout
  409, // Conflict
  429, // Too Many Requests
  502, // Bad Gateway
  503, // Service Unavailable
  504, // Gateway Timeout
];

const sessionId = crypto.randomUUID?.() ?? '';

const authLink = setContext((_, { headers }) => {
  const { token } = getAuth();
  return {
    headers: {
      ...headers,
      ...(token ? { authorization: `Bearer ${token}` } : {}),
      session: sessionId,
    },
  };
});

// Stolen from https://github.com/dotansimha/graphql-code-generator/issues/3063
const fragmentDeDupeLink = new ApolloLink((operation, forward) => {
  const previousDefinitions = new Set<string>();
  const definitions = operation.query.definitions.filter((def) => {
    if (def.kind !== 'FragmentDefinition') return true;
    const name = `${def.name.value}-${def.typeCondition.name.value}`;
    if (previousDefinitions.has(name)) {
      return false;
    }
    previousDefinitions.add(name);
    return true;
  });
  const newQuery = {
    ...operation.query,
    definitions,
  };
  // eslint-disable-next-line no-param-reassign
  operation.query = newQuery;
  return forward(operation);
});

let hasWebsocketClosedBefore = false;

// hack: cast createUploadLink to unknown and ApolloLink to be used in ApolloLink.from
const uploadLink = createUploadLink({ uri: import.meta.env.VITE_HTTP_API_GRAPHQL_URI }) as unknown as ApolloLink;

const wsLinkOptions: ClientOptions = {
  url: import.meta.env.VITE_WS_API_GRAPHQL_URI,
  /**
 * By default the client will immediately fail on any non-fatal CloseEvent problem thrown during the connection phase
 * See: https://github.com/enisdenjo/graphql-ws/blob/master/docs/interfaces/client.ClientOptions.md#isfatalconnectionproblem
 */
  isFatalConnectionProblem: () => false,

  /**
 * Ping server every 10 seconds
 * See: https://github.com/enisdenjo/graphql-ws/blob/master/docs/interfaces/client.ClientOptions.md#keepalive
  */
  keepAlive: 10_000,

  on: {
    connected: async () => {
      if (hasWebsocketClosedBefore) {
        setTimeout(async () => {
          await onReconnectCallback();
        }, 1000 + Math.random() * 10000);
      }
    },
    closed: () => {
      if (navigator.onLine) return;
      hasWebsocketClosedBefore = true;
    },
  },
  retryAttempts: Infinity,
  retryWait: async function waitForServerHealthyBeforeRetry() {
    await new Promise((resolve) => setTimeout(resolve, 1000 + Math.random() * 10000));
  },
  connectionParams: () => {
    const { token } = getAuth();
    if (!token) {
      return {};
    }
    return {
      Authorization: `Bearer ${token}`,
      Session: sessionId,
    };
  },
};

export const subscriptionlink = import.meta.env.VITE_QUERIES_MUTATIONS_OVER_WEBSOCKET === 'on'
  // All operations are sent to GraphQLWsLink
  ? new GraphQLWsLink(createClient(wsLinkOptions))
  // All subscription operations are sent to GraphQLWsLink, all other operations are sent to HttpLink
  : split(
    (test) => {
      const definition = getMainDefinition(test.query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    new GraphQLWsLink(createClient(wsLinkOptions)),
    uploadLink,
  );

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: (count, _, error) => {
    // If online, retry only specific network errors up to 5 times (Apollo default value)
    if (navigator.onLine) {
      return ERROR_STATUS_CODES_WITH_RETRY.includes(error.statusCode) && count <= 5;
    }

    // If offline, retry when connection is restored
    return new Promise(resolve => {
      window.addEventListener('online', () => resolve(true), { once: true });
    });
  },
});

export const getApolloClient = (schema?: GraphQLSchema) => {
  if (schema) { // For tests
    const errorLink = onError(({
      graphQLErrors, networkError, operation,
    }) => {
      const { operationName } = operation;
      if (graphQLErrors) {
        graphQLErrors.forEach(({
          message, locations, path,
        }) => console.error(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}, Operation name: ${operationName}`,
        ));
      }
      if (networkError) console.error(`[Network error]: ${networkError}`);
    });

    return new ApolloClient({
      link: ApolloLink.from([errorLink, fragmentDeDupeLink, authLink, subscriptionlink, new SchemaLink({ schema })]),
      cache,
      assumeImmutableResults: true,
    });
  }

  return new ApolloClient({
    link: ApolloLink.from([retryLink, fragmentDeDupeLink, authLink, subscriptionlink, uploadLink]),
    cache,
    assumeImmutableResults: true,
  });
};

const client = getApolloClient();
const onReconnectCallback = () => client.reFetchObservableQueries();

export default client;
