import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import { AnswerTextContainer } from './Ask.styles';

type Props = {
  text: string;
  simulateTyping?: boolean;
};

export const AnswerText = ({
  text, simulateTyping = false,
}: Props) => {
  const [stream, setStream] = useState('');
  const containerRef = useRef<HTMLDivElement>(null);
  const cursorRect = useRef<DOMRect>(document.body.getBoundingClientRect());

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (simulateTyping) {
      const callback = (count = 0) => {
        // Complete the typing if the tab is hidden to avoid sync issues
        if (document.visibilityState === 'hidden') {
          clearInterval(timeoutId);
          setStream(text);
          return;
        }

        if (text[count] === undefined) {
          clearInterval(timeoutId);
          return;
        }
        setStream(prev => prev + text[prev.length]);
        timeoutId = setTimeout(() => callback(count + 1), 10);
      };
      callback();
    }

    return () => {
      clearInterval(timeoutId);
    };
  }, [text, simulateTyping]);

  // Insert cursor after the last element
  useLayoutEffect(() => {
    if (!containerRef.current) return;
    const prevCursor = containerRef.current.querySelector('#cursor');
    if (prevCursor) prevCursor.remove();

    if (!simulateTyping || text === stream) return;
    const lastElement = findLastElement(containerRef.current);
    if (lastElement) {
      lastElement.insertAdjacentHTML('beforeend', '<mark id="cursor"></mark>');
      const cursor = lastElement.querySelector('#cursor');
      if (!cursor) return;
      cursorRect.current = cursor.getBoundingClientRect();
    }
  }, [simulateTyping, stream, text]);

  return (
    <AnswerTextContainer
      ref={containerRef}
      $withCursor={simulateTyping}
    >
      <ReactMarkdown>
        {simulateTyping ? stream : text}
      </ReactMarkdown>
    </AnswerTextContainer>
  );
};

/** Find the last element in the tree that contains text */
const findLastElement = (element: HTMLElement): HTMLElement | null => {
  const lastChild = element.lastChild?.textContent === '\n'
    ? element.lastChild.previousSibling
    : element.lastChild;

  if (lastChild?.nodeType === Node.TEXT_NODE) {
    return element;
  }
  if (lastChild instanceof HTMLElement) {
    return findLastElement(lastChild);
  }
  return null;
};
