import {
  CustomerFragment,
  CustomerFragmentDoc,
  UpdateCustomerDocument,
  UpdateCustomerMutationVariables,
} from '@cycle-app/graphql-codegen';

import useSafeMutation from 'src/hooks/useSafeMutation';

type Options = {
  displayErrorMessages?: boolean;
};

export const useCustomerUpdate = (currentCustomer?: CustomerFragment, options?: Options) => {
  const [changeMutation, { loading }] = useSafeMutation(UpdateCustomerDocument, {
    errorPolicy: 'all',
    displayErrorMessages: options?.displayErrorMessages,
    update: (cache, { data }) => {
      if (!currentCustomer || !data?.updateCustomer) return;

      const customerFragment = cache.readFragment({
        fragment: CustomerFragmentDoc,
        fragmentName: 'Customer',
        id: currentCustomer.id,
      });

      if (!customerFragment) return;

      if (currentCustomer.company?.id !== data.updateCustomer.company?.id) {
        if (data.updateCustomer.company) {
          cache.modify({
            id: cache.identify(data.updateCustomer.company),
            fields: {
              customers: (customers, {
                readField, toReference,
              }) => {
                const index = customers.edges.findIndex((edge: any) => (readField('email', edge.node) ?? '') > (customerFragment.email || ''));
                const node = toReference(customerFragment);
                if (!node) return customers.edges;
                const newEdges = [...customers.edges];
                newEdges.splice(Math.max(0, index), 0, {
                  __typename: 'CustomerEdge',
                  node: { ...node },
                });
                return {
                  ...customers,
                  edges: newEdges,
                };
              },
            },
          });
        }
        if (currentCustomer.company) {
          cache.modify({
            id: cache.identify(currentCustomer.company),
            fields: {
              countCustomers: (count) => Math.max(0, count - 1),
              customers: (customers, { readField }) => ({
                ...customers,
                edges: customers.edges.filter((edge: any) => customerFragment.id !== readField('id', edge.node)),
              }),
            },
          });
        }
      }
    },
  });

  const update = ({
    customerId, name, companyId, avatarInput, email,
  }: UpdateCustomerMutationVariables) => {
    if (!customerId) throw Error('Data are missing');

    return changeMutation({
      variables: {
        customerId,
        companyId,
        name,
        ...email && { email },
        avatarInput,
      },
    });
  };

  return {
    update,
    isLoading: loading,
  };
};
