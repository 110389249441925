import { Icon } from '@cycle-app/ui';
import { FC } from 'react';

import { BOOK_DEMO } from 'src/constants/contacts.constants';
import { closeSlackSettingsModal } from 'src/reactives/slackSettingsModal.reactive';
import { setUpgradeModal } from 'src/reactives/upgradeModal.reactive';
import { Layer } from 'src/types/layers.types';

import {
  StyledPortalModal, Content, Title, Description, Features, FeaturesList, StyledCheckIcon, ContactSales, Cancel, StyledLogo, Logos,
} from './LimitationModalSlack.styles';

interface Props {
  description?: string;
  plan: 'pro' | 'business';
  hide: VoidFunction;
}

const businessFeatures = [
  [
    'Premium integrations',
    'Unlimited feature types',
    'Advanced Slack features',
    'Unlimited workspaces',
  ],
  [
    'Unlimited activity log',
    'Admin tools & private teams',
    'SAML-based SSO',
    'Customer attributes',
  ],
];

const proFeatures = [
  [
    'Unlimited docs',
    'Unlimited views',
    'Unlimited integrations',
  ],
  [
    'Unlimited file uploads',
  ],
];

export const LimitationModalSlack: FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    plan, hide, description,
  } = props;
  return (
    <StyledPortalModal
      layer={Layer.DialogModal}
      hide={hide}
    >
      <Content>
        <Logos>
          <StyledLogo size={64} {...plan === 'pro' && { color: 'blue' }} />
          <Icon name="brand/slack" className="size-12" />
        </Logos>
        <Title>{plan === 'pro' ? 'Get more with Pro' : 'Get extra Slack features with Business+'}</Title>
        <Description>
          {plan === 'pro'
            ? description
            : 'Use your branding for public notifications, link several workspaces and create reacjis'}
        </Description>
        <Features>
          <FeaturesList>
            {(plan === 'business' ? businessFeatures : proFeatures).map((col, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <ul key={i}>
                {col.map((row, j) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={`${i}${j}`}>
                    <StyledCheckIcon />
                    {row}
                  </li>
                ))}
              </ul>
            ))}
          </FeaturesList>
          <ContactSales
            size="L"
            full
            id="contact-sales"
            variant="alt"
            onClick={() => {
              if (plan === 'pro') {
                window.open(BOOK_DEMO, '_blank');
              } else {
                hide();
                closeSlackSettingsModal();
                setUpgradeModal({ isOpened: true });
              }
            }}
          >
            {plan === 'pro' ? 'Book a demo' : 'Explore Business+'}
          </ContactSales>
          <Cancel size={14} onClick={hide}>Maybe later</Cancel>
        </Features>
      </Content>
    </StyledPortalModal>
  );
};
