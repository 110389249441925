import {
  Shortcut,
  ShortcutBoard,
  ShortcutGroupOptions,
  ShortcutDocPanel,
  ShortcutDocDraft,
  ShortcutsHotkeys,
  ShortcutCommandbar,
  ShortcutCreateModal,
  ShortcutEditor,
  ShortcutStatus,
  ShortcutSidebar,
} from 'src/types/shortcuts.types';

export * from 'src/types/shortcuts.types';

export const shortcuts: ShortcutsHotkeys = {
  [Shortcut.Undo]: ['mod', 'z'],

  // DocPanel
  [ShortcutDocPanel.CloseDoc]: ['escape'],
  [ShortcutDocPanel.ExpandDoc]: ['mod', 'enter'],
  [ShortcutDocPanel.PrevDoc]: ['k'],
  [ShortcutDocPanel.NextDoc]: ['j'],
  [ShortcutDocPanel.Status]: ['control', 'shift', 's'],

  // Status
  [ShortcutStatus.Status1]: ['control', 'shift', '1'],
  [ShortcutStatus.Status2]: ['control', 'shift', '2'],
  [ShortcutStatus.Status3]: ['control', 'shift', '3'],
  [ShortcutStatus.Status4]: ['control', 'shift', '4'],
  [ShortcutStatus.Status5]: ['control', 'shift', '5'],
  [ShortcutStatus.Status6]: ['control', 'shift', '6'],
  [ShortcutStatus.Status7]: ['control', 'shift', '7'],
  [ShortcutStatus.Status8]: ['control', 'shift', '8'],
  [ShortcutStatus.Status9]: ['control', 'shift', '9'],

  // Board
  [ShortcutBoard.CreateDoc]: ['c'],
  [ShortcutBoard.Update]: ['mod', 'e'],
  [ShortcutBoard.Duplicate]: ['mod', 'd'],
  [ShortcutBoard.Delete]: ['mod', 'delete'],
  [ShortcutBoard.Publish]: ['mod', 's'],

  // Sidebar
  [ShortcutSidebar.OpenNotifCenter]: ['g', ',', 'n'],

  // Doc hover
  [ShortcutBoard.OpenDoc]: ['enter'],
  [ShortcutBoard.OpenDocFullPage]: ['mod', 'enter'],
  [ShortcutBoard.CopyDocUrl]: ['mod', 'c'],
  [ShortcutBoard.DuplicateDoc]: ['mod', 'd'],
  [ShortcutBoard.DeleteDoc]: ['mod', 'delete'],
  [ShortcutBoard.SelectDoc]: ['x'],
  [ShortcutBoard.SelectAllDocs]: ['mod', 'a'],
  [ShortcutBoard.ChangeDoctype]: ['t'],
  [ShortcutBoard.Assign]: ['a'],
  [ShortcutBoard.AddComment]: ['m'],
  [ShortcutBoard.AddCover]: ['p'],
  [ShortcutBoard.EditProperty]: ['mod', 'e'],
  [Shortcut.ProcessDoc]: ['e'],

  // Navigation
  [Shortcut.GoToSettings]: ['g', ',', 's'],
  [Shortcut.GoToComments]: ['g', ',', 't'],
  [Shortcut.GoToHome]: ['g', ',', 'h'],
  [Shortcut.GoToFeedback]: ['g', ',', 'f'],
  [Shortcut.GoToInsights]: ['g', ',', 'i'],
  [Shortcut.GoToRoadmap]: ['g', ',', 'r'],
  [Shortcut.GoToReleases]: ['g', ',', 'l'],
  [Shortcut.GoToMyAccount]: ['g', ',', 'm'],

  // Commandbar
  [ShortcutCommandbar.ToggleCommandBar]: ['mod', 'k'],
  [Shortcut.SwitchTheme]: ['t', ',', 's'],
  [Shortcut.GoToWorkspace]: ['g', ',', 'w'],

  // Board options
  [ShortcutGroupOptions.CreateDoc]: ['c'],

  // Doc draft
  [ShortcutDocDraft.Abort]: ['escape'],
  [ShortcutDocDraft.Create]: ['enter'],
  [ShortcutDocDraft.CreateAndOpen]: ['mod', 'enter'],
  [ShortcutDocDraft.CreateAndNewDraft]: ['shift', 'enter'],
  [ShortcutDocDraft.GoDown]: ['tab'],
  [ShortcutDocDraft.GoUp]: ['shift', 'tab'],

  // Create doc modal
  [ShortcutCreateModal.Save]: ['mod', 'enter'],
  [ShortcutCreateModal.SaveAndOpen]: ['shift', 'enter'],

  [ShortcutEditor.TurnIntoInsight]: ['mod', '1'],
  [ShortcutEditor.TurnGithubIssue]: ['mod', '2'],
  [ShortcutEditor.TurnLinearIssue]: ['mod', '3'],
};
