import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { DocThreadsDocument, DocThreadFragment, DocThreadsQuery } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';

import { ThreadsSection } from 'src/reactives/comments.reactive';
import { extract } from 'src/types/graphql.types';

type Options = {
  section: ThreadsSection;
  onCompleted: (data: DocThreadsQuery) => void;
  skip?: boolean;
  fetchPolicy?: WatchQueryFetchPolicy;
};

export const useDocThreads = (docId: string | undefined, options: Partial<Options>) => {
  const unresolvedQuery = useQuery(DocThreadsDocument, {
    fetchPolicy: options?.fetchPolicy ?? 'cache-first',
    nextFetchPolicy: 'cache-first',
    skip: !docId || options.skip || options.section === 'closed',
    variables: {
      id: docId as string,
      resolved: false,
    },
    onCompleted: options?.onCompleted,
  });

  const resolvedQuery = useQuery(DocThreadsDocument, {
    fetchPolicy: options?.fetchPolicy ?? 'cache-first',
    nextFetchPolicy: 'cache-first',
    skip: !docId || options?.skip || options.section === 'open',
    variables: {
      id: docId as string,
      resolved: true,
    },
    onCompleted: options?.onCompleted,
  });

  const unresolvedThreads = nodeToArray(extract('Doc', unresolvedQuery.data?.node)?.threads);
  const resolvedThreads = nodeToArray(extract('Doc', resolvedQuery.data?.node)?.threads);

  if (options.section === 'open') {
    return {
      fetched: !!unresolvedQuery.data,
      loading: unresolvedQuery.loading,
      threads: unresolvedThreads,
      resolvedIds: [],
    };
  }

  if (options.section === 'closed') {
    return {
      fetched: !!resolvedQuery.data,
      loading: resolvedQuery.loading,
      threads: resolvedThreads,
      resolvedIds: resolvedThreads.map(thread => thread.id),
    };
  }

  return {
    fetched: !!unresolvedQuery.data && !!resolvedQuery.data,
    loading: unresolvedQuery.loading || resolvedQuery.loading,
    threads: sortByCreatedAt(uniqBy([...resolvedThreads, ...unresolvedThreads], 'id')),
    resolvedIds: resolvedThreads.map(thread => thread.id),
  };
};

const sortByCreatedAt = (threads: DocThreadFragment[]) => orderBy(
  threads,
  thread => thread.comments?.edges.at(-1)?.node.createdAt,
  'desc',
);
