import { TrashIcon, ReleaseNoteIcon, DownloadIcon, ImageIcon, LinkIcon } from '@cycle-app/ui/icons';
import { filenamify } from '@cycle-app/utilities';
import { FC } from 'react';

import { EditorDragMenu } from 'src/components/EditorDragMenu';
import { PageId } from 'src/constants/routing.constant';
import { useEditorContext } from 'src/contexts/editorContext';
import useDocCoverMutations from 'src/hooks/api/mutations/useDocCoverMutations';
import { ActionId } from 'src/services/editor/editorActions';
import { copyToClipboard } from 'src/utils/clipboard.utils';
import { download } from 'src/utils/download.utils';
import { downloadWithToaster } from 'src/utils/downloadWithToaster.utils';
import { getUrl } from 'src/utils/routing.utils';

import { LanguageSelect } from '../LanguageSelect';
import { TranscriptLabel } from './EditorMediaDragMenu.styles';

interface Props {
  onTranscript?: VoidFunction;
  isTranscriptLoading?: boolean;
  onDelete: VoidFunction;
  title: string | undefined;
  src: string | undefined;
  type: 'image' | 'file' | 'audio' | 'video';
}

export const EditorMediaDragMenu: FC<React.PropsWithChildren<Props>> = ({
  onTranscript,
  isTranscriptLoading = false,
  title,
  src,
  onDelete,
  type,
}) => {
  const docId = useEditorContext(ctx => ctx.doc?.id);
  const disabledActions = useEditorContext(ctx => ctx.disabledActions);
  const isCoverEnabled = useEditorContext(ctx => !ctx.disabledFeatures?.includes('cover'));
  const { updateDocCover } = useDocCoverMutations(docId || '');
  const docTitle = useEditorContext(ctx => ctx.doc?.title);

  return (
    <EditorDragMenu
      tooltip={{
        content: 'Click to open menu',
        title: 'Move to reorder',
      }}
      options={[
        ...(src && type === 'image' && isCoverEnabled ? [{
          label: 'Set as cover',
          value: 'set-as-cover',
          icon: <ImageIcon />,
          onSelect: () => updateDocCover(src),
        }] : []),
        ...(src && onTranscript && !disabledActions?.includes(ActionId.Transcript) ? [{
          label: 'Transcript',
          renderLabel: () => (
            <TranscriptLabel>
              {type === 'image' ? 'Run OCR' : 'Get transcript'}
              {(type === 'audio' || type === 'video') && <LanguageSelect />}
            </TranscriptLabel>
          ),
          onSelect: onTranscript,
          value: 'transcript',
          icon: <ReleaseNoteIcon />,
          disabled: isTranscriptLoading,
        }] : []),
        ...(src && type === 'video' ? [{
          label: 'Copy URL',
          value: 'copy',
          icon: <LinkIcon />,
          onSelect: () => {
            copyToClipboard({
              // src is base64 encoded to simulate a private URL
              text: window.location.origin + getUrl(PageId.Video, { src: btoa(src) }),
              notification: 'URL copied to clipboard',
            });
          },
        }] : []),
        ...(src ? [{
          label: 'Download',
          value: 'download',
          icon: <DownloadIcon />,
          onSelect: () => (type === 'video' ? downloadWithToaster : download)({
            url: src,
            baseName: title || filenamify(docTitle) || 'File downloaded from Cycle App',
          }),
        }] : []),
        {
          label: 'Delete',
          onSelect: onDelete,
          value: 'delete',
          icon: <TrashIcon />,
          variant: 'danger',
        },
      ]}
    />
  );
};
