import { useLayoutEffect, useRef, useState } from 'react';

import { AnswerDoc } from './AnswerDoc';
import { Content, AnswerDocsContainer, AnswerDocsScrollable } from './Ask.styles';
import { Doc } from './Ask.types';
import { DocsButton } from './DocsButton';

export const AnswerDocs = ({
  docs, hide,
}: {
  docs: Doc[];
  hide: VoidFunction;
}) => {
  const scrollableRef = useRef<HTMLDivElement>(null);
  const [withGradientTop, setWithGradientTop] = useState(false);
  const [withBorderBottom, setWithBorderBottom] = useState(false);

  const checkGradientTop = () => {
    const el = scrollableRef.current as HTMLDivElement;
    if (el.scrollTop > 0 && !withGradientTop) setWithGradientTop(true);
    if (el.scrollTop === 0 && withGradientTop) setWithGradientTop(false);
  };

  const checkBorderBottom = () => {
    const el = scrollableRef.current as HTMLDivElement;
    const isOverflow = el.scrollHeight - el.scrollTop > el.clientHeight;
    if (isOverflow && !withBorderBottom) setWithBorderBottom(true);
    if (!isOverflow && withBorderBottom) setWithBorderBottom(false);
  };

  useLayoutEffect(() => {
    checkBorderBottom();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AnswerDocsContainer>
      <DocsButton
        docCount={docs.length}
        onClick={hide}
      />
      <Content
        $withBorder={withBorderBottom}
        $withGradient={withGradientTop}
      >
        <AnswerDocsScrollable
          ref={scrollableRef}
          onScroll={() => {
            checkGradientTop();
            checkBorderBottom();
          }}
        >
          {docs.map(doc => (
            <AnswerDoc
              rootRef={scrollableRef}
              key={doc.id}
              doc={doc}
            />
          ))}
        </AnswerDocsScrollable>
      </Content>

    </AnswerDocsContainer>
  );
};
