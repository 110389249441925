import { Language } from '@cycle-app/graphql-codegen';

export const CHANGELOG_LANGUAGES = [
  {
    label: 'English',
    value: Language.En,
  },
  {
    label: 'German',
    value: Language.De,
  },
  {
    label: 'French',
    value: Language.Fr,
  },
  {
    label: 'Spanish',
    value: Language.Es,
  },
  {
    label: 'Italian',
    value: Language.It,
  },
  {
    label: 'Dutch',
    value: Language.Nl,
  },
];
