import { useFragment } from '@apollo/client';
import { ProductAreasFragmentDoc } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';

import { useWorkspaceContext } from '../../contexts/workspaceContext';

export const useProductAreas = () => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const {
    data, complete,
  } = useFragment({
    fragment: ProductAreasFragmentDoc,
    fragmentName: 'ProductAreas',
    from: productId,
  });
  return nodeToArray(complete ? data.productAreas : null);
};