import { Progress }  from '@cycle-app/ui';
import { LogoutIcon } from '@cycle-app/ui/icons';
import { FC, ReactNode } from 'react';
import { twJoin } from 'tailwind-merge';

import { ErrorToasters } from 'src/app/ErrorToasters/ErrorToasters';
import { AppToasters } from 'src/components/AppToasters';
import { useLogout } from 'src/hooks/useLogout';
import { history } from 'src/providers';
import { useGetOnboarding } from 'src/reactives/lightOnboarding.reactive';
import { LightOnboardingScreen } from 'src/types/onboarding.types';

import {
  Aside,
  Body,
  ContentContainer,
  Content,
  Description,
  Header,
  Main,
  Root,
  SubTitle,
  StyledToastersContainer,
  Title,
  Wrapper,
  RootAnimated,
  StyledErrorToastersContainer,
  LogoutButton,
  StyledLogo,
} from './OnboardingLayout.styles';

interface Props {
  aside?: ReactNode;
  cover?: ReactNode;
  description?: ReactNode;
  hasLogout?: boolean;
  headline?: ReactNode;
  hideLogo?: boolean;
  isAnimated?: boolean;
  isVerticalCentered?: boolean;
  isContentGrow?: boolean;
  main?: ReactNode;
  subtitle?: ReactNode;
  title?: ReactNode;
  footer?: ReactNode;
  root?: ReactNode;
  progressAbsolute?: boolean;
}

export const OnboardingLayout: FC<React.PropsWithChildren<Props>> = ({
  aside,
  cover,
  description,
  hasLogout = false,
  headline,
  hideLogo,
  isAnimated,
  isVerticalCentered = true,
  isContentGrow,
  main,
  subtitle,
  title,
  footer,
  root,
  progressAbsolute,
}) => {
  const { screen } = useGetOnboarding();
  const logout = useLogout(history.location.state?.logoutRedirectPageId);

  return (
    <>
      <Wrapper data-onboarding-step={screen}>
        <Root $isAnimated={isAnimated}>
          <Main $hasHeader={!hideLogo}>
            {hasLogout && (
              <LogoutButton onClick={logout} size="L">
                <LogoutIcon size={16} />
                Log out
              </LogoutButton>
            )}
            {!hideLogo && (
              <Header $isCentered={!aside}>
                <StyledLogo animation="hover" animateColor={false} full size={28} />
              </Header>
            )}
            <ContentContainer $isVerticalCentered={isVerticalCentered} $isContentGrow={isContentGrow} className="min-w-0">
              <Content $isVerticalCentered={isVerticalCentered} $isStretch={!!footer}>
                <Body>
                  {cover}
                  {headline && <div className="mb-3 flex select-text items-center gap-2 text-body font-medium text-secondary">{headline}</div>}
                  {title && <Title>{title}</Title>}
                  {subtitle && <SubTitle>{subtitle}</SubTitle>}
                  {description && <Description>{description}</Description>}
                  {main}
                </Body>
              </Content>
              {footer}
            </ContentContainer>
          </Main>
          {aside && (
            <Aside>
              <div className={twJoin('mt-8 flex items-center justify-end pr-8', progressAbsolute && 'absolute inset-x-0 z-10')}>
                <Progress
                  className="size-6"
                  isNeutral
                  percentage={getPercentageProgress(screen)}
                  type="ring"
                  useCycleColor={false}
                />
              </div>
              {aside}
            </Aside>
          )}
          {root}
          {isAnimated && (
            <RootAnimated
              initial={{
                opacity: 0,
                translateY: 40,
              }}
              animate={{
                opacity: 1,
                translateY: 0,
              }}
            />
          )}
        </Root>
      </Wrapper>
      <StyledToastersContainer>
        <AppToasters />
      </StyledToastersContainer>
      <StyledErrorToastersContainer
        placeLeft={false}
        $sidebarWidth={0}
      >
        <ErrorToasters />
      </StyledErrorToastersContainer>
    </>
  );
};

const LightOnboardingSteps: (LightOnboardingScreen | LightOnboardingScreen[])[] = [
  LightOnboardingScreen.Welcome,
  LightOnboardingScreen.AccountVerify,
  LightOnboardingScreen.AccountInfos,
  LightOnboardingScreen.AccountPreferences,
  LightOnboardingScreen.Workspace,
  LightOnboardingScreen.FlowSelection,
  [
    // ONE OF THESE SCREENS
    LightOnboardingScreen.Sources,
    LightOnboardingScreen.Linear,
    LightOnboardingScreen.UserResearch,
    LightOnboardingScreen.Changelog,
  ],
  LightOnboardingScreen.Done,
];


const getPercentageProgress = (screen: LightOnboardingScreen) => {
  const screenIndex = LightOnboardingSteps.findIndex((step) => {
    if (Array.isArray(step)) {
      return step.includes(screen);
    }
    return step === screen;
  });

  return ((screenIndex + 1) / LightOnboardingSteps.length) * 100;
};

