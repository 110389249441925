import { format, type Locale } from 'date-fns';
import {
  enUS, fr, de, es, it, nl,
} from 'date-fns/locale';

import { useChangelogBuilderForm } from '../useChangelogBuilderForm';


const displayedDates = [
  new Date(), // today
  new Date(new Date().setDate(new Date().getDate() - 7)), // last week
  new Date(new Date().setMonth(new Date().getMonth() - 1)), // last month
];

type TranslateObject = { translations: Locale; format: string };

const localesWithFormats: Record<string, TranslateObject> = {
  en: {
    translations: enUS,
    format: 'MMM dd, yyyy',
  },
  de: {
    translations: de,
    format: 'dd. MMM yyyy',
  },
  fr: {
    translations: fr,
    format: 'dd MMM yyyy',
  },
  es: {
    translations: es,
    format: 'dd MMM yyyy',
  },
  it: {
    translations: it,
    format: 'dd MMM yyyy',
  },
  nl: {
    translations: nl,
    format: 'dd MMM yyyy',
  },
};

function formatDate(date: string | Date, locale: string = 'en') {
  const localeObject = localesWithFormats[locale] ?? localesWithFormats.en as TranslateObject;

  return format(new Date(date), localeObject.format, { locale: localeObject?.translations });
}


export function ChangelogBuilderContentDates() {
  const { watch } = useChangelogBuilderForm();

  return (
    <ul className="flex flex-col gap-2">
      {displayedDates.map((date, index) => (
        <li key={index} className="text-[var(--changelog-body-color)] first:mb-2 first:font-semibold">
          {formatDate(date, watch('language'))}
        </li>
      ))}
    </ul>
  );
}
