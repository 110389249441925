import { Reference } from '@apollo/client';
import { VerifyQuotesDocument } from '@cycle-app/graphql-codegen';

import { useSafeMutation } from 'src/hooks';
import client from 'src/services/apollo/client';
import { getDocFullFromCache } from 'src/utils/cache.utils';
import { getVerifiedQuoteTempId } from 'src/utils/quotes.utils';

export const useVerifyQuotes = () => {
  const [mutate, { loading }] = useSafeMutation(VerifyQuotesDocument);

  const verifyQuotes = (docId: string, quoteIds: string[]) => {
    const doc = getDocFullFromCache(docId);

    // Add temporary verified quotes to the feedback doc, to display skeletons
    client.cache.modify({
      id: client.cache.identify({ id: docId }),
      fields: {
        docTargets: (refs) => ({
          ...refs,
          edges: [
            ...refs.edges,
            ...quoteIds.map(quoteId => {
              const quote = doc?.quotes.edges.find(edge => edge.node?.id === quoteId)?.node;
              return {
                __typename: 'DocTargetEdge',
                node: {
                  __typename: 'DocTarget',
                  id: getVerifiedQuoteTempId(quoteId),
                  content: quote?.content,
                  blockId: null,
                  doc: null,
                },
              };
            }),
          ],
        }),
      },
    });

    return mutate({
      variables: {
        ids: quoteIds,
      },
      optimisticResponse: {
        verifyQuotes: true,
      },
      update: (cache) => {
        // Remove the unverified quotes from the feedback doc
        cache.modify({
          id: cache.identify({ id: docId }),
          fields: {
            quotesCount: (value) => (value > 0 ? value - quoteIds.length : 0),
            quotes: (refs, { readField }) => ({
              ...refs,
              edges: refs.edges.filter(
                (edge: { node: Reference }) => {
                  const nodeId = readField('id', edge.node)?.toString();
                  return nodeId && !quoteIds.includes(nodeId);
                },
              ),
            }),
          },
        });
      },
    });
  };

  return {
    verifyQuotes,
    loading,
  };
};
