import { Spinner } from '@cycle-app/ui';
import { twJoin } from 'tailwind-merge';

import { SuggestionsModal } from 'src/components/InsightSuggestions/SuggestionsModal';
import { useOptimizedBooleanState } from 'src/hooks';
import { useSearchSuggestionDocTargets } from 'src/hooks/api/queries/useSearchSuggestionDocTargets';
import { useDocInsightChildren } from 'src/hooks/insight/useDocInsightChildren';
import { useInsightDocType } from 'src/reactives/docTypes.reactive';

type RecommendedQuotesProps = {
  docId: string;
  doctypeId: string;
};

export const RecommendedQuotes = ({
  docId, doctypeId, 
}: RecommendedQuotesProps) => {
  const insightDoctypeId = useInsightDocType()?.id;
  if (!insightDoctypeId) return null;
  return (
    <RecommendedQuotesButton
      docId={docId}
      doctypeId={doctypeId}
      insightDoctypeId={insightDoctypeId}
    />
  );
};

type ButtonProps = {
  docId: string;
  doctypeId: string;
  insightDoctypeId: string;
};

export const RecommendedQuotesButton = ({
  docId, doctypeId, insightDoctypeId,
}: ButtonProps) => {
  const insightsQuery = useDocInsightChildren({
    docId,
    doctypeId,
  });

  const insightsIds = insightsQuery.insights.map(insight => insight.id);

  const suggestionQuery = useSearchSuggestionDocTargets({
    docId,
    doctypeIds: [insightDoctypeId],
    hasParent: false,
  });

  const docs = suggestionQuery.docs.filter(doc => !insightsIds.includes(doc.id));

  const [
    isModalOpen, {
      setTrueCallback: openModal,
      setFalseCallback: closeModal,
    },
  ] = useOptimizedBooleanState(false);

  const isLoading = !suggestionQuery.data && (insightsQuery.isLoading || suggestionQuery.isLoading);

  return (
    <>
      <button
        className={twJoin(
          'flex items-center gap-1.5 rounded p-0 text-body font-medium text-disabled outline-none focus-visible:ring',
          docs.length > 0 && 'cursor-pointer hover:text-secondary',
        )}
        onClick={openModal}
        disabled={docs.length === 0 || isLoading}
      >
        {isLoading && <Spinner />}
        {getButtonLabel(docs.length, isLoading)}
      </button>

      {isModalOpen && (
        <SuggestionsModal
          hide={closeModal}
          docs={docs}
        />
      )}
    </>
  );
};

const getButtonLabel = (count: number, isLoading: boolean) => {
  if (isLoading) return 'Loading recommendations...';
  if (count === 0) return 'No recommendation';
  if (count === 1) return '1 recommended quote';
  return `${count} recommended quotes`;
};