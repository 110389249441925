import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { LinearProjectDocument, LinearProjectQuery } from '@cycle-app/graphql-codegen';

type Options = {
  fetchPolicy?: WatchQueryFetchPolicy;
};

export const useLinearProject = (projectId: string | undefined | null, integrationId: string | null, options?: Options) => {
  const query = useQuery(LinearProjectDocument, {
    fetchPolicy: options?.fetchPolicy || 'cache-and-network',
    skip: !projectId || !integrationId,
    variables: {
      integrationId: integrationId as string,
      projectId: projectId as string,
    },
  });

  return {
    project: getProject(query.data),
    refetch: query.refetch,
    isLoading: query.loading,
  };
};

const getProject = (data?: LinearProjectQuery) => {
  if (data?.node?.__typename !== 'Integration') return null;
  if (data.node.provider?.__typename !== 'Linear') return null;
  return data.node.provider.projects[0] ?? null;
};
