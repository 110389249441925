import { useSubscription } from '@apollo/client';
import {
  BoardConfigDocsSubscription,
  BoardConfigDocsDocument,
  BoardConfigDocsSubscriptionVariables,
  DocBaseFragment,
  ListPosition,
} from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';

import { useGetGroup, useUpdateDocsGroup, useGetGroupNoFiltered } from './cache/cacheGroupHooks';
import { useBoardGroups } from './useBoardGroups';

export const useBoardConfigDocsSubscription = (boardConfigId?: string | null) => {
  const { groups } = useBoardGroups();
  const getGroup = useGetGroup();
  const getGroupNoFiltered = useGetGroupNoFiltered();
  const updateGroup = useUpdateDocsGroup();

  useSubscription<BoardConfigDocsSubscription, BoardConfigDocsSubscriptionVariables>(
    BoardConfigDocsDocument,
    {
      variables: {
        boardConfigId: boardConfigId as string,
      },
      skip: !boardConfigId,
      onData: ({ data: { data } }) => {
        if (!data || !boardConfigId) return;
        data.boardConfigDocs?.forEach((docUpdate) => {
          const {
            doc,
            isVisibleInBoard,
            group: groupFromSubscription,
            position,
          } = docUpdate;

          if (!groups) return;

          const previousGroupId = Object.keys(groups).find(gId => Object.values(groups[gId]?.docs ?? {}).map(d => d.id).includes(doc.id));

          if (previousGroupId && !isVisibleInBoard) {
            const previousGroup = getGroup(previousGroupId);

            if (previousGroup) {
              updateGroup({
                groupData: previousGroup,
                updatedDocs: nodeToArray(previousGroup.node.docs).filter(d => d.id !== doc.id),
                boardConfigId,
              });
            }
          }

          if (!groupFromSubscription?.id) return;

          const group = groupFromSubscription?.__typename === 'DocGroupWithPropertyValue'
            ? getGroup(groupFromSubscription?.id)
            : getGroupNoFiltered(groupFromSubscription.id);

          if (!group) return;

          if (!position && !nodeToArray(group.node.docs).map(d => d.id).includes(doc.id)) {
            updateGroup({
              groupData: group,
              updatedDocs: [doc, ...nodeToArray(group.node.docs)],
              boardConfigId,
              cleanSelection: true,
            });
            return;
          }

          if (position && previousGroupId && previousGroupId !== group.node.id) {
            const previousGroup = getGroup(previousGroupId);

            if (previousGroup && !nodeToArray(group?.node.docs).map(d => d.id).includes(doc.id)) {
              updateGroup({
                groupData: previousGroup,
                updatedDocs: nodeToArray(previousGroup.node.docs).filter(d => d.id !== doc.id),
                boardConfigId,
              });

              updateGroup({
                groupData: group,
                updatedDocs: getNewDocList(
                  doc,
                  nodeToArray(group?.node.docs),
                  position,
                ),
                boardConfigId,
                cleanSelection: true,
              });
            }
            return;
          }

          if (position) {
            updateGroup({
              groupData: group,
              updatedDocs: reorderList(
                doc,
                nodeToArray(group?.node.docs),
                position,
              ),
              boardConfigId,
              cleanSelection: true,
            });
          }
        });
      },
    },
  );
};

const reorderList = (doc: DocBaseFragment, docs: Array<DocBaseFragment>, position: ListPosition) => {
  const result = Array.from(docs);

  const startIndex = docs.findIndex(d => d.id === doc.id);
  if (startIndex !== -1) {
    result.splice(startIndex, 1);
  }

  const indexBefore = result.findIndex(d => d.id === position.after) + 1;
  result.splice(indexBefore, 0, doc);

  return result;
};

const getNewDocList = (doc: DocBaseFragment, docs: Array<DocBaseFragment>, position: ListPosition) => {
  if (!docs.length) return [doc];

  const indexBefore = docs.findIndex(d => d.id === position.after) + 1;
  docs.splice(indexBefore, 0, doc);
  return docs;
};
