import { SlideRightIcon } from '@cycle-app/ui/icons';
import { twJoin } from 'tailwind-merge';

type Props = {
  direction: 'right' | 'left';
  className?: string;
};

export const ToggleSlidePanelIcon = ({
  direction, className, 
}: Props) => {
  return (
    <div
      className={twJoin(
        'flex size-4 items-center justify-center text-disabled duration-300',
        direction === 'right' && '[transform:rotateY(180deg)]',
        className,
      )}
    >
      <SlideRightIcon
        className="origin-left group-hover:scale-x-125"
      />
    </div>
  );
};
