import {
  ChangeBoardEmojiDocument,
  ChangeBoardInfoDocument,
  ProductBySlugDocument,
  PublishBoardDocument,
  PublishBoardMutationVariables,
  RemoveBoardDocument,
  UpdateBoardDocument,
  UpdateBoardMutationVariables,
  Color,
} from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Events, Methods, Objects, Sources } from 'src/constants/analytics.constants';
import { PageId } from 'src/constants/routing.constant';
import { useCacheBoardStarred } from 'src/hooks';
import { useProduct } from 'src/hooks/api/useProduct';
import { useLoader } from 'src/hooks/useLoader';
import { useMergeQuery } from 'src/hooks/useMergeQuery';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { defaultPagination } from 'src/utils/pagination.util';
import { getParams, getUrl } from 'src/utils/routing.utils';
import { getBoardSlug } from 'src/utils/slug.util';
import { removeBoardFromSection } from 'src/utils/update-cache/boards-cache.util';

import { useBoardLinkCache } from '../../boards/useBoardLinkCache';

const trackBoardUpdate = () => {
  trackAnalytics(Events.BoardUpdated, {
    method: Methods.UI,
    object: [Objects.BoardName, Objects.BoardDescription],
  });
};

const trackEmojiUpdate = () => {
  trackAnalytics(Events.BoardUpdated, {
    method: Methods.UI,
    object: Objects.BoardEmoji,
  });
};

export default function useBoardMutations() {
  const history = useHistory();
  const { product } = useProduct();
  const mergeProduct = useMergeQuery({
    query: ProductBySlugDocument,
  });
  const { remove } = useCacheBoardStarred();
  const { removeBoardLinkFromCache } = useBoardLinkCache();
  const [changeBoardEmojiMutation, { loading: loadingChangeBoardEmoji }] = useSafeMutation(ChangeBoardEmojiDocument, {
    onCompleted: trackEmojiUpdate,
  });
  const [changeBoardInfoMutation, { loading: loadingChangeBoardInfo }] = useSafeMutation(ChangeBoardInfoDocument, {
    onCompleted: trackBoardUpdate,
  });
  const [updateBoardMutation, { loading: loadingUpdateBoard }] = useSafeMutation(UpdateBoardDocument, {
    onCompleted: trackBoardUpdate,
  });
  const [publishBoardMutation, { loading: loadingPublishBoard }] = useSafeMutation(PublishBoardDocument, {
    onCompleted: () => trackAnalytics(Events.BoardCreated, {
      source: Sources.Sidebar,
    }),
  });
  const [removeBoardMutation] = useSafeMutation(RemoveBoardDocument, {
    onCompleted: ({ removeBoard }) => {
      trackAnalytics(Events.BoardDeleted, { method: Methods.UI });
      if (removeBoard?.id) {
        remove(removeBoard.id);
      }
    },
  });

  const loading =
    loadingChangeBoardEmoji ||
    loadingChangeBoardInfo || loadingUpdateBoard || loadingPublishBoard;

  useLoader({ loading });

  const changeBoardEmoji = useCallback((
    boardId: string,
    emoji: string | null | undefined,
    color?: Color | null,
  ) => changeBoardEmojiMutation({
    variables: {
      boardId,
      emoji,
      color,
    },
    optimisticResponse: {
      updateBoard: {
        __typename: 'Board',
        id: boardId,
        emoji,
        color,
      },
    },
  }), [changeBoardEmojiMutation]);

  const changeBoardInfo = useCallback(async (boardId: string, name: string, description: string) => {
    if (getParams().boardId === boardId) {
      history.replace(getUrl(PageId.Board, {
        boardSlug: getBoardSlug({
          id: boardId,
          name,
        }),
      }));
    }

    await changeBoardInfoMutation({
      variables: {
        boardId,
        name,
        description,
      },
      optimisticResponse: {
        updateBoard: {
          __typename: 'Board',
          id: boardId,
          name,
          description,
        },
      },
    });
  }, [changeBoardInfoMutation, history]);

  const removeBoard = useCallback((boardId: string) => removeBoardMutation({
    variables: { boardId },
    optimisticResponse: {
      removeBoard: {
        __typename: 'Board',
        id: boardId,
      },
    },
    update: (cache, { data }) => {
      if (!product || !data?.removeBoard?.id) return;

      mergeProduct(
        {
          slug: product.slug,
          ...defaultPagination,
        },
        {
          product: {
            boardSections: {
              edges: removeBoardFromSection(product.boardSections.edges, data?.removeBoard.id),
            },
          },
        },
        'replace',
      );
      removeBoardLinkFromCache(cache, { boardId: data.removeBoard.id });
      // We removed the board from the sections, we also need to remove it the cache.
      // Some queries may still request the board node.
      cache.evict({ id: data.removeBoard.id });
      cache.gc();
    },
  }), [removeBoardMutation, product, mergeProduct, removeBoardLinkFromCache]);

  const updateBoard = ({
    description, ...variables
  }: UpdateBoardMutationVariables) => updateBoardMutation({
    variables: {
      ...variables,
      ...description?.trim() && { description },
    },
  });

  const publishBoard = (variables: PublishBoardMutationVariables) => publishBoardMutation({ variables });

  return {
    loading,
    changeBoardEmoji,
    changeBoardInfo,
    removeBoard,
    updateBoard,
    publishBoard,
  };
}

export const useChangeBoardEmojiMutation = () => {
  const [mutate, { loading: isLoading }] = useSafeMutation(ChangeBoardEmojiDocument, {
    onCompleted: trackEmojiUpdate,
  });

  const changeBoardEmoji = (
    boardId: string,
    emoji: string | null | undefined,
    color?: Color | null,
  ) => mutate({
    variables: {
      boardId,
      emoji,
      color,
    },
    optimisticResponse: {
      updateBoard: {
        __typename: 'Board',
        id: boardId,
        emoji,
        color,
      },
    },
  });

  return {
    changeBoardEmoji,
    isLoading,
  };
};
