import { formatDuration, intervalToDuration } from 'date-fns';
import { useEffect, useState, ReactNode } from 'react';


const ONE_MINUTE = 1_000 * 60;

type TrialPlanProps = {
  endOfTrial?: string | null;
  title?: ReactNode;
  upgradeLink?: ReactNode;
};

const useTrialCountdown = (endOfTrial: string) => {
  const [durationLeft, setDurationLeft] = useState(intervalToDuration({
    start: new Date(endOfTrial),
    end: new Date(),
  }));

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (!durationLeft.hours) {
      interval = setInterval(() => {
        const newDuration = intervalToDuration({
          start: new Date(endOfTrial),
          end: new Date(),
        });
        setDurationLeft(newDuration);
      }, ONE_MINUTE);
    }

    return () => {
      if (!interval) return;
      clearInterval(interval);
    };
  }, [durationLeft.hours, endOfTrial]);

  return durationLeft.days
    ? formatDuration(durationLeft, { format: ['days'] })
    : formatDuration(durationLeft, { format: ['hours', 'minutes'] });
};

export const TrialPlan = ({
  endOfTrial,
  title = 'Trial',
  upgradeLink,
}: TrialPlanProps) => {
  return (
    <div className="flex flex-col rounded-lg bg-grey-100 p-3 text-primary dark:bg-grey-850">
      <header className="flex flex-wrap items-center justify-between gap-1">
        <div className="mb-2 max-w-full flex-none truncate text-caption font-medium text-primary">
          {title}
        </div>

        {upgradeLink}
      </header>

      {endOfTrial && (
        isTrialEnded(endOfTrial)
          ? (
            <div className="max-w-full flex-none truncate text-caption text-secondary">
              Ended
            </div>
          )
          : <CountDown endOfTrial={endOfTrial} />
      )}
    </div>
  );
};

// The trial is ended if we have passed the end date
const isTrialEnded = (endOfTrial: string) => new Date() > new Date(endOfTrial);

const CountDown = ({ endOfTrial }: { endOfTrial: string }) => {
  const countdown = useTrialCountdown(endOfTrial);
  return (
    <div className="max-w-full flex-none truncate text-caption text-secondary">
      {`Ends in ${countdown}`}
    </div>
  );
};
