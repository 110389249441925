import {
  DeleteCompanyDocument,
  DeleteCompanyMutationVariables,
  namedOperations,
} from '@cycle-app/graphql-codegen';

import useSafeMutation from 'src/hooks/useSafeMutation';
import { addErrorToaster } from 'src/utils/errorToasters.utils';

import { useProduct } from '../../useProduct';

export const useCompanyRemove = () => {
  const { product } = useProduct();
  const [deleteCompanyMutation, { loading }] = useSafeMutation(DeleteCompanyDocument, {
    onError: () => {
      addErrorToaster({ message: '🧐 Oops, looks like something went wrong on our side, we’re on it!' });
    },
    refetchQueries: () => [namedOperations.Query.productCustomers],
    update(cache, { data }) {
      if (!data?.deleteCompany || !product) return;

      const idToRemove = data.deleteCompany.id;

      cache.modify({
        id: cache.identify(product),
        fields: {
          // @deprecated
          companyCount: (companyCount) => Math.max(0, companyCount - 1),
          companies: (companies, { readField }) => ({
            ...companies,
            edges: companies.edges.filter((edge: any) => idToRemove !== readField('id', edge.node)),
            count: Math.max(0, companies.count - 1),
          }),
        },
      });

      // Need also to evict as company can be in the filters.
      const normalizedId = cache.identify({
        id: data.deleteCompany.id,
        __typename: data.deleteCompany.__typename,
      });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  const deleteCompany = (variables: DeleteCompanyMutationVariables) => deleteCompanyMutation({
    variables,
  });

  return {
    deleteCompany,
    isLoading: loading,
  };
};
