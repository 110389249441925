import { Icon } from '@cycle-app/ui';
import {
  AttioIcon,
  CannyIcon,
  DiscordIcon,
  EmailIcon,
  FreshDeskIcon,
  FrontIcon,
  FullStoryIcon,
  GainsightIcon,
  GongIcon,
  GoogleMeetIcon,
  IntercomIcon,
  ProductBoardIcon,
  SalesforceIcon,
  ZendeskIcon,
  FirefliesIcon,
  FathomIcon,
} from '@cycle-app/ui/icons';

export const sources = [
  {
    id: 'slack',
    icon: <Icon name="brand/slack" className="size-4" />,
    label: 'Slack',
  },
  {
    id: 'intercom',
    icon: <IntercomIcon />,
    label: 'Intercom',
  },
  {
    id: 'zendesk',
    icon: <ZendeskIcon />,
    label: 'Zendesk',
  },
  {
    id: 'hubspot',
    icon: <Icon name="brand/hubspot" className="size-4" />,
    label: 'HubSpot',
  },
  {
    id: 'salesforce',
    icon: <SalesforceIcon />,
    label: 'Salesforce',
  },
  {
    id: 'email',
    icon: <EmailIcon />,
    label: 'Email',
  },
  {
    id: 'productboard',
    icon: <ProductBoardIcon />,
    label: 'ProductBoard',
  },
  {
    id: 'canny',
    icon: <CannyIcon />,
    label: 'Canny',
  },
  {
    id: 'gong',
    icon: <GongIcon />,
    label: 'Gong',
  },
  {
    id: 'front',
    icon: <FrontIcon />,
    label: 'Front',
  },
  {
    id: 'discord',
    icon: <DiscordIcon />,
    label: 'Discord',
  },
  {
    id: 'freshdesk',
    icon: <FreshDeskIcon />,
    label: 'Freshdesk',
  },
  {
    id: 'attio',
    icon: <AttioIcon />,
    label: 'Attio',
  },
  {
    id: 'fullstory',
    icon: <FullStoryIcon />,
    label: 'FullStory',
  },
  {
    id: 'gainsight',
    icon: <GainsightIcon />,
    label: 'Gainsight',
  },
  {
    id: 'google-meet',
    icon: <GoogleMeetIcon />,
    label: 'Google Meet',
  },
  {
    id: 'fathom',
    icon: <FathomIcon />,
    label: 'Fathom',
  },
  {
    id: 'fireflies',
    icon: <FirefliesIcon />,
    label: 'Fireflies',
  },
];
