import { isTouchDevice, getBreakpoint } from '@cycle-app/utilities';
import { useEffect, useRef } from 'react';
import { useThrottledCallback } from 'use-debounce';

import { getDocPanel, setDocPanel } from 'src/reactives/docRightPanel.reactive';
import { getResponsive, setResponsive } from 'src/reactives/responsive.reactive';
import { getSidebarCollapsed, getSidebarWidth, setSidebarCollapsed } from 'src/reactives/sidebar.reactive';
import { isDevEnv } from 'src/utils/env.util';

// Width of the window below which the sidebar is automatically closed
const SIDEBAR_LIMIT = 780;

// Width of the doc panel below which the dod right panel is automatically closed
const RIGHTPANEL_LIMIT = 1000;

/**
 * - Automatically collapses the doc right panel when the doc panel is too small
 * - Automatically collapses the sidebar when the window is too small
 * and restores the previous state when the window is large enough
 * */
export const useResizeWindow = () => {
  // We store the previous width of the window to detect when the limit is crossed
  const prevWidth = useRef(window.innerWidth);

  const onResizeWindow = useThrottledCallback(() => {
    const newBreakpoint = getBreakpoint();
    const currentBreakpoint = getResponsive().breakpoint;
    if (newBreakpoint !== currentBreakpoint) {
      setResponsive({ breakpoint: newBreakpoint });
    }

    // If the doc panel is too small, we collapse the right panel
    const sidebarWidth = getSidebarCollapsed().collapsed ? 64 : getSidebarWidth().width;
    const docPanelWidth = window.innerWidth - sidebarWidth;
    const prevDocPanelWidth = prevWidth.current - sidebarWidth;
    if (
      prevDocPanelWidth >= RIGHTPANEL_LIMIT &&
      docPanelWidth < RIGHTPANEL_LIMIT &&
      getDocPanel().isExpanded
    ) {
      setDocPanel({ isExpanded: false });
      prevWidth.current = window.innerWidth;
      return;
    }

    // If the window is too small, we collapse the sidebar and store the previous state
    if (
      prevWidth.current >= SIDEBAR_LIMIT &&
      window.innerWidth < SIDEBAR_LIMIT &&
      !getSidebarCollapsed().collapsed
    ) {
      setSidebarCollapsed({
        collapsed: true,
        prevCollapsed: false,
      });
      prevWidth.current = window.innerWidth;
      return;
    }

    // If the window is large enough, we restore the previous state
    if (
      prevWidth.current < SIDEBAR_LIMIT &&
      window.innerWidth >= SIDEBAR_LIMIT &&
      getSidebarCollapsed().collapsed && !getSidebarCollapsed().prevCollapsed
    ) {
      setSidebarCollapsed({
        collapsed: false,
      });
      prevWidth.current = window.innerWidth;
      return;
    }

    prevWidth.current = window.innerWidth;
  }, 100);

  useEffect(() => {
    if (isTouchDevice() && !isDevEnv()) return;

    const mediaQuery = window.matchMedia('(pointer: coarse)');

    const onChangeMediaQuery = (event: MediaQueryListEvent) => {
      setResponsive({ isTouchDevice: event.matches });
    };

    window.addEventListener('resize', onResizeWindow);
    mediaQuery.addEventListener('change', onChangeMediaQuery);

    return () => {
      window.removeEventListener('resize', onResizeWindow);
      mediaQuery.removeEventListener('change', onChangeMediaQuery);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
