import { useQuery } from '@apollo/client';
import {
  BoardWithConfigQuery,
  BoardConfigQueryVariables,
  BoardWithConfigDocument,
  MateFragment,
  Property,
  PublishedBoardConfigFullFragment,
  FilterPropertyRuleSelectableValueDocFragment,
  OperatorIsInOrNot,
  Order,
  ViewType,
  BoardType,
  SectionType,
} from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { createContext, useContextSelector, ContextSelector, Context, useHasParentContext } from '@fluentui/react-context-selector';
import keyBy from 'lodash/keyBy';
import { FC, useEffect, useMemo, useRef } from 'react';

import { routing, PageId } from 'src/constants/routing.constant';
import { useRouteMatch } from 'src/hooks';
import { useProduct } from 'src/hooks/api/useProduct';
import { useIsReleasesEnabled } from 'src/hooks/releases/useIsReleasesEnabled';
import { useBoardId, useBoardSlug } from 'src/hooks/usePathParams';
import { setViewType } from 'src/reactives/boardConfig.reactive';
import { FetchMore } from 'src/types/apollo.types';
import { extract } from 'src/types/graphql.types';
import { getBoardConfigGroupBy, getHasFilterPreventingNewDoc, isFilterDoctypePropertyRuleByInsight } from 'src/utils/boardConfig/boardConfig.util';
import { assigneeIsCompatible, docShouldHaveAnAssignee, docShouldHaveCustomer, docShouldHaveCompany } from 'src/utils/compatibility.util';
import { defaultPagination } from 'src/utils/pagination.util';

import { setLastView } from '../reactives/lastView.reactive';

export interface BoardConfigContextValue {
  boardId: string | null;
  boardSlug: string | null;
  boardType: BoardType | undefined;
  boardConfig: PublishedBoardConfigFullFragment | null;
  loading: boolean;
  hasError: boolean;
  refetch: VoidFunction;
  fetchMore: FetchMore<BoardWithConfigQuery, BoardConfigQueryVariables>;
  usersForAssignee: Array<MateFragment>;
  builtInDisplay: BuiltInDisplay;
  displayedPropertiesIds: string[];
  assigneeIsRequired: boolean;
  hasFilterPreventingNewDoc: boolean;
  groupByProperty: Property | undefined;
  notFound: boolean;
  isDndGroupsEnabled: boolean;
  isGroupCreationDisabled: boolean;
  isGroupByStatus: boolean;
  customerIsRequired: boolean;
  companyIsRequired: boolean;
  docParents: FilterPropertyRuleSelectableValueDocFragment['value'][];
  boardConfigGroupBy: ReturnType<typeof getBoardConfigGroupBy> | null;
  insightDocType: {
    isTheOnlyOne?: boolean;
    isInBoard?: boolean;
  };
  defaultAssigneeId: string | undefined;
  isSortByDate: boolean;
  isSortByMostRecent: boolean;
  isSortByOldest: boolean;
  isAnalytics: boolean;
  isFeedbackView: boolean;
}

export interface BuiltInDisplay {
  assignee: boolean;
  creator: boolean;
  comments: boolean;
  createdAt: boolean;
  cover: boolean;
  parent: boolean;
  children: boolean;
  insights: boolean;
  customer: boolean;
  docId: boolean;
  docType: boolean;
  source: boolean;
  status: boolean;
  release: boolean;
  linear: boolean;
  aiState: boolean;
}

const GROUP_BY_RULES_WITH_DISABLED_GROUPS_DND: Array<Property['__typename']> = [
  'CreatorDefinition',
  'AssigneeDefinition',
  'DoctypeDefinition',
  'StatusDefinition',
  'SourceDefinition',
];

const GROUP_BY_RULE_WITH_GROUP_CREATION_DISABLED: Array<Property['__typename']> = [
  'CreatorDefinition',
  'AssigneeDefinition',
  'DoctypeDefinition',
  'StatusDefinition',
  'SourceDefinition',
];

export const BoardConfigContext = createContext<BoardConfigContextValue | undefined>(undefined) as Context<BoardConfigContextValue>;

type ProviderProps = { skip?: boolean };

export const BoardConfigContextProvider: FC<React.PropsWithChildren<ProviderProps>> = ({
  children, ...props
}) => {
  const isWrappedWithContext = useHasParentContext(BoardConfigContext);
  return isWrappedWithContext ? <>{children}</> : <Provider {...props}>{children}</Provider>;
};

const Provider: FC<React.PropsWithChildren<ProviderProps>> = ({
  children, skip,
}) => {
  const { product } = useProduct();

  const previousBoardId = useRef<string | null>(null);

  const matchInboxView = useRouteMatch(routing[PageId.InboxView]);

  const boardId = useBoardId();
  const boardSlug = useBoardSlug();

  const {
    data,
    refetch,
    fetchMore,
    loading,
    error,
  } = useQuery(BoardWithConfigDocument, {
    skip: skip || !boardId,
    // Logic would want to use cache-and-network but doing so will trigger a query with default params when calling fetchMore,
    // leading to cache being rewritten and new page content not merged
    fetchPolicy: 'cache-first',
    variables: {
      id: boardId as string,
      ...defaultPagination,
    },
  });

  const notFound = !loading && data?.node === null;

  useEffect(() => {
    if (skip || !boardId) return;
    if (previousBoardId.current !== boardId) {
      previousBoardId.current = boardId ?? null;
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      refetch({ id: boardId });
    }
  }, [boardId, refetch, skip]);

  const board = extract('Board', data?.node);
  const boardType = board?.type;
  const viewType = board?.publishedBoardConfig?.viewType;
  useEffect(() => {
    if (viewType) {
      setViewType({ viewType });
      setLastView({ isNewInbox: !!matchInboxView && viewType === ViewType.List });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewType]);

  const publishedBoardConfig = board?.publishedBoardConfig ?? null;
  // TODO: find why filterProperties are not always present in boardConfig
  const boardConfig = publishedBoardConfig?.filterProperties ? publishedBoardConfig : null;

  const usersForAssignee = useMemo(
    () => product?.users.edges.map(edge => ({
      ...edge.node,
      _compatibleWithBoardConfig: assigneeIsCompatible(edge.node.id, boardConfig),
    })) ?? [],
    [product?.users, boardConfig],
  );

  const isReleasesEnabled = useIsReleasesEnabled();

  const isSortByDate = boardConfig?.sortByProperty?.__typename === 'SortByPropertyCreatedAt';

  const isSortByOldest =
    boardConfig?.sortByProperty?.__typename === 'SortByPropertyCreatedAt' &&
    boardConfig.sortByProperty.order === Order.Asc;

  const isSortByMostRecent =
    boardConfig?.sortByProperty?.__typename === 'SortByPropertyCreatedAt' &&
    boardConfig.sortByProperty.order === Order.Desc;

  const value = useMemo<BoardConfigContextValue>(() => {
    const displayedProperties = nodeToArray(boardConfig?.properties).filter((p) => p.displayed);
    const displayedPropertiesByTypename = keyBy(displayedProperties, p => p.property.__typename);
    const displayedPropertiesIds = displayedProperties.map(p => p.property.id);

    const builtInDisplay = {
      assignee: 'AssigneeDefinition' in displayedPropertiesByTypename,
      creator: 'CreatorDefinition' in displayedPropertiesByTypename,
      createdAt: 'CreatedatDefinition' in displayedPropertiesByTypename,
      cover: 'CoverDefinition' in displayedPropertiesByTypename,
      parent: 'ParentDefinition' in displayedPropertiesByTypename,
      customer: 'BuiltInCustomerDefinition' in displayedPropertiesByTypename,
      docId: 'DocidDefinition' in displayedPropertiesByTypename,
      docType: 'DoctypeDefinition' in displayedPropertiesByTypename,
      source: 'SourceDefinition' in displayedPropertiesByTypename,
      comments: 'CommentDefinition' in displayedPropertiesByTypename,
      children: 'ChildrenDefinition' in displayedPropertiesByTypename,
      insights: 'BuiltInInsightDefinition' in displayedPropertiesByTypename,
      status: 'StatusDefinition' in displayedPropertiesByTypename,
      release: 'BuiltInReleaseDefinition' in displayedPropertiesByTypename && isReleasesEnabled,
      linear: 'LinearAutomationDefinition' in displayedPropertiesByTypename,
      aiState: 'BuiltInAiStateDefinition' in displayedPropertiesByTypename,
    };

    const boardConfigGroupBy = boardConfig ? getBoardConfigGroupBy(boardConfig) : null;
    const groupByProperty = boardConfigGroupBy?.property as Property | undefined;

    const isDndGroupsEnabled = !GROUP_BY_RULES_WITH_DISABLED_GROUPS_DND.includes(groupByProperty?.__typename);
    const isGroupCreationDisabled = GROUP_BY_RULE_WITH_GROUP_CREATION_DISABLED.includes(groupByProperty?.__typename);

    const isGroupByStatus = groupByProperty?.__typename === 'StatusDefinition';

    const docParentFilter = nodeToArray(boardConfig?.filterProperties)
      .find(node => node.__typename === 'FilterPropertyRuleDocParent');

    const docParents = (
      docParentFilter?.__typename === 'FilterPropertyRuleDocParent' &&
        docParentFilter.docParentRule.__typename === 'RuleDocParentMultipleValues' &&
        docParentFilter.docParentRule.operator === OperatorIsInOrNot.Is
    ) ? nodeToArray(docParentFilter.docParentRule.selectedValues).map(node => node.value)
      : [];

    const insightDoctypeFilter = isFilterDoctypePropertyRuleByInsight(boardConfig?.filterProperties);
    const insightDocType = {
      isTheOnlyOne: insightDoctypeFilter?.isOnlyInsightSelected,
      isInBoard: insightDoctypeFilter?.isInsightSelected,
    };

    const assigneeIsRequired = docShouldHaveAnAssignee(boardConfig);
    const companyIsRequired = docShouldHaveCompany(boardConfig);
    const customerIsRequired = docShouldHaveCustomer(boardConfig);

    const hasFilterPreventingNewDoc = !!boardConfig && getHasFilterPreventingNewDoc(boardConfig);

    return ({
      assigneeIsRequired,
      boardConfig,
      boardConfigGroupBy,
      boardId: boardId ?? null,
      boardSlug: boardSlug ?? null,
      builtInDisplay,
      companyIsRequired,
      customerIsRequired,
      docParents,
      displayedPropertiesIds,
      fetchMore,
      groupByProperty,
      hasError: !!error,
      hasFilterPreventingNewDoc,
      notFound,
      insightDocType,
      isDndGroupsEnabled,
      isGroupCreationDisabled,
      isGroupByStatus,
      loading,
      refetch,
      usersForAssignee,
      defaultAssigneeId: product?.defaultAssignee?.id,
      isSortByDate,
      isSortByMostRecent,
      isSortByOldest,
      boardType,
      isAnalytics: boardType === BoardType.Analytics,
      isFeedbackView: board?.section?.type === SectionType.Feedback,
    });
  }, [
    boardConfig,
    boardId,
    boardSlug,
    error,
    fetchMore,
    loading,
    refetch,
    usersForAssignee,
    isReleasesEnabled,
    product?.defaultAssignee?.id,
    isSortByDate,
    isSortByMostRecent,
    isSortByOldest,
    notFound,
    boardType,
    board?.section?.type,
  ]);

  return (
    <BoardConfigContext.Provider value={value}>
      {children}
    </BoardConfigContext.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const useBoardConfig = <T,>(selector: ContextSelector<BoardConfigContextValue, T>) => {
  const isWrappedWithContext = useHasParentContext(BoardConfigContext);
  if (!isWrappedWithContext) throw new Error('useBoardConfig must be used within a board');
  return useContextSelector(BoardConfigContext, selector);
};
