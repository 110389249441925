import { SourceType } from '@cycle-app/graphql-codegen';

const sourceTypeLabels: Record<SourceType, string> = {
  [SourceType.Cycle]: 'Cycle',
  [SourceType.Gong]: 'Gong',
  [SourceType.GoogleMeet]: 'Google Meet',
  [SourceType.Hubspot]: 'HubSpot',
  [SourceType.Intercom]: 'Intercom',
  [SourceType.Mail]: 'Mail',
  [SourceType.MicrosoftTeams]: 'Microsoft Teams',
  [SourceType.Salesforce]: 'Salesforce',
  [SourceType.Slack]: 'Slack',
  [SourceType.Web]: 'Extension',
  [SourceType.Zapier]: 'Zapier',
  [SourceType.Zendesk]: 'Zendesk',
  [SourceType.Zoom]: 'Zoom',
};

const sourceCategoryLabels: Record<string, string> = sourceTypeLabels;

export const sourceCategoryLabel = (value: string) =>
  sourceCategoryLabels[value] ?? value.charAt(0) + value.slice(1).toLowerCase().replace(/_/g, ' ');
