import { IntegrationType } from '@cycle-app/graphql-codegen';
import { Icon } from '@cycle-app/ui';
import {
  AsanaIcon,
  ChromeIcon,
  ClickUpIcon,
  CycleStandardIcon,
  DiscordIcon,
  EmailIcon,
  FrontIcon,
  FullStoryIcon,
  GitLabIcon,
  GongIcon,
  GrainIcon,
  IntercomIcon,
  JiraIcon,
  LinearIcon,
  MakeIcon,
  ModjoIcon,
  MondayIcon,
  PhoneIcon,
  SalesforceIcon,
  ShortcutAppIcon,
  SliteIcon,
  WebhookIcon,
  ZapierIcon,
  ZendeskIcon,
} from '@cycle-app/ui/icons';
import { getArrayWithoutDuplicates } from '@cycle-app/utilities';
import { ReactNode } from 'react';

import { CommandKey } from 'src/constants/editor.constants';
import { HELP_URL_SLACK, HELP_INTEGRATION } from 'src/constants/help.constants';
import { PageId } from 'src/constants/routing.constant';
import { Integration, FrontEndIntegration } from 'src/types/integrations.types';

import { mapAddOnsData } from './addOn.constants';

export type IntegrationsData = {
  icon: ReactNode;
  title?: string;
  label: string;
  labelOne?: string;
  description?: string;
  learnUrl?: string;
  order: number;
  commandKey?: CommandKey;
  action?: string;
  remove?: {
    title: string;
    text: string | JSX.Element;
  };
  installationMode: 'install' | 'by-default' | 'user-level' | 'none';
};

export const integrationsDataMap: Record<Integration, IntegrationsData> = {
  [IntegrationType.Github]: {
    icon: <Icon name="brand/github" className="size-4" />,
    title: 'GitHub',
    label: 'GitHub issues',
    labelOne: 'GitHub issue',
    description: "Create issues or project right from Cycle or link existing ones to Cycle's Features.",
    learnUrl: `${HELP_INTEGRATION}/github`,
    order: 1,
    commandKey: 'GITHUB_ISSUE',
    remove: {
      title: 'Remove Github integration',
      text: (
        <>
          Are you sure you want to remove Github integration?
          <br />
          You will no longer access Github issues.
        </>
      ),
    },
    installationMode: 'install',
  },
  [IntegrationType.Slack]: {
    icon: <Icon name="brand/slack" className="size-4" />,
    label: 'Slack',
    description: 'Sync your customers, send feedback and get updates in Slack',
    learnUrl: HELP_URL_SLACK,
    order: 2,
    remove: {
      title: 'Remove Slack integration',
      text: (
        <>
          Are you sure you want to remove Slack integration?
          <br />
          You will no longer be able to create docs and view notifications from Slack.
        </>
      ),
    },
    installationMode: 'install',
  },
  [IntegrationType.Linear]: {
    icon: <LinearIcon />,
    title: 'Linear',
    label: 'Linear',
    labelOne: 'Linear',
    description: 'Link projects and issues',
    learnUrl: `${HELP_INTEGRATION}/linear`,
    order: 3,
    commandKey: 'LINEAR',
    remove: {
      title: 'Remove Linear integration',
      text: (
        <>
          Are you sure you want to remove Linear integration?
          <br />
          You will no longer access Linear issues and projects.
        </>
      ),
    },
    installationMode: 'install',
  },
  [IntegrationType.Notion]: {
    icon: <Icon name="brand/notion" className="size-4" />,
    title: 'Notion',
    label: 'Notion',
    labelOne: 'Notion page',
    description: 'Link, preview, and embed pages',
    learnUrl: `${HELP_INTEGRATION}/notion`,
    order: 4,
    commandKey: 'NOTION',
    remove: {
      title: 'Remove Notion integration',
      text: (
        <>
          Are you sure you want to remove Notion integration?
          <br />
          You will no longer access Notion pages.
        </>
      ),
    },
    installationMode: 'install',
  },
  [IntegrationType.Figma]: {
    icon: <Icon name="brand/figma" className="size-4" />,
    label: 'Figma',
    description: 'Link, preview, and embed pages',
    learnUrl: `${HELP_INTEGRATION}/figma-loom-miro-and-pitch`,
    order: 5,
    installationMode: 'by-default',
  },
  [IntegrationType.Intercom]: {
    icon: <IntercomIcon />,
    label: 'Intercom',
    description: 'Sync your customers, send feedback and get updates in Intercom',
    learnUrl: `${HELP_INTEGRATION}/intercom`,
    order: 6,
    installationMode: 'install',
  },
  [IntegrationType.Zendesk]: {
    icon: <ZendeskIcon />,
    label: 'Zendesk',
    description: 'Sync customers and create docs',
    order: 7,
    installationMode: 'install',
  },
  [IntegrationType.Extension]: {
    icon: <ChromeIcon />,
    label: 'Extension',
    description: 'Capture feedback from anywhere on the web',
    learnUrl: `${HELP_INTEGRATION}/chrome-extension`,
    order: 8,
    installationMode: 'none',
  },
  [IntegrationType.Mail]: {
    icon: <EmailIcon />,
    label: 'Email',
    description: 'Forward emails to your feedback inbox',
    action: 'Click to copy email',
    learnUrl: `${HELP_INTEGRATION}/email`,
    order: 9,
    installationMode: 'install',
  },
  [IntegrationType.Zapier]: {
    icon: <ZapierIcon />,
    label: 'Zapier',
    description: 'Capture feedback from thousands of sources with Zapier (survey results, recordings, sheets, etc).',
    learnUrl: `${HELP_INTEGRATION}/zapier`,
    order: 10,
    installationMode: 'none',
  },
  [IntegrationType.Hubspot]: {
    icon: <Icon name="brand/hubspot" className="size-4" />,
    label: 'HubSpot',
    learnUrl: `${HELP_INTEGRATION}/hubspot`,
    order: 11,
    installationMode: 'install',
  },
  [IntegrationType.Gong]: {
    icon: <GongIcon />,
    label: 'Gong',
    learnUrl: `${HELP_INTEGRATION}/gong`,
    description: 'Capture feedback from your incoming calls',
    order: 12,
    installationMode: 'install',
  },
  [FrontEndIntegration.JIRA]: {
    icon: <JiraIcon />,
    label: 'Jira',
    order: 12,
    installationMode: 'install',
  },
  [FrontEndIntegration.GITLAB]: {
    icon: <GitLabIcon />,
    label: 'GitLab',
    order: 13,
    installationMode: 'install',
  },
  [FrontEndIntegration.SHORTCUT]: {
    icon: <ShortcutAppIcon />,
    label: 'Shortcut',
    order: 14,
    installationMode: 'install',
  },
  [FrontEndIntegration.CYCLE]: {
    icon: <CycleStandardIcon variant="primary" />,
    label: 'Cycle',
    order: 15,
    installationMode: 'by-default',
  },
  [FrontEndIntegration.FRONT]: {
    icon: <FrontIcon size={16} />,
    label: 'Front',
    order: 16,
    installationMode: 'install',
  },
  [IntegrationType.Salesforce]: {
    icon: <SalesforceIcon size={18} />,
    label: 'Salesforce',
    order: 17,
    installationMode: 'install',
  },
  [FrontEndIntegration.MAKE]: {
    icon: <MakeIcon size={18} />,
    label: 'Make',
    order: 18,
    installationMode: 'none',
  },
  [FrontEndIntegration.ZAPIER_COLLAB]: {
    icon: <ZapierIcon />,
    label: 'Zapier',
    learnUrl: `${HELP_INTEGRATION}/zapier`,
    order: 19,
    installationMode: 'none',
  },
  [FrontEndIntegration.CYCLE_API]: {
    icon: <CycleStandardIcon variant="secondary" />,
    label: 'Cycle API',
    description: 'Sync customers & send feedback from custom sources. Get inspired by our favorite use case',
    learnUrl: PageId.SettingsAPI,
    order: 20,
    installationMode: 'none',
  },
  [FrontEndIntegration.WEBHOOKS]: {
    icon: <WebhookIcon />,
    label: 'Webhooks',
    description: 'Trigger custom workflows starting from a Cycle event. Get inspired by our favorite use cases.',
    learnUrl: PageId.SettingsAPI,
    order: 21,
    installationMode: 'none',
  },
  [FrontEndIntegration.ATTIO]: {
    icon: mapAddOnsData.attio.logo,
    label: mapAddOnsData.attio.name,
    description: mapAddOnsData.attio.description,
    order: 22,
    installationMode: 'none',
  },
  [FrontEndIntegration.DISCORD]: {
    icon: <DiscordIcon />,
    label: 'Discord',
    description: '',
    order: 23,
    installationMode: 'none',
  },
  [FrontEndIntegration.FRESHDESK]: {
    icon: mapAddOnsData['fresh-desk'].logo,
    label: mapAddOnsData['fresh-desk'].name,
    description: mapAddOnsData['fresh-desk'].description,
    order: 24,
    installationMode: 'none',
  },
  [FrontEndIntegration.GAINSIGHT]: {
    icon: mapAddOnsData['grain-sight'].logo,
    label: mapAddOnsData['grain-sight'].name,
    description: mapAddOnsData['grain-sight'].description,
    order: 25,
    installationMode: 'none',
  },
  [FrontEndIntegration.GRAINS]: {
    icon: <GrainIcon />,
    label: 'Grains',
    description: '',
    order: 27,
    installationMode: 'none',
  },
  [FrontEndIntegration.MICROSOFT_TEAM]: {
    icon: mapAddOnsData['microsoft-teams'].logo,
    label: mapAddOnsData['microsoft-teams'].name,
    description: mapAddOnsData['microsoft-teams'].description,
    order: 28,
    installationMode: 'none',
  },
  [FrontEndIntegration.MODJO]: {
    icon: <ModjoIcon />,
    label: 'Modjo',
    description: '',
    order: 29,
    installationMode: 'none',
  },
  [FrontEndIntegration.PLANHAT]: {
    icon: mapAddOnsData['plan-hat'].logo,
    label: mapAddOnsData['plan-hat'].name,
    description: mapAddOnsData['plan-hat'].description,
    order: 30,
    installationMode: 'none',
  },
  [FrontEndIntegration.SNOWFLAKE]: {
    icon: mapAddOnsData['snow-flake'].logo,
    label: mapAddOnsData['snow-flake'].name,
    description: mapAddOnsData['snow-flake'].description,
    order: 31,
    installationMode: 'none',
  },
  [FrontEndIntegration.VITALLY]: {
    icon: mapAddOnsData.vitally.logo,
    label: mapAddOnsData.vitally.name,
    description: mapAddOnsData.vitally.description,
    order: 32,
    installationMode: 'none',
  },
  [FrontEndIntegration.CONFLUENCE]: {
    icon: mapAddOnsData.confluence.logo,
    label: mapAddOnsData.confluence.name,
    description: mapAddOnsData.confluence.description,
    order: 32,
    installationMode: 'none',
  },
  [FrontEndIntegration.ASANA]: {
    icon: <AsanaIcon />,
    label: 'Asana',
    description: '',
    order: 33,
    installationMode: 'none',
  },
  [FrontEndIntegration.CLICK_UP]: {
    icon: <ClickUpIcon />,
    label: 'ClickUp',
    description: '',
    order: 34,
    installationMode: 'none',
  },
  [FrontEndIntegration.FULL_STORY]: {
    icon: <FullStoryIcon />,
    label: 'FullStory',
    description: '',
    order: 35,
    installationMode: 'none',
  },
  [FrontEndIntegration.MONDAY]: {
    icon: <MondayIcon />,
    label: 'Monday',
    description: '',
    order: 36,
    installationMode: 'none',
  },
  [FrontEndIntegration.SLITE]: {
    icon: <SliteIcon />,
    label: 'Slite',
    description: '',
    order: 37,
    installationMode: 'none',
  },
  [IntegrationType.Meeting]: {
    icon: <PhoneIcon />,
    label: 'Call recording',
    description: 'Record & transcribe meetings from Zoom, Meet & Teams.',
    order: 3,
    installationMode: 'install',
  },
};

const byIntegrationOrder = (integrationA: Integration, integrationB: Integration) => (
  integrationsDataMap[integrationA].order - integrationsDataMap[integrationB].order);

export const INTEGRATIONS: Integration[] = getArrayWithoutDuplicates([
  ...Object.values(IntegrationType),
  ...Object.values(FrontEndIntegration),
]).sort(byIntegrationOrder);

export const ACTIVE_INTEGRATIONS: Integration[] = [
  IntegrationType.Github,
  IntegrationType.Slack,
  IntegrationType.Linear,
  IntegrationType.Notion,
  IntegrationType.Intercom,
  IntegrationType.Hubspot,
  IntegrationType.Mail,
  IntegrationType.Meeting,
  IntegrationType.Zapier,
  IntegrationType.Extension,
  IntegrationType.Gong,
  IntegrationType.Zendesk,
  IntegrationType.Salesforce,
  FrontEndIntegration.ATTIO,
  FrontEndIntegration.SNOWFLAKE,
  FrontEndIntegration.FRESHDESK,
  FrontEndIntegration.GAINSIGHT,
  FrontEndIntegration.PLANHAT,
  FrontEndIntegration.VITALLY,
  FrontEndIntegration.CONFLUENCE,
  FrontEndIntegration.JIRA,
];
export const SOURCE_INTEGRATIONS: Integration[] = [
  IntegrationType.Slack,
  IntegrationType.Intercom,
  IntegrationType.Mail,
  IntegrationType.Meeting,
  FrontEndIntegration.ATTIO,
  IntegrationType.Hubspot,
  IntegrationType.Gong,
  IntegrationType.Zendesk,
  FrontEndIntegration.CYCLE_API,
  IntegrationType.Zapier,
  IntegrationType.Extension,
  IntegrationType.Salesforce,
  FrontEndIntegration.SNOWFLAKE,
  FrontEndIntegration.FRESHDESK,
  FrontEndIntegration.GAINSIGHT,
  FrontEndIntegration.PLANHAT,
  FrontEndIntegration.VITALLY,
  FrontEndIntegration.MODJO,
  FrontEndIntegration.MICROSOFT_TEAM,
  FrontEndIntegration.DISCORD,
  FrontEndIntegration.FRONT,
  FrontEndIntegration.MAKE,
].sort(byIntegrationOrder);

export const CUSTOMERS_INTEGRATIONS: Integration[] = [
  IntegrationType.Slack,
  IntegrationType.Intercom,
  IntegrationType.Hubspot,
];

export const PREINSTALLED_SOURCE_INTEGRATIONS: Integration[] = [
  IntegrationType.Zapier,
  IntegrationType.Extension,
  // FrontEndIntegration.CHROME,
  FrontEndIntegration.CYCLE_API,
  FrontEndIntegration.WEBHOOKS,
];

export const COLLAB_INTEGRATIONS: Integration[] = [
  IntegrationType.Github,
  IntegrationType.Notion,
  IntegrationType.Linear,
  FrontEndIntegration.WEBHOOKS,
  FrontEndIntegration.CONFLUENCE,
  FrontEndIntegration.JIRA,
  FrontEndIntegration.GITLAB,
  FrontEndIntegration.SHORTCUT,
  FrontEndIntegration.ZAPIER_COLLAB,
  FrontEndIntegration.MAKE,
  FrontEndIntegration.FULL_STORY,
  FrontEndIntegration.CLICK_UP,
  FrontEndIntegration.MONDAY,
  FrontEndIntegration.ASANA,
  FrontEndIntegration.SLITE,
].sort(byIntegrationOrder);

export const ZAPIER_CYCLE_APP_URL = 'https://zapier.com/apps/cycle/integrations';
export const CHROME_STORE_CYCLE_APP_URL = import.meta.env.PROD
  ? 'https://chrome.google.com/webstore/detail/cycle-turn-feedback-into/dlbjafeipdkijijmlllpenhccdhegeaa'
  : 'https://chrome.google.com/webstore/detail/fepoahfpjeokkegjnnjflbccogmnjlph';

export const ADD_ON_INTEGRATIONS: Integration[] = [
  // FrontEndIntegration.ZENDESK,
  IntegrationType.Salesforce,
  FrontEndIntegration.JIRA,
];
