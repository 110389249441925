import { useSubscription } from '@apollo/client';
import {
  UnlinkedDocChildSubscription,
  UnlinkedDocChildDocument,
  UnlinkedDocChildSubscriptionVariables,
} from '@cycle-app/graphql-codegen';

import { useUpdateChildCache } from './cache/cacheHierarchy';

export const useUnlinkedDocChildSubscription = (parentDocId?: string | null) => {
  const updateChild = useUpdateChildCache();
  useSubscription<UnlinkedDocChildSubscription, UnlinkedDocChildSubscriptionVariables>(
    UnlinkedDocChildDocument,
    {
      variables: {
        parentDocId: parentDocId as string,
      },
      onData: async ({ data: { data } }) => {
        if (!parentDocId || !data) return;
        updateChild({
          newParentId: undefined,
          docs: [{
            docId: data?.unlinkedDocChild,
            parentId: parentDocId,
          }],
        });
      },
    },
  );
};
