
import { ThemeType } from '@cycle-app/graphql-codegen';
import { isSafari } from '@cycle-app/utilities';
import { useEffect } from 'react';

import { useGetThemeConfig } from 'src/reactives/theme.reactive';

export const BodyClassList = () => {
  const { colorTheme } = useGetThemeConfig();
  
  useEffect(() => {
    document.body.classList.toggle('dark', colorTheme === ThemeType.Nightfall);
  }, [colorTheme]);

  useEffect(() => {
    if (isSafari) {
      document.body.classList.toggle('safari', true);
    }
  }, []);

  return null;
};