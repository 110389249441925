import { PlusBoxRoundedIcon } from '@cycle-app/ui/icons';
import { useState } from 'react';

import { ReleaseModal } from 'src/components/ReleaseModals';
import { navigateToRelease } from 'src/hooks/useNavigate';
import { useSidebarCollapsed } from 'src/hooks/useSidebarCollapsed';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { getPermission } from 'src/reactives/permission.reactive';

import { CreateNewButton } from './SidebarActions.styles';

export const NewReleaseButton = () => {
  const collapsed = useSidebarCollapsed();
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <CreateNewButton
        $collapsed={collapsed}
        onClick={() => {
          if (getPermission().canCreateRelease) {
            setModalOpen(true);
          } else {
            setLimitationsModal({ action: 'RELEASE_UPDATE' });
          }
        }}
        iconStart={<PlusBoxRoundedIcon />}
        tooltipProps={{
          content: 'New release',
          placement: 'right',
          withPortal: true,
          withWrapper: false,
          disabled: !collapsed,
        }}
      >
        New release
      </CreateNewButton>

      {modalOpen && (
        <ReleaseModal
          title="New release"
          hide={() => setModalOpen(false)}
          onReleaseCreated={releaseId => {
            setModalOpen(false);
            navigateToRelease(releaseId);
          }}
        />
      )}
    </>
  );
};
