import { Tag, Icon } from '@cycle-app/ui';
import { nodeToArray } from '@cycle-app/utilities';
import sortBy from 'lodash/sortBy';
import { useState } from 'react';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';

import { useDoc } from '../../hooks';
import { useDocProductAreaUpdate } from '../../hooks/doc/useDocProductAreaUpdate';
import { useProductAreas } from '../../hooks/product/useProductAreas';
import { isFeedback } from '../../utils/docType.util';
import { ProductAreasManager } from '../ProductAreasManager';

type Props = {
  docId: string;
  disabled?: boolean;
};

export const DocProductAreas = ({
  docId, disabled,
}: Props) => {
  const { doc } = useDoc(docId);
  const docProductAreas = nodeToArray(doc?.productAreas);
  const isDocFeedback = isFeedback(doc?.doctype);
  const productAreaUpdate = useDocProductAreaUpdate();
  const productAreas = useProductAreas();
  const [{
    visible, rect,
  }, setDropdown] = useState<{ visible: boolean; rect: DOMRect | null }>({
    visible: false,
    rect: null,
  });
  return (
    <>
      <DropdownLayer
        closingArea
        withWrapper={false}
        placement="bottom-start"
        disabled={disabled}
        getReferenceClientRect={rect ? (() => rect) : undefined}
        // remove of unnecessary gap
        style={{ marginRight: -6 }}
        content={(
          <ProductAreasManager
            isMulti={isDocFeedback}
            onClearValue={async () => {
              // Clear is displayed for single select only as there is no bulk mutation yet.
              const selectedArea = docProductAreas?.[0];
              if (selectedArea) {
                await productAreaUpdate.remove({
                  docId,
                  productAreaId: selectedArea.id,
                });
              }
            }}
            onSelect={option => productAreaUpdate.update({
              docId,
              productAreaId: option.productAreaId,
              isMulti: option.isMulti,
            })}
            onUnselect={productAreaId => productAreaUpdate.remove({
              docId,
              productAreaId,
            })}
            selectedValues={docProductAreas.map(area => area.id)}
          />
        )}
        visible={visible}
        hide={() => setDropdown({
          visible: false,
          rect,
        })}
      />

      {sortBy(docProductAreas, 'value')
        .filter(selectedValue => !!productAreas.some(a => a.id === selectedValue.id))
        .map(selectValue => (
          <Tag
            key={selectValue.id}
            {...!disabled && {
              onClick: e => {
                e.preventDefault();
                if (!visible) setDropdown({
                  visible: true,
                  rect: e.currentTarget.getBoundingClientRect(),
                });
              },
            }}
            limitSize
            tooltip={{
              placement: 'top',
              withPortal: true,
              withWrapper: false,
              content: (
                <>
                  <div className="flex items-center gap-1">
                    <Icon name="outline/triShapes" />
                    {selectValue.value}
                  </div>
                  {!disabled && (
                    <div className="text-caption text-grey-500">
                      Click to update
                    </div>
                  )}
                </>
              ),
            }}
          >
            {selectValue.value}
          </Tag>
        ))}
    </>
  );
};