import { typo, Button, ActionButton, CycleLogo } from '@cycle-app/ui';
import { Helper } from '@cycle-app/ui/components/Inputs/Input/Input.styles';
import { small, tablet } from '@cycle-app/utilities';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { ToastersContainer, ErrorToastersContainer } from 'src/components/LayoutFooter/LayoutFooter.styles';

import aside from './aside.svg';

export const StyledLogo = styled(CycleLogo)`
  --color1: ${p => (p.theme.colors.text.primary)};
  --color2: ${p => (p.theme.colors.text.primary)};
`;

export const ErrorHelper = styled(Helper)`
  margin-top: 12px;
`;

export const NextButton = styled(Button)`
  ${typo.body600}
`;

export const BackButton = styled(Button).attrs({ variant: 'ternary' })`
 --border: ${p => p.theme.colors.border.primary};
  ${typo.body600}
`;

export const Wrapper = styled.div`
  align-items: center;
  background: ${p => p.theme.colors.lightOnboarding.background.body};
  color: ${p => p.theme.colors.text.primary};
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

interface RootProps {
  $isAnimated?: boolean;
}

export const Root = styled.div<RootProps>`
  ${typo.body400}
  ${p => !p.$isAnimated && `
    background: ${p.theme.colors.lightOnboarding.background.main};
    border-radius: 24px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05), 0px 35px 70px rgba(0, 0, 0, 0.06);
    overflow: hidden;
  `}
  display: flex;
  height: 840px;
  max-width: calc(100vw - 48px);
  max-height: calc(100vh - 48px);
  position: relative;
  width: 1200px;

  ${small} {
    border-radius: 0px;
    height: 100vh;
    margin-left: 0;
    margin-right: 0;
    max-width: 100vw;
    max-height: 100vh;
    width: 100vw;
  }
`;

export const RootAnimated = styled(motion.div)`
  background: ${p => p.theme.colors.lightOnboarding.background.main};
  border-radius: 24px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05), 0px 35px 70px rgba(0, 0, 0, 0.06);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;

  ${small} {
    border-radius: 0px;
  }
`;

interface MainProps {
  $hasHeader: boolean;
}

export const Main = styled.div<MainProps>`
  ${p => (p.$hasHeader
    ? css`
      grid-template-rows: 30px 1fr;
      display: grid;
    `
    : css`display: flex;`
  )}
  gap: 24px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 32px;
  position: relative;
  width: 100%;
  z-index: 1;
`;

interface HeaderProps {
  $isCentered?: boolean;
}

export const Header = styled.div<HeaderProps>`
  align-items: flex-start;
  display: flex;
  flex: none;
  justify-content: space-between;
  margin-bottom: 24px;
  gap: 8px;

  ${p => p.$isCentered && css`justify-content: center;`}

  ${small} {
    justify-content: center;
  }
`;

interface ContentContainerProps {
  $isVerticalCentered?: boolean;
  $isContentGrow?: boolean;
}

export const ContentContainer = styled.div<ContentContainerProps>`
  flex: 1;
  display: ${p => (p.$isVerticalCentered ? 'flex' : 'block')};
  justify-content: center;
  align-items: center;
  width: ${p => (p.$isContentGrow ? '100%' : 'min(100%, 410px)')};
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
`;

interface ContentProps {
  $isVerticalCentered?: boolean;
  $isStretch?: boolean;
}

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${p => p.$isStretch && css`
    flex: 1;
  `};

  ${p => (p.$isVerticalCentered ? css`
    width: 100%;
    height: fit-content;
  ` : css`
    position: absolute;
    top: 35%;
    left: 35px;
    right: 35px;
  `)}

  ${small} {
    position: relative;
    max-width: 100%;
    top: 0;
    left: 0;
    right: 0;
  }
`;

export const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${small} {
    padding: 0;
  }
`;

export const Footer = styled.div<{ $isFixed?: boolean }>`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin-top: 24px;

  > :last-child {
    margin-left: auto;
  }
`;

export const Aside = styled.div`
  background: ${p => (p.theme.isDark ? 'linear-gradient(179.73deg, #323232 0.28%, #282828 98.3%);' : `url("${aside}")`)};
  min-width: 600px;
  max-width: 600px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  ${tablet} {
    min-width: 40%;
    max-width: 40%;

    [data-onboarding-step='infos'] & {
      min-width: 430px;
    }
  }

  ${small} {
    display: none;
  }
`;

export const Title = styled.h1`
  ${typo.headerLarge600}
`;

export const SubTitle = styled.h2`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
  margin-top: 8px;
`;

export const Description = styled.div`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
  margin-top: 24px;
`;

export const StyledToastersContainer = styled(ToastersContainer)`
  right: 12px;
  bottom: 12px;
`;

export const StyledErrorToastersContainer = styled(ErrorToastersContainer)`
  bottom: 12px;
`;

export const Form = styled.form`
  margin-top: 24px;
`;

export const FormGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const LogoutButton = styled(ActionButton)`
  position: absolute;
  top: 0;
  right: 0;
  margin: 24px;
  gap: 8px;
  ${typo.body500}
`;
