import { CustomerFragment } from '@cycle-app/graphql-codegen';
import { CompanyLogo } from '@cycle-app/ui';

export const QuoteCustomer = ({ customer }: { customer: CustomerFragment }) => {
  const singleCustomer = customer.company && customer.company.countCustomers === 1;
  return (
    <div className="flex items-center gap-1.5 text-caption text-secondary">
      <CompanyLogo
        company={customer.company}
        size="XS"
      />

      <span className="select-text truncate text-caption font-medium text-primary">
        {singleCustomer ? customer.company?.name : customer.displayName}
      </span>

      {!singleCustomer && customer.company?.name && (
        <>
          <span>·</span>
          <span className="select-text">
            {customer.company.name}
          </span>
        </>
      )}
    </div>
  );
};