import { QueryHookOptions, useQuery } from '@apollo/client';
import { FetchLinearTeamsDocument, FetchLinearTeamsQuery, IntegrationType } from '@cycle-app/graphql-codegen';
import { useMemo } from 'react';

import { useProductIntegrations } from 'src/hooks/api/useProductIntegrations';
import { isLinearTeam } from 'src/utils/integrations.utils';

export const useLinearTeams = (options?: Pick<QueryHookOptions<FetchLinearTeamsQuery>, 'onCompleted'>) => {
  const { getIntegration } = useProductIntegrations();
  const integration = getIntegration(IntegrationType.Linear);
  const {
    data, loading,
  } = useQuery(FetchLinearTeamsDocument, {
    skip: !integration?.provider || !integration?.id,
    variables: {
      integrationId: integration?.id as string,
    },
    ...options,
  });

  const teams = useMemo(
    () => {
      if (
        data?.node?.__typename !== 'Integration' ||
        data?.node.provider?.__typename !== 'Linear'
      ) return [];

      return data.node.provider.teams.filter(isLinearTeam) || [];
    },
    [data],
  );

  return {
    isLoading: loading,
    teams,
  };
};
