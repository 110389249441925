import { useChangelogBuilderFormContext } from 'src/hooks/releases/useChangelogBuilderFormContext';

import { List, Tag, TagItem } from './ChangelogBuilderContentNoteTags.styles';
import { ChangelogTagLabel } from '../ChangelogTag/ChangelogTag.styles';

export const ChangelogBuilderContentNoteTags = () => {
  const { watch } = useChangelogBuilderFormContext();
  const tags = watch('tags').filter(tag => tag.value.trim());
  const releaseTagStyle = watch('releaseTagStyle');
  const contentSection = watch('contentSection');
  return (
    <>
      {!!tags.length && (
        <List>
          {tags.map((tag, i) => (
            <TagItem
              key={tag.id || i}
            >
              <Tag
                $contentSection={contentSection}
                style={{
                  '--bg': tag.color,
                }}
                $tagStyle={releaseTagStyle}
              >
                <ChangelogTagLabel>
                  {tag.value}
                </ChangelogTagLabel>
              </Tag>
            </TagItem>
          ))}
        </List>
      )}
    </>
  );
};
