import { DocProductAreaRemoveDocument, DocProductAreaUpdateDocument } from '@cycle-app/graphql-codegen';
import { produce } from 'immer';

import { RefConnection } from '../../types/apollo.types';
import useSafeMutation from '../useSafeMutation';

export const useDocProductAreaUpdate = () => {
  const [mutateUpdate, updateState] = useSafeMutation(DocProductAreaUpdateDocument);
  const [mutateDelete, deleteState] = useSafeMutation(DocProductAreaRemoveDocument);

  const update = ({
    docId, productAreaId, isMulti,
  }: { docId: string; productAreaId: string; isMulti: boolean }) => {
    return mutateUpdate({
      variables: {
        docId,
        productAreaId,
      },
      optimisticResponse: {
        changeDocProductArea: {
          id: docId,
        },
      },
      update: (cache, { data }) => {
        if (data?.changeDocProductArea?.id) {
          cache.modify({
            id: cache.identify(data.changeDocProductArea),
            fields: {
              // @ts-ignore -- TOFIX later
              productAreas: (value: RefConnection, { toReference }) => {
                const node = toReference(productAreaId);
                return node ? produce(value, draft => {
                  const edge = {
                    __typename: 'ProductAreaEdge',
                    node,
                  };
                  if (isMulti) {
                    draft.edges.push(edge);
                  } else {
                    draft.edges.splice(0, draft.edges.length, edge);
                  }
                }) : value;
              },
            },
          });
        }
      },
    });
  };

  const remove = ({
    docId, productAreaId,
  }: { docId: string; productAreaId: string }) => {
    return mutateDelete({
      variables: {
        docId,
        productAreaId,
      },
      optimisticResponse: {
        removeDocProductArea: {
          id: docId,
        },
      },
      update: (cache, { data }) => {
        if (data?.removeDocProductArea?.id) {
          cache.modify({
            id: cache.identify(data.removeDocProductArea),
            fields: {
              // @ts-ignore -- TOFIX later
              productAreas: (value: RefConnection, { readField }) => {
                return produce(value, draft => {
                  draft.edges.splice(draft.edges.findIndex(edge => {
                    return readField<string>('id', edge.node) === productAreaId;
                  }), 1);
                });
              },
            },
          });
        }
      },
    });
  };

  return {
    update,
    remove,
    isUpdateLoading: updateState.loading,
    isDeleteLoading: deleteState.loading,
  };
};