import { useSubscription } from '@apollo/client';
import { MutationProgressDocument } from '@cycle-app/graphql-codegen';

import { onAnalyzeDashboardRefreshProgress } from 'src/components/AnalysesPage/AnalysesPage.utils';
import { onLinearImportProgress } from 'src/components/LinearImportModal/LinearImportModal.utils';

import { useProduct } from '..';

export const useMutationProgressSubscription = () => {
  const { product } = useProduct();
  return useSubscription(MutationProgressDocument, {
    variables: {
      productId: product?.id as string,
    },
    skip: !product?.id,
    onData: ({ data: { data } }) => {
      const result = data?.mutationProgress;
      if (!result) return;
      onLinearImportProgress(result);
      onAnalyzeDashboardRefreshProgress(result, product?.lastCustomerVoiceRefresh);
    },
  });
};
