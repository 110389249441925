import { Feature } from '@cycle-app/graphql-codegen';

import { useProductSelector } from 'src/hooks/api/selectors/useProductSelector';
import { useIsMobile } from 'src/reactives/responsive.reactive';

export const useIsReleasesEnabled = () => {
  const isMobile = useIsMobile();
  const productReleaseEnabled = useProductSelector(p => !!p?.features.find(f => f === Feature.Release));
  return !isMobile && productReleaseEnabled;
};
