import { NotionMinimalDatabaseFragment, NotionMinimalPageFragment } from '@cycle-app/graphql-codegen';
import { SelectLine, SelectLineProps, TextHighlighter, Icon } from '@cycle-app/ui';
import { FC } from 'react';

import { SlotText } from 'src/components/Integrations/IntegrationsCommon.styles';

import { StartSlot } from './NotionSelectLine.styles';

type Props = {
  page?: NotionMinimalPageFragment | NotionMinimalDatabaseFragment;
  isSelected: boolean;
  slotText?: string;
  extraProps: Omit<SelectLineProps, 'isSelected' | 'startSlot'>;
  highlightText?: string;
};

export const NotionSelectLine: FC<React.PropsWithChildren<Props>> = ({
  page, extraProps, isSelected, slotText, highlightText,
}) => (
  <SelectLine
    label={highlightText && page?.title
      ? (<TextHighlighter className="highlight" searchWords={[highlightText]} textToHighlight={page?.title} />) : page?.title}
    isSelected={isSelected}
    startSlot={(
      <StartSlot>
        <Icon name="brand/notion" className="size-4" />
        {!!slotText && <SlotText>{slotText}</SlotText>}
      </StartSlot>
    )}
    {...extraProps}
  />
);
