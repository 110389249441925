export function isDevEnv(): boolean {
  return import.meta.env.DEV;
}

export function isProductionEnv(): boolean {
  return import.meta.env.VITE_ENVIRONMENT === 'alpha';
}

export function isStagingEnv(): boolean {
  return import.meta.env.VITE_ENVIRONMENT === 'dogfood';
}
