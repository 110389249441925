import { BillingPlan } from '@cycle-app/graphql-codegen';

import { PageId } from 'src/constants/routing.constant';
import { useProductSelector } from 'src/hooks/api/selectors/useProductSelector';
import { useProductStatus } from 'src/reactives/product.reactive';


export const useRouteAccess = (pageId: PageId) => {
  const productPlan = useProductSelector(p => p?.plan);
  const { productStatus } = useProductStatus();

  switch (pageId) {
    case PageId.SettingsBilling: return productPlan === BillingPlan.Standard && productStatus !== 'trial';
    default: return true;
  }
};
