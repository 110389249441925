import { Color, HeroSection } from '@cycle-app/graphql-codegen';
import { Input, SelectPanel } from '@cycle-app/ui';
import { EditIcon, ReleaseNoteIcon, DownIcon } from '@cycle-app/ui/icons';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { twJoin } from 'tailwind-merge';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { PortalModal } from 'src/components/PortalModal';
import { Layer } from 'src/types/layers.types';

import { EditableContainer } from '../shared/EditableContainer';
import { useThemeVariant } from '../shared/useThemeVariant';
import { useChangelogBuilderForm } from '../useChangelogBuilderForm';

export function ChangelogBuilderContentHeader() {
  const [editStyleModalOpen, setEditStyleModalOpen] = useState(false);
  const [editContentModalOpen, setEditContentModalOpen] = useState(false);
  const { watch } = useChangelogBuilderForm();

  const heroSectionClasses = useThemeVariant(watch('heroSection'));

  const subtitle = watch('subtitle');
  const shipIndex = subtitle.toLocaleLowerCase().indexOf('ship');
  const hasShip = shipIndex >= 0;

  return (
    <>
      <EditableContainer actions={[
        {
          label: 'Edit text',
          action: () => { setEditContentModalOpen(true); },
          icon: <ReleaseNoteIcon />,
        },
        {
          label: hasShip ? 'Edit style' : 'Edit size',
          action: () => { setEditStyleModalOpen(true); },
          icon: <EditIcon />,
        },
      ]}
      >
        <div className="text-center">
          <h1 className={twJoin(
            'font-bold text-[var(--changelog-headers-color)]',
            heroSectionClasses({
              S: 'mt-8 text-[32px] tablet:text-[40px]/[44px]',
              M: 'mt-10 text-[36px] tablet:text-[48px]/[57.6px]',
              L: 'mt-12 text-[41px] tablet:text-[62px]/[68px]',
              XL: 'mt-12 text-[73px] tablet:text-[80px]/[91px]',
            }),
          )}
          >
            {watch('title')}
          </h1>
          <p className={twJoin(
            'mt-4 font-medium text-[var(--changelog-body-color)]',
            heroSectionClasses({
              S: 'mt-3 text-base',
              M: 'mt-3 tablet:mt-2 text-base tablet:text-lg',
              L: 'mt-4 text-xl',
              XL: 'mt-4 text-xl tablet:text-2xl',
            }),
          )}
          >
            {hasShip ? (
              <>
                <span>{subtitle.substring(0, shipIndex)}</span>
                <img
                  src={`/images/ship-${watch('shipBadgeColor')}.png`} alt="Ship badge"
                  className="mx-1 inline-block h-[2.3em] align-middle drop-shadow"
                  loading="lazy"
                />
                <span>{subtitle.substring(shipIndex).replace('ship', '')}</span>
              </>
            ) : (
              subtitle
            )}
          </p>
        </div>
      </EditableContainer>

      {editStyleModalOpen && <EditStyleModal hide={() => setEditStyleModalOpen(false)} hasShip={hasShip} />}
      {editContentModalOpen && <EditContentModal hide={() => setEditContentModalOpen(false)} />}
    </>
  );
}

const heroOptions = [
  {
    value: HeroSection.S,
    label: 'Small',
  },
  {
    value: HeroSection.M,
    label: 'Medium',
  },
  {
    value: HeroSection.L,
    label: 'Large',
  },
  {
    value: HeroSection.Xl,
    label: 'Extra large',
  },
];

const shipOptions = [
  {
    value: Color.A,
    color: '#2C45E8',
  },
  {
    value: Color.B,
    color: '#7B40F2',
  },
  {
    value: Color.C,
    color: '#3F8FF7',
  },
  {
    value: Color.D,
    color: '#09BB90',
  },
  {
    value: Color.E,
    color: '#F7CF3F',
  },
  {
    value: Color.F,
    color: '#EA9E10',
  },
  {
    value: Color.G,
    color: '#EA3C10',
  },
  {
    value: Color.H,
    color: '#F240AB',
  },
  {
    value: Color.I,
    color: '#DA40F2',
  },
  {
    value: Color.J,
    color: '#404040',
  },
  {
    value: Color.K,
    color: '#F0F0F0',
  },
];

function EditStyleModal({
  hide, hasShip,
}: { hide: VoidFunction; hasShip: boolean }) {
  const {
    getValues, setValue,
  } = useChangelogBuilderForm();
  const {
    handleSubmit, control,
  } = useForm({
    defaultValues: {
      heroSection: getValues('heroSection'),
      shipColor: getValues('shipBadgeColor'),
    },
  });

  const handleFormSubmit = handleSubmit((data) => {
    setValue('heroSection', data.heroSection, { shouldDirty: true });
    if (hasShip) {
      setValue('shipBadgeColor', data.shipColor, { shouldDirty: true });
    }
    hide();
  });

  return (
    <PortalModal hide={hide} className="w-96">
      <form
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          void handleFormSubmit(e);
        }}
      >
        <div className="mb-2 font-medium">Hero section</div>
        <Controller
          control={control}
          name="heroSection"
          render={({ field }) => (
            <ToggleDropdown
              withWrapper={false}
              placement="bottom-start"
              layer={Layer.DropdownModal}
              content={(contentProps) => (
                <SelectPanel
                  hideSearch
                  selectedValue={field.value}
                  options={heroOptions}
                  onOptionChange={(payload) => {
                    field.onChange(payload.value);
                    contentProps.hide();
                  }}
                  style={{ width: `${contentProps.buttonRect?.width}px` }}
                />
              )}
              button={(buttonProps) => (
                <button
                  ref={buttonProps.ref}
                  type="button"
                  // eslint-disable-next-line max-len
                  className="group flex w-full cursor-pointer items-center justify-between rounded-lg border border-primary px-3 py-2 text-left hover:bg-grey-100 dark:hover:bg-grey-800"
                  onClick={buttonProps.onClick}
                >
                  {heroOptions.find((option) => option.value === field.value)?.label ?? 'Select'}
                  <DownIcon className="text-secondary group-aria-expanded:rotate-180" />
                </button>
              )}
            />
          )}
        />


        {hasShip && (
          <div>
            <div className="mb-2 mt-4 font-medium">Ship badge color</div>
            <Controller
              control={control}
              name="shipColor"
              render={({ field }) => (
                <div className="grid grid-cols-11 place-items-center">
                  {shipOptions.map((shipOption, shipOptionIndex) => (
                    <label key={shipOptionIndex}>
                      <div className="cursor-pointer rounded-full border border-transparent p-1 hover:border-grey-300 has-[:checked]:border-grey-500">
                        <input
                          type="radio"
                          value={shipOption.value}
                          name="shipBadgeColor"
                          checked={field.value === shipOption.value}
                          onChange={field.onChange}
                          className="sr-only"
                        />
                        <div className="size-3 rounded-full border border-grey-600/10 shadow-sm" style={{ backgroundColor: shipOption.color }} />
                      </div>
                    </label>
                  ))}
                </div>
              )}
            />
          </div>
        )}

        <div className="mt-8 flex items-center justify-end gap-2">
          <button
            type="button"
            className="btn-secondary"
            onClick={hide}
          >
            Discard
          </button>
          <button
            type="submit"
            className="btn-primary"
          >
            Save changes
          </button>
        </div>
      </form>
    </PortalModal>
  );
}

function EditContentModal({ hide }: { hide: VoidFunction }) {
  const {
    setValue, getValues,
  } = useChangelogBuilderForm();

  const {
    register, handleSubmit,
  } = useForm({
    defaultValues: {
      title: getValues('title'),
      subtitle: getValues('subtitle'),
    },
  });

  const handleFormSubmit = handleSubmit((data) => {
    setValue('title', data.title, { shouldDirty: true });
    setValue('subtitle', data.subtitle, { shouldDirty: true });
    hide();
  });

  return (
    <PortalModal hide={hide} className="w-96">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          void handleFormSubmit(e);
        }}
      >
        <Input
          autoFocus
          label="Title"
          {...register('title')}
        />
        <Input
          className="mt-4"
          label="Subtitle"
          {...register('subtitle')}
        />

        <div className="mt-8 flex justify-end gap-2">
          <button
            type="button"
            className="btn-secondary"
            onClick={hide}
          >
            Discard
          </button>
          <button
            type="submit"
            className="btn-primary"
          >
            Save changes
          </button>
        </div>
      </form>
    </PortalModal>
  );
}
