import { useQuery } from '@apollo/client';
import { FetchGithubRepositoriesDocument, IntegrationType } from '@cycle-app/graphql-codegen';
import { useMemo } from 'react';

import { useProductIntegrations } from 'src/hooks/api/useProductIntegrations';
import { isGithubRepository } from 'src/utils/integrations.utils';

export const useGithubRepositories = () => {
  const { getIntegration } = useProductIntegrations();
  const integration = getIntegration(IntegrationType.Github);
  const {
    data, loading,
  } = useQuery(FetchGithubRepositoriesDocument, {
    skip: !integration?.provider || !integration?.id,
    variables: {
      integrationId: integration?.id as string,
    },
  });

  const githubRepositories = useMemo(
    () => {
      if (
        data?.node?.__typename !== 'Integration' ||
        data?.node.provider?.__typename !== 'Github'
      ) return [];

      return data.node.provider.repositories.filter(isGithubRepository) || [];
    },
    [data],
  );

  return {
    isLoading: loading,
    repositories: githubRepositories,
  };
};
