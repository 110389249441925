import { gql } from '@apollo/client';
import { CreateCompanyDocument, CreateCompanyMutationVariables } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useCallback } from 'react';

import useSafeMutation from 'src/hooks/useSafeMutation';

import { addCustomerInCache } from './useCustomerCreate';

export type CreateCompanyParams = {
  name: string;
  domain?: string;
  customerName?: string;
  customerEmail?: string;
  logo?: File | string | null;
};

export const useCompanyCreate = (productId?: string) => {
  const [createCompanyMutation, { loading }] = useSafeMutation(CreateCompanyDocument);

  const createCompany = useCallback((params: CreateCompanyParams) => {
    const variables: Omit<CreateCompanyMutationVariables, 'productId'> = {
      name: params.name,
      domain: params.domain,
      // eslint-disable-next-line no-nested-ternary
      avatarInput: params.logo instanceof File
        ? { avatar: params.logo }
        : params.logo
          ? { avatarUrl: params.logo }
          : undefined,
      customerName: params.customerName || undefined,
      customerEmail: params.customerEmail || undefined,
    };

    if (!productId) return null;

    return createCompanyMutation({
      variables: {
        productId,
        ...variables,
      },
      errorPolicy: 'all',
      update(cache, { data }) {
        if (!data?.createCompany) return;

        const newCompanyRef = cache.writeFragment({
          data: data.createCompany,
          fragment: gql`
            fragment NewCompany on Fragment {
              id
            }
          `,
        });

        cache.modify({
          id: cache.identify({
            __typename: 'Product',
            id: productId,
          }),
          fields: {
            // @deprecated
            companyCount: (companyCount) => companyCount + 1,
            companies: (companies, { readField }) => {
              const newEdge = {
                __typename: 'CompanyEdge',
                node: newCompanyRef,
              };
              const index = companies.edges.findIndex((edge: any) => (readField('name', edge.node) ?? '') > variables.name);
              return {
                ...companies,
                edges: index === -1 ? [...companies.edges, newEdge] : [
                  ...companies.edges.slice(0, index),
                  newEdge,
                  ...companies.edges.slice(index),
                ],
                count: companies.count + 1,
              };
            },
          },
        });

        for (const newCustomer of nodeToArray(data.createCompany.customers)) {
          addCustomerInCache(cache, newCustomer, cache.identify({
            __typename: 'Product',
            id: productId,
          }));
        }
      },
    });
  }, [createCompanyMutation, productId]);

  return {
    createCompany,
    isLoading: loading,
  };
};
