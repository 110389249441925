import { useSubscription } from '@apollo/client';
import { AiStreamDocument, AiStreamSubscription, AiStreamSubscriptionVariables } from '@cycle-app/graphql-codegen';

import { setCustomerDashboard, getCustomerDashboards } from 'src/reactives';

import { useProduct } from './useProduct';

export const useCustomerDashboardAiSubscription = (dashboardId: string) => {
  const { product } = useProduct('cache-only');
  return useSubscription<AiStreamSubscription, AiStreamSubscriptionVariables>(AiStreamDocument, {
    variables: {
      productId: product?.id as string,
    },
    skip: !product?.id || false,
    onData: ({ data: { data } }) => {
      if (!data || !product) return;
      const mutationId = data.aiStream?.mutationId;
      const dashboard = Object.entries(getCustomerDashboards()).find(state => {
        return state[1].mutationId === mutationId;
      });
      // [dashboardId, StateValues]
      if (dashboard?.[0] === dashboardId) {
        setCustomerDashboard(dashboardId, {
          aiSuggestion: `${dashboard[1].aiSuggestion}${data.aiStream?.stream}`,
          isAiGenerating: data.aiStream?.progress !== 100,
        });
      }
    },
  });
};
