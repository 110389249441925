import { useQuery } from '@apollo/client';
import { addFakeAttributes } from '@cycle-app/front/tests/fakes/customerAttributes';
import { CompanyCustomersDocument, CompanyDocument } from '@cycle-app/graphql-codegen/generated';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo } from 'react';

import { extract } from 'src/types/graphql.types';

export const useCompany = (companyId?: string) => {
  const {
    data, loading: isLoading,
  } = useQuery(CompanyDocument, {
    skip: !companyId,
    variables: {
      id: companyId as string,
    },
  });

  const company = useMemo(() => {
    const node = extract('Company', data?.node);
    return addFakeAttributes ? addFakeAttributes(node) : node;
  }, [data]);

  return {
    company,
    customersCount: company?.countCustomers ?? 0,
    isLoading,
  };
};

export const useCompanyCustomers = (companyId?: string) => {
  const {
    data, fetchMore, loading: isLoading,
  } = useQuery(CompanyCustomersDocument, {
    skip: !companyId,
    variables: {
      id: companyId as string,
      size: 10,
      cursor: '',
    },
  });

  const company = extract('Company', data?.node);
  const customers = useMemo(() => nodeToArray(company?.customers), [company]);
  const pageInfo = company?.customers?.pageInfo;

  const loadMoreCustomers = async () => {
    if (!pageInfo?.hasNextPage) return;
    await fetchMore({
      variables: {
        cursor: pageInfo.endCursor,
      },
    });
  };

  return {
    customers,
    loadMoreCustomers,
    isLoading,
    pageInfo,
  };
};
