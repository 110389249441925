import { Role, ThemeType } from '@cycle-app/graphql-codegen';
import {
  AddIcon,
  WheelIcon,
  UserIcon,
  HomeIcon,
  ReleaseIcon,
  BulbIcon,
  RoadmapIcon,
  MoonIcon,
  SunIcon,
} from '@cycle-app/ui/icons';
import { MouseEventHandler } from 'react';

import { StyledCreateDocIconContainer } from 'src/components/CommandBar/CommandBarModal.styles';
import { StyledInboxIcon } from 'src/components/CommandBar/CommandK/CommandAction/CommandAction.styles';
import { PageId } from 'src/constants/routing.constant';
import { shortcuts } from 'src/constants/shortcuts.constants';
import { useIsChangelogOnly } from 'src/contexts/workspaceContext';
import { useIsRoadmapsEnabled } from 'src/hooks';
import { useMe } from 'src/hooks/api/useMe';
import { useIsReleasesEnabled } from 'src/hooks/releases/useIsReleasesEnabled';
import { useGlobalShortcutCallbacks } from 'src/hooks/shortcuts/useGlobalShortcutListener';
import { usePageId } from 'src/hooks/usePageId';
import { useGetCommandbar } from 'src/reactives/commandbar.reactive';
import { setCreateDoc } from 'src/reactives/createDoc.reactive';
import { useGetPermission } from 'src/reactives/permission.reactive';
import { getActiveProductTour } from 'src/reactives/productTours.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { openSettingsAccount } from 'src/reactives/settingsModal.reactive';
import { CommandActionCategory, CommandActionType, CommandSection } from 'src/types/commandbar.types';
import { Shortcut, ShortcutBoard } from 'src/types/shortcuts.types';
import { execCallbackAndHide } from 'src/utils/commandbar.util';

import { useGetThemeConfig } from '../../reactives/theme.reactive';
import { useFeedbackDocType } from '../api/useProductDoctypes';
import { closeCommandBar } from '../modals/useCommandBarModal';
import { useOnboardingPreviewBoard } from '../useOnboardingPreviewBoard';
import { useUrl } from '../useUrl';
import { useAddonsResult } from './useAddonsResult';
import { useAdminActions, useCreateWorkspaceResult } from './useAdminActions';
import { useBoardContextActions } from './useBoardContextActions';
import { useBoardsResult, CATEGORY_ID as VIEW_CATEGORY } from './useBoardsResult';
import { CATEGORY_ID as COMPANY_CATEGORY } from './useCompaniesResult';
import { CATEGORY_ID as PEOPLE_CATEGORY } from './useCustomersResult';
import { useDocSearch, docCategories, DocCategory } from './useDocSearch';
import { useSettingsResult } from './useSettingsResult';
import { useWorkspacesSearch } from './useWorkspacesSearch';

interface UseCommandResultParams {
  search: string;
  searchDebounced: string;
  docsResult: CommandActionCategory[];
  customersResult: CommandActionCategory[];
  companiesResult: CommandActionCategory[];
}

export const useCommandResult = ({
  search, searchDebounced, docsResult, customersResult, companiesResult,
}: UseCommandResultParams) => {
  const pageId = usePageId();
  const { section } = useGetCommandbar();

  const defaultDocSearch = useDocSearch({ skip: !!searchDebounced });
  const defaultResult = useDefaultResult(searchDebounced ? docsResult : defaultDocSearch.result);
  const settingsResult = useSettingsResult();
  const addonsResult = useAddonsResult();
  const boardsResult = useBoardsResult(section === 'go-to-board');
  const workspacesSearch = useWorkspacesSearch(search, section === 'workspace');
  const createWorkspaceResult = useCreateWorkspaceResult();
  const results: Record<CommandSection, Array<CommandActionCategory>> = {
    'go-to-board': boardsResult,
    'go-to-settings': settingsResult,
    'create-workspace': createWorkspaceResult,
    'toggle-addon': addonsResult,
    customers: [...customersResult, ...companiesResult],
    workspace: workspacesSearch.result,
  };
  const searchedResult = [...defaultResult, ...boardsResult, ...customersResult, ...companiesResult];
  const result = (search.trim() || searchDebounced.trim()) ? searchedResult : defaultResult;

  const filteredResult = section === 'workspace'
    ? results[section]
    : filterResult(section ? results[section] : result, search, pageId);

  const resultByCategory = groupResult(filteredResult, search, searchDebounced, section);
  return {
    resultByCategory,
    entries: resultByCategory.map(cat => cat.actions).flat(),
    loading: workspacesSearch.loading,
    hasNextPage: workspacesSearch.hasNextPage,
    fetchNextPage: workspacesSearch.fetchNextPage,
  };
};

function groupResult(filteredResult: Array<CommandActionCategory>, search: string, searchDebounced: string, section: CommandSection | null) {
  return ((search.length || searchDebounced.length) && !section) // default groups when the user did not selected any section.
    ? [
      {
        id: 'commands',
        label: 'Commands',
        actions: filteredResult
          .filter(res => ![...docCategories, VIEW_CATEGORY, PEOPLE_CATEGORY, COMPANY_CATEGORY].includes(res.id))
          .map(res => res.actions).flat()
          .reduce<CommandActionType[]>((acc, current) => (
          acc.find(action => action.id === current.id || action.label === current.label) ? acc : ([...acc, current])), []),
      },
      {
        id: VIEW_CATEGORY,
        label: 'Views',
        actions: filteredResult.filter(res => res.id === VIEW_CATEGORY).map(res => res.actions).flat(),
      },
      ...docCategories.map(id => {
        const category = filteredResult.find(res => res.id === id);
        return {
          id,
          label: category?.label,
          actions: category?.actions ?? [],
        };
      }),
      {
        id: PEOPLE_CATEGORY,
        label: 'People',
        actions: filteredResult.filter(res => res.id === PEOPLE_CATEGORY).map(res => res.actions).flat(),
      },
      {
        id: COMPANY_CATEGORY,
        label: 'Companies',
        actions: filteredResult.filter(res => res.id === COMPANY_CATEGORY).map(res => res.actions).flat(),
      },
    ].filter(res => res.actions.length)
    : filteredResult;
}

const filterResult = (result: Array<CommandActionCategory>, search: string, currentPageId: PageId) => {
  function filterAction(action: CommandActionType) {
    return (
      // Has no defined callback
      !(import.meta.env.PROD && !action.onSelect) &&

      // The current page doesn't match with the scope specified in `onlyOn: Array<PageId>`
      !(action.onlyOn && !action.onlyOn.includes(currentPageId)) &&

      (
        // Already filtered (eg. from a search query)
        action.filtered ||
        // Should match with the search term
        !!action.label?.toLowerCase().includes(search.toLowerCase())
      )
    );
  }

  function filterCategory(category: CommandActionCategory) {
    return category.actions.filter(filterAction).length > 0;
  }

  return result
    .filter(filterCategory)
    .map(cat => ({
      ...cat,
      actions: cat.actions.filter(filterAction),
    }))
    .map((cat, _, filteredResult) => (
      cat.mapCategory ? cat.mapCategory(cat, {
        filteredResult,
        search,
      }) : cat
    ));
};

const useDefaultResult = (docSearch: CommandActionCategory[]): Array<CommandActionCategory> => {
  const isChangelogOnly = useIsChangelogOnly();
  const getUrl = useUrl();
  const { me } = useMe();
  const { boardId } = useGetCommandbar();
  const isMobile = useIsMobile();
  const boardInbox = useOnboardingPreviewBoard({ name: 'inbox' });
  const adminActions = useAdminActions();
  const boardContextActions = useBoardContextActions();
  const isReleasesEnabled = useIsReleasesEnabled();
  const isRoadmapsEnabled = useIsRoadmapsEnabled();

  const globalCallbacks = useGlobalShortcutCallbacks();

  const { canReadSettings } = useGetPermission();
  const feedback = useFeedbackDocType();

  const isAdmin = import.meta.env.DEV || me.role === Role.SuperAdmin;
  const isDark = useGetThemeConfig().colorTheme === ThemeType.Nightfall;

  const docsDefaultResult = (id: DocCategory): CommandActionCategory => {
    const result = docSearch.find(s => s.id === id);
    return {
      id,
      label: result?.label,
      actions: result?.actions ?? [],
      mapCategory: (cat, { search: searchText }) => (
        !searchText.length
          ? {
            ...cat,
            actions: cat.actions.slice(0, 3),
          }
          : cat),
    };
  };

  const defaultResult: Array<CommandActionCategory> = [
    {
      id: 'general-actions',
      actions: [
        {
          id: 'create-doc',
          label: `New ${feedback?.name || 'Feedback'}`,
          icon: (
            <StyledCreateDocIconContainer>
              <AddIcon />
            </StyledCreateDocIconContainer>
          ),
          shortcut: shortcuts[ShortcutBoard.CreateDoc],
          onSelect: execCallbackAndHide(() => setCreateDoc({
            modalVisible: true,
            doctypeId: feedback?.id ?? null,
          })),
          onlyOn: [PageId.Board, PageId.Main],
        },
        {
          id: 'theme',
          label: isDark ? 'Switch to light theme' : 'Switch to dark theme',
          icon: isDark ? <SunIcon /> : <MoonIcon />,
          shortcut: shortcuts[Shortcut.SwitchTheme],
          onSelect: globalCallbacks[Shortcut.SwitchTheme],
        },
      ],
    },
    docsDefaultResult('docs'),
    docsDefaultResult('feedback'),
    docsDefaultResult('insights'),
    docsDefaultResult('roadmaps'),
    {
      id: 'navigation',
      label: 'Navigation',
      actions: [
        {
          id: 'navigation-home',
          label: 'Go to Home',
          icon: <HomeIcon />,
          shortcut: shortcuts[Shortcut.GoToHome],
          onSelect: execCallbackAndHide(globalCallbacks[Shortcut.GoToHome]),
          linkTo: getUrl(PageId.Main),
          onClick: onClickLink,
        },
        ...!isChangelogOnly && boardInbox ? [
          {
            id: 'inbox',
            label: 'Go to Feedback',
            icon: <StyledInboxIcon />,
            shortcut: shortcuts[Shortcut.GoToFeedback],
            onSelect: execCallbackAndHide(globalCallbacks[Shortcut.GoToFeedback]),
            onClick: onClickLink,
            linkTo: getUrl(PageId.Inbox),
          }] : [],
        ...!isChangelogOnly ? [
          {
            id: 'insights',
            label: 'Go to Insights',
            icon: <BulbIcon />,
            shortcut: shortcuts[Shortcut.GoToInsights],
            onSelect: execCallbackAndHide(globalCallbacks[Shortcut.GoToInsights]),
            onClick: onClickLink,
            linkTo: getUrl(PageId.Insight),
          }] : [],
        ...!isChangelogOnly && isRoadmapsEnabled ? [
          {
            id: 'roadmap',
            label: 'Go to Roadmaps',
            icon: <RoadmapIcon />,
            shortcut: shortcuts[Shortcut.GoToRoadmap],
            onSelect: execCallbackAndHide(globalCallbacks[Shortcut.GoToRoadmap]),
            onClick: onClickLink,
            linkTo: getUrl(PageId.Roadmap),
          }] : [],
        ...isReleasesEnabled ? [
          {
            id: 'releases',
            label: 'Go to Releases',
            icon: <ReleaseIcon />,
            shortcut: shortcuts[Shortcut.GoToReleases],
            onSelect: execCallbackAndHide(globalCallbacks[Shortcut.GoToReleases]),
            onClick: onClickLink,
            linkTo: getUrl(PageId.Release),
          }] : [],
        ...!isMobile ? [
          ...canReadSettings ? [{
            id: 'settings',
            label: 'Go to Settings',
            icon: <WheelIcon />,
            shortcut: shortcuts[Shortcut.GoToSettings],
            onSelect: globalCallbacks[Shortcut.GoToSettings],
          }] : [],
          {
            id: 'my-account',
            label: 'Go to My account',
            icon: <UserIcon />,
            shortcut: shortcuts[Shortcut.GoToMyAccount],
            onSelect: execCallbackAndHide(openSettingsAccount),
          },
        ] : [],
      ],
    }];

  return [
    ...defaultResult,
    ...(isAdmin ? [adminActions] : []),
    // ...(hoverDocId ? [docContextActions] : []),
    ...(boardId && boardContextActions.actions.length ? [boardContextActions] : []),
  ];
};

const onClickLink: MouseEventHandler<HTMLAnchorElement> = (e) => {
  if (getActiveProductTour()) e.preventDefault();
  if (!e.metaKey) closeCommandBar();
};
