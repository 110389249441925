import { useQuery } from '@apollo/client';
import { ReleaseNotesDocument } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useCallback, useMemo, useState } from 'react';

import { RELEASE_NOTES_INITIAL_PAGINATION_SIZE, RELEASE_NOTES_NEXT_PAGINATION_SIZE } from 'src/constants/releases.constants';
import { extract } from 'src/types/graphql.types';

export const useReleaseNotes = (releaseId: string, isOther: boolean) => {
  const query = useQuery(ReleaseNotesDocument, {
    fetchPolicy: 'cache-first',
    variables: {
      releaseId,
      isOther,
      cursor: '',
      size: RELEASE_NOTES_INITIAL_PAGINATION_SIZE,
    },
  });

  const data = extract('Release', query.data?.node)?.releaseNotes;
  const hasNextPage = data?.pageInfo?.hasNextPage ?? false;
  const endCursor = data?.pageInfo?.endCursor ?? '';

  const list = useMemo(() => nodeToArray(data), [data]);

  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const loadMore = useCallback(async () => {
    if (!hasNextPage || !endCursor) return;
    setIsLoadingMore(true);
    await query.fetchMore({
      variables: {
        cursor: endCursor,
        size: RELEASE_NOTES_NEXT_PAGINATION_SIZE,
      },
    });
    setIsLoadingMore(false);
  }, [endCursor, hasNextPage, query]);

  const refetch = useCallback(async (size: number) => {
    const result = await query.refetch({
      releaseId,
      isOther,
      cursor: '',
      size,
    });
    return nodeToArray(extract('Release', result.data?.node)?.releaseNotes);
  }, [isOther, query, releaseId]);

  return {
    isLoading: query.loading && !query.data,
    isLoadingMore,
    list,
    hasNextPage,
    endCursor,
    loadMore,
    refetch,
  };
};

export type UseReleaseNotesReturn = ReturnType<typeof useReleaseNotes>;
