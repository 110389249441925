import {
  AddBoardConfigSwimlanebyDoctypeDocument,
  RemoveBoardConfigSwimlaneDocument,
  MoveSwimlaneDocument,
  ListPositionInput,
  DocQueryFragment,
} from '@cycle-app/graphql-codegen';
import { produce } from 'immer';
import { useCallback } from 'react';

import { Events } from 'src/constants/analytics.constants';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { trackAnalytics } from 'src/utils/analytics/analytics';

interface MoveSwimlaneParams {
  swimlaneId: string;
  position: ListPositionInput;
  // new nodes.
  itemsUpdated: (string | undefined)[];
}

export default function useManageSwimlanes(boardConfigId: string) {
  const [addSwimlaneDoctypeMutation, { loading: loadingAddSwimlane }] = useSafeMutation(AddBoardConfigSwimlanebyDoctypeDocument, {
    onCompleted: () => trackAnalytics(Events.SwimlaneAdded),
  });
  const [removeSwimlaneMutation, { loading: loadingRemoveSwimlane }] = useSafeMutation(RemoveBoardConfigSwimlaneDocument);

  const addSwimlaneDoctype = useCallback((doctypeId: string) => addSwimlaneDoctypeMutation({
    variables: {
      boardConfigId,
      doctypeId,
    },
  }), [addSwimlaneDoctypeMutation, boardConfigId]);

  const removeSwimlane = useCallback(() => removeSwimlaneMutation({
    variables: { boardConfigId },
  }), [removeSwimlaneMutation, boardConfigId]);

  const [moveSwimlaneMutation, { loading: loadingMoveSwimlane }] = useSafeMutation(MoveSwimlaneDocument);

  const moveSwimlane = useCallback(async ({
    swimlaneId,
    position,
    itemsUpdated,
  }: MoveSwimlaneParams) => {
    await moveSwimlaneMutation({
      variables: {
        swimlaneId,
        boardConfigId,
        position,
      },
      update: (cache, { data }) => {
        if (data?.moveSwimlane?.id) {
          cache.modify<DocQueryFragment>({
            id: boardConfigId,
            fields: {
              docQuery: (docQuery, { toReference }) => {
                if (!('swimlanes' in docQuery)) return docQuery;
                return produce(docQuery, docQueryDraft => {
                  let endCursor = '';
                  const lastNodeId = itemsUpdated[itemsUpdated.length - 1];
                  const hasCursor = !!docQueryDraft.swimlanes.pageInfo.endCursor;
                  const swimlaneUuid = lastNodeId ? window.atob(lastNodeId)?.split('_')[1] : null;
                  if (lastNodeId && hasCursor && swimlaneUuid) {
                    endCursor = window.btoa(swimlaneUuid);
                  }
                  if (endCursor) {
                    // eslint-disable-next-line no-param-reassign
                    docQueryDraft.swimlanes.pageInfo.endCursor = endCursor;
                  }
                  docQueryDraft.swimlanes.edges.forEach((edge, edgeIndex, edges) => {
                    const nodeId = itemsUpdated[edgeIndex];
                    if (nodeId) {
                      // @ts-ignore edge.node is a ref, but as we used DocQueryFragment['docQuery'], we need to ignore.
                      // eslint-disable-next-line no-param-reassign
                      edge.node = toReference(nodeId);
                      // @ts-ignore edge.node is a ref
                      // eslint-disable-next-line no-param-reassign
                      edge.cursor =
                          endCursor && edgeIndex === edges.length - 1
                            ? endCursor
                            : undefined;
                    }
                  });
                });
              },
            },
          });
        }
      },
    });
  }, [moveSwimlaneMutation, boardConfigId]);

  return {
    addSwimlaneDoctype,
    removeSwimlane,
    moveSwimlane,
    loadingAddSwimlane,
    loadingRemoveSwimlane,
    loadingMoveSwimlane,
  };
}
