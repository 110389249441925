import { ContentSection, ReleaseTagStyle } from '@cycle-app/graphql-codegen';
import styled from 'styled-components';

import { ChangelogTagStyle } from '../ChangelogTag/ChangelogTag.styles';

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 24px;
`;

interface TagProps {
  $tagStyle: ReleaseTagStyle;
  $contentSection: ContentSection;
}

export const TagItem = styled.li`
  overflow: hidden;
  max-width: 100%;
`;

export const Tag = styled.div<TagProps>`
  ${ChangelogTagStyle}

  ${p => p.$contentSection === ContentSection.Discreet && `
    font-size: ${p.$tagStyle === ReleaseTagStyle.Background ? '12px' : '14px'};
  `}
  ${p => p.$contentSection === ContentSection.Uniform && `
    font-size: ${p.$tagStyle === ReleaseTagStyle.Background ? '14px' : '16px'};
  `}
  ${p => p.$contentSection === ContentSection.Classic && `
    font-size: ${p.$tagStyle === ReleaseTagStyle.Background ? '14px' : '16px'};
  `}
  ${p => p.$contentSection === ContentSection.Sober && `
    font-size: ${p.$tagStyle === ReleaseTagStyle.Background ? '16px' : '18px'};
  `}
  ${p => p.$contentSection === ContentSection.ShowOff && `
    font-size: ${p.$tagStyle === ReleaseTagStyle.Background ? '18px' : '20px'};
  `}
  ${p => p.$contentSection === ContentSection.Unique && `
    font-size: ${p.$tagStyle === ReleaseTagStyle.Background ? '12px' : '14px'};
  `}
`;
