import { QueryHookOptions, useQuery } from '@apollo/client';
import { ProductCompanyBaseListDocument, ProductCompanyBaseListQuery, ProductCompanyBaseListQueryVariables } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useEffect } from 'react';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import client from 'src/services/apollo/client';
import { extract } from 'src/types/graphql.types';
import { defaultCompaniesPagination } from 'src/utils/pagination.util';

export const useCompanyBaseList = (
  variables?: Partial<Omit<ProductCompanyBaseListQueryVariables, 'id'>>,
  options?: Pick<QueryHookOptions<ProductCompanyBaseListQuery>, 'fetchPolicy'>,
) => {
  const productId = useWorkspaceContext(ctx => ctx.productId);

  const query = useQuery(ProductCompanyBaseListDocument, {
    ...options,
    variables: {
      id: productId,
      ...defaultCompaniesPagination,
      ...variables,
    },
  });

  return {
    ...query,
    companies: nodeToArray(extract('Product', query.data?.node)?.companies),
  };
};

export const usePrefetchCompanyBaseList = (
  variables?: Partial<Pick<ProductCompanyBaseListQueryVariables, 'size'>>,
) => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    client.query({
      query: ProductCompanyBaseListDocument,
      variables: {
        id: productId,
        cursor: '',
        size: variables?.size ?? defaultCompaniesPagination.size,
      },
    });
  }, [productId, variables?.size]);
};