import { Emoji } from '@cycle-app/ui';
import { nodeToArray } from '@cycle-app/utilities';

import { PageId } from 'src/constants/routing.constant';
import { useIsChangelogOnly } from 'src/contexts/workspaceContext';
import { useNavigate } from 'src/hooks';
import { useBoardSections } from 'src/hooks/api/useBoardSections';
import { useUrl } from 'src/hooks/useUrl';
import { CommandActionCategory, CommandActionType } from 'src/types/commandbar.types';
import { isViewAll, isViewMyInbox } from 'src/utils/inbox.util';
import { getBoardSlug } from 'src/utils/slug.util';
import { getSectionName } from 'src/utils/viewSection.utils';

import { closeCommandBar } from '../modals/useCommandBarModal';

export const CATEGORY_ID = 'boards';

export const useBoardsResult = (isBoardSection: boolean): Array<CommandActionCategory> => {
  const isChangelogOnly = useIsChangelogOnly();
  const { navigate } = useNavigate();
  const getUrl = useUrl();

  const {
    sections, inbox, insights, roadmaps,
  } = useBoardSections();

  const myInboxView = inbox.find(isViewMyInbox);
  const allInboxView = inbox.find(isViewAll);
  const inboxViews = inbox.filter(view => ![myInboxView?.id, allInboxView?.id].includes(view.id));
  const inboxActions: CommandActionType[] = [
    ...myInboxView ? [myInboxView] : [],
    ...allInboxView ? [allInboxView] : [],
    ...inboxViews,
  ].map(view => ({
    id: view.id,
    label: view.name,
    linkTo: getUrl(PageId.InboxView, { boardSlug: getBoardSlug(view) }),
    onClick: (e) => {
      if (!e.metaKey) closeCommandBar();
    },
    onSelect: () => {
      closeCommandBar();
      navigate(PageId.InboxView, { boardSlug: getBoardSlug(view) });
    },
  }));

  const insightsActions: CommandActionType[] = insights.map(view => ({
    id: view.id,
    label: view.name,
    linkTo: getUrl(PageId.InsightView, { boardSlug: getBoardSlug(view) }),
    onClick: (e) => {
      if (!e.metaKey) closeCommandBar();
    },
    onSelect: () => {
      closeCommandBar();
      navigate(PageId.InsightView, { boardSlug: getBoardSlug(view) });
    },
  }));

  const roadmapsActions: CommandActionType[] = roadmaps.map(view => ({
    id: view.id,
    label: view.name,
    linkTo: getUrl(PageId.RoadmapView, { boardSlug: getBoardSlug(view) }),
    onClick: (e) => {
      if (!e.metaKey) closeCommandBar();
    },
    onSelect: () => {
      closeCommandBar();
      navigate(PageId.RoadmapView, { boardSlug: getBoardSlug(view) });
    },
  }));

  if (isChangelogOnly) return [];

  return isBoardSection
    ? [
      {
        id: 'views-feedback',
        label: 'Feedback',
        actions: inboxActions,
      },
      {
        id: 'views-insights',
        label: 'Insights',
        actions: insightsActions,
      },
      {
        id: 'views-roadmaps',
        label: 'Roadmaps',
        actions: roadmapsActions,
      },
      ...sections.map(section => ({
        id: section.id,
        label: getSectionName(section, sections.length),
        actions: nodeToArray(section.boards).map(board => ({
          id: board.id,
          label: board.name,
          icon: board.emoji ? <Emoji emoji={board.emoji} /> : null,
          linkTo: getUrl(PageId.Board, { boardSlug: board.id }),
          onClick: (e) => {
            if (!e.metaKey) closeCommandBar();
          },
          onSelect: () => {
            closeCommandBar();
            navigate(PageId.Board, { boardSlug: board.id });
          },
        })) as CommandActionType[],
      })),
    ]
    : [{
      id: CATEGORY_ID,
      label: 'Views',
      actions: [
        ...inboxActions,
        ...insightsActions,
        ...roadmapsActions,
        ...sections.flatMap(section => nodeToArray(section.boards))
          .map(board => ({
            id: board.id,
            label: board.name,
            icon: board.emoji ? <Emoji emoji={board.emoji} /> : null,
            linkTo: getUrl(PageId.Board, { boardSlug: board.id }),
            onClick: (e) => {
              if (!e.metaKey) closeCommandBar();
            },
            onSelect: () => {
              closeCommandBar();
              navigate(PageId.Board, { boardSlug: board.id });
            },
          })) as CommandActionType[],
      ],
    }];
};
