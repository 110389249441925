import { DocBaseFragment } from '@cycle-app/graphql-codegen';
import { AddIcon, DuplicateIcon } from '@cycle-app/ui/icons';
import { useEffect, useRef, useState } from 'react';

import { InsightCreate } from 'src/components/InsightCreate';
import { useDocInsights } from 'src/hooks';
import { insightName } from 'src/utils/doc.util';

import { Container, ListContainer } from './InsightChildrenList.styles';
import { InsightsList } from './InsightsList';
import { Buttons, ActionButtons, AddButton, Action, AddInsightButton } from './InsightsList.styles';
import { copyQuotesToClipboard } from './InsightsList.utils';

type FeedbackInsightListProps = {
  doc: DocBaseFragment;
};

export const FeedbackInsightList = ({ doc }: FeedbackInsightListProps) => {
  const {
    insights, isLoading,
  } = useDocInsights(doc.id);

  // Add a bottom border to the list if scrollable
  const listRef = useRef<HTMLDivElement>(null);
  const [isScrollable, setIsScrollable] = useState(false);
  useEffect(() => {
    const element = listRef.current?.children[0];
    const checkScrollable = () => {
      if (!(element instanceof HTMLDivElement)) return;
      setIsScrollable(element.scrollHeight > element.clientHeight);
    };
    checkScrollable();
    window.addEventListener('resize', checkScrollable);
    return () => {
      window.removeEventListener('resize', checkScrollable);
    };
  }, [insights]);

  return (
    <Container>
      <ListContainer ref={listRef} $withBorder={isScrollable}>
        <InsightsList
          hideSource
          hideAssignee
          hideCustomer
          hideCopy
          insights={insights}
          isLoading={isLoading}
          docTargetsAiCount={doc.docTargetsAiCount}
          modal={insights.length > 0 ? (
            <>
              <ActionButtons>
                <Action
                  size="L"
                  tooltip="Copy all quotes"
                  tooltipPlacement="top"
                  onClick={() => copyQuotesToClipboard(insights)}
                >
                  <DuplicateIcon />
                </Action>
              </ActionButtons>

              <InsightCreate
                feedbackDoc={doc}
                button={props => (
                  <AddButton
                    onClick={props.onClick}
                    forceFocus={props.isFocus}
                    disabled={props.isDisabled}
                  >
                    <AddIcon size={12} />
                    {`Add ${insightName()}`}
                  </AddButton>
                )}
              />
            </>
          ) : (
            <Buttons>
              <InsightCreate
                feedbackDoc={doc}
                button={props => (
                  <AddInsightButton
                    onClick={props.onClick}
                    forceFocus={props.isFocus}
                    $isFocus={props.isFocus}
                    $isFullWidth
                    disabled={props.isDisabled}
                  >
                    <AddIcon size={12} />
                    {`Add ${insightName()}`}
                  </AddInsightButton>
                )}
              />
            </Buttons>
          )}
        />
      </ListContainer>
    </Container>
  );
};
