import { Tooltip } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import { plural } from '@cycle-app/utilities';

import { mapAddOnsData } from 'src/constants/addOn.constants';
import { useProductAddOns } from 'src/hooks';
import { useProductAddOnRequest } from 'src/hooks/useProductAddOnRequest';

import { Layer } from '../../types/layers.types';
import { AddOnIcon } from '../AddOnIcon';
import { ToggleDropdown } from '../DropdownLayer';
import { Title, StyledSelectPanel } from './UpgradeBoxAddons.styles';

export const UpgradeBoxAddons = ({ isYC }: { isYC: boolean }) => {
  const { request } = useProductAddOnRequest();
  const addOns = useProductAddOns();
  const addOnItemsOn = addOns.filter(a => a.isEnabled);
  const addOnItemsOff = addOns.filter(a => !a.isEnabled);
  const addOnItemsDisplay = addOnItemsOn.slice(0, 4);
  return (
    <div>
      <Title>
        {
        // eslint-disable-next-line no-nested-ternary
        isYC ? 'Included'
          : addOnItemsOn.length
            ? plural(`${addOnItemsOn.length} add-on`, addOnItemsOn.length)
            : 'No add-ons'
        }
      </Title>
      <ToggleDropdown
        layer={Layer.DropdownModal}
        content={({ hide }) => (
          <StyledSelectPanel
            hideSearch
            onOptionChange={async (o) => {
              hide();
              await request(o.value as typeof addOns[number]['name']);
            }}
            options={addOnItemsOff.map(addon => ({
              value: addon.name,
              label: mapAddOnsData[addon.name].name,
              icon: <AddOnIcon name={addon.name} />,
              style: {
                height: 'auto',
                padding: '2px 16px',
                marginTop: '8px',
              },
            }))}
          />
        )}
        placement="bottom"
        width={275}
        style={{
          marginTop: '12px',
        }}
        button={({ onClick }) => (
          <div
            className="flex cursor-pointer items-center justify-center gap-2 text-body font-medium text-disabled"
            onClick={onClick}
          >
            <div className="flex items-center justify-center gap-2">
              {!addOnItemsDisplay.length && (
                <AddIcon />
              )}
              {!!addOnItemsDisplay.length && addOnItemsDisplay.map(addon => (
                <Tooltip
                  key={addon.name}
                  placement="top"
                  content={mapAddOnsData[addon.name].name}
                  style={{
                    display: 'flex',
                  }}
                >
                  <AddOnIcon name={addon.name} />
                </Tooltip>
              ))}
            </div>
            {addOnItemsOn.length > addOnItemsDisplay.length && `+${addOnItemsOn.length - addOnItemsDisplay.length}`}
          </div>
        )}
      />
    </div>
  );
};
