import { AskQuestionDocument } from '@cycle-app/graphql-codegen';
import { Skeleton } from '@cycle-app/ui';
import { CloseIcon, AiIcon, InfoIconOutline, ExpandIcon } from '@cycle-app/ui/icons';
import { format, parseISO } from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';
import { useLayoutEffect, useRef, useState } from 'react';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useSafeMutation } from 'src/hooks';
import { useCompanyBaseList } from 'src/hooks/api/queries/customers/useCompanyBaseList';
import {
  addAskItems, closeAskPanel, setAskPanel, toggleIsAskExpanded, updateAskAnswer, useGetAskAnswers, useGetAskItems, useGetAskPanel, useIsAskExpanded,
} from 'src/reactives/ask.reactive';

import { AnswerDocs } from './AnswerDocs';
import { AnswerItem } from './AnswerItem';
import {
  Container, Content, Scrollable,
  Header, Title, Actions, Action,
  Footer, QuestionInput,
  Question,
  TipMessage,
  QuestionDate,
  AskExampleList,
  AskExample,
} from './Ask.styles';

export const AskPanel = () => {
  const {
    question: defaultValue, screen,
  } = useGetAskPanel();
  const productId = useWorkspaceContext(ctx => ctx.productId);

  const {
    companies,
    loading: isCompaniesLoading,
  } = useCompanyBaseList({
    size: 3,
  }, {
    fetchPolicy: 'cache-first',
  });

  const { items } = useGetAskItems();
  const { answers } = useGetAskAnswers();
  const scrollableRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [withBorderBottom, setWithBorderBottom] = useState(false);
  const [withBorderTop, setWithBorderTop] = useState(false);
  const [askQuestion] = useSafeMutation(AskQuestionDocument);

  useLayoutEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleAsk = async (question: string) => {
    setAskPanel({ screen: 'answers' });

    scrollableRef.current?.scrollTo(0, 0);
    setAnswerId(null);

    const anwserId = crypto.randomUUID();

    addAskItems([
      {
        id: anwserId,
        type: 'answer',
      },
      {
        id: crypto.randomUUID(),
        type: 'question',
        content: question,
        createdDate: new Date().toISOString(),
      },
    ]);

    updateAskAnswer(anwserId, {
      id: anwserId,
      status: 'pending',
      content: null,
      uuid: null,
    });

    const result = await askQuestion({
      variables: {
        productId,
        question,
      },
    });

    const uuid = result?.data?.ask;
    if (typeof uuid !== 'string') return;

    updateAskAnswer(anwserId, {
      id: anwserId,
      status: 'loading',
      uuid,
      content: null,
    });
  };

  const [answerId, setAnswerId] = useState<string | null>(null);
  const isExampleScreen = screen === 'examples';
  const hasAnswers = items.some(item => item.type === 'answer');

  const company1 = companies[1]?.name;
  const company2 = companies[2]?.name;
  const company3 = companies[3]?.name;
  const examples = [
    {
      id: 'a',
      isLoading: false,
      content: 'What are customers saying about performance this month?',
    },
    {
      id: 'b',
      isLoading: isCompaniesLoading,
      content: `What feedback do we have from customers ${(company1 || 'Figma')} and ${company2 || 'Canva'}?`,
    },
    {
      id: 'c',
      isLoading: false,
      content: 'Can you please summarize the feedback we\'ve received over the past two weeks?',
    },
    {
      id: 'd',
      isLoading: isCompaniesLoading,
      content: `I'm meeting with customer ${company3 || 'Google'} tomorrow, I'd love to have a summary of their feedback on reporting feature`,
    },
  ];

  const isAskExpanded = useIsAskExpanded();

  return (
    <motion.div
      initial={false}
      animate={{ height: isAskExpanded ? 'calc(100vh - 60px)' : 450 }}
      style={{ maxHeight: 'calc(100vh - 60px)' }}
    >
      <Container>
        <Header>
          <Title>
            <AiIcon hasGradient />
            <span>Ask Cycle</span>
          </Title>
          <Actions>
            <Action
              tooltip={isAskExpanded ? 'Collapse' : 'Expand'}
              tooltipPlacement="top"
              onClick={toggleIsAskExpanded}
            >
              <ExpandIcon size={15} />
            </Action>

            <Action onClick={closeAskPanel}>
              <CloseIcon size={12} />
            </Action>
          </Actions>
        </Header>

        <Content
          $withBorder={withBorderBottom && !answerId}
          $withGradient={withBorderTop}
        >
          <Scrollable
            $isColumn={isExampleScreen}
            style={{ opacity: answerId ? 0 : 1 }}
            ref={scrollableRef}
            onScroll={e => {
              const el = e.target as HTMLDivElement;
              setWithBorderTop(isExampleScreen ? el.scrollTop !== 0 : el.scrollTop !== el.getBoundingClientRect().height - el.scrollHeight);
              if (el.scrollTop < 0 && !withBorderBottom) setWithBorderBottom(true);
              if (el.scrollTop === 0 && withBorderBottom) setWithBorderBottom(false);
            }}
          >
            {isExampleScreen && (
              <AnimatePresence>
                <AskExampleList>
                  {examples.map((question, i) => (
                    <motion.li
                      initial={{
                        opacity: 0,
                        y: 2,
                      }}
                      animate={{
                        opacity: 1,
                        y: 0,
                        transition: { delay: 0.05 * i },
                      }}
                      // eslint-disable-next-line react/no-array-index-key
                      key={question.id}
                    >
                      <AskExample
                        variant="outlined-alt"
                        size="M"
                        onClick={() => {
                          if (question.isLoading) return;
                          inputRef.current?.focus();
                          setAskPanel({ question: question.content });
                        }}
                        style={question.isLoading ? {
                          height: '32px',
                          width: '100%',
                        } : {}}
                      >
                        {question.isLoading ? (
                          <Skeleton style={{
                            width: '100%',
                            height: '6px',
                          }}
                          />
                        ) : question.content}
                      </AskExample>
                    </motion.li>
                  ))}
                </AskExampleList>
              </AnimatePresence>
            )}
            {!isExampleScreen && (
              <>
                {items.map(item => (item.type === 'question' ? (
                  <div
                    key={item.id}
                    className="flex flex-col items-center gap-4"
                  >
                    <QuestionDate>{format(parseISO(item.createdDate), 'PP')}</QuestionDate>
                    <Question key={item.id}>
                      {item.content}
                    </Question>
                  </div>
                ) : (
                  <AnswerItem
                    key={item.id}
                    answer={answers[item.id]}
                    openDocs={() => setAnswerId(item.id)}
                  />
                )))}
              </>
            )}
          </Scrollable>

          {!isExampleScreen && answerId && (
            <AnswerDocs
              docs={answers[answerId]?.content?.docs ?? []}
              hide={() => setAnswerId(null)}
            />
          )}
        </Content>

        <Footer>
          {(isExampleScreen || !hasAnswers) && (
            <TipMessage>
              <InfoIconOutline />
              Tip: you can mention your customers or date ranges to filter feedback
            </TipMessage>
          )}
          <QuestionInput
            ref={inputRef}
            key={defaultValue}
            defaultValue={defaultValue}
            autoFocus
            placeholder="Ask a question to your feedback"
            onKeyDown={async (e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                const question = e.currentTarget.value.trim();
                if (!question) return;
                e.currentTarget.value = '';
                await handleAsk(question);
              }
            }}
          />
        </Footer>
      </Container>
    </motion.div>
  );
};
