import { DoctypeFragment, DoctypeRelativeFragment, DoctypeType, DocTypeChildrenFragment, Doctype } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import keyBy from 'lodash/keyBy';
import uniq from 'lodash/uniq';
import { isPresent } from 'ts-is-present';

const sortedNames = ['Feedback', 'Insight'];

type SortByNameFn = <T extends { name: string }>(items: T[]) => T[];

export const sortDocTypes: SortByNameFn = (docTypes) => [
  ...sortedNames.map(name => docTypes.find(d => d.name === name)).filter(isPresent),
  ...docTypes.filter(d => !sortedNames.includes(d.name)),
];

export const isFeedback = (docType?: { type: DoctypeType }) => !!docType && docType.type === DoctypeType.Feedback;
export const isInsight = (docType?: { type: DoctypeType }) => !!docType && docType.type === DoctypeType.Insight;
export const isNotInsight = (docType?: { type: DoctypeType }) => !isInsight(docType);
export const isBuiltIn = (docType?: { type: DoctypeType }) => isFeedback(docType) || isInsight(docType);
export const isCustom = (docType?: { type: DoctypeType }) => !isBuiltIn(docType);
export const isParentOfInsight = (docType?: Partial<DoctypeFragment>) => !!docType?.children?.edges.some(e => isInsight(e.node));
export const isLinkedToFeedback = isParentOfInsight;
export const isLinkedToReleases = (docType: DoctypeFragment) => !!docType.release;
export const isLinkedToAI = (docType: DoctypeFragment) => !!docType.withAI;

type CustomPartialDocTypeChildrenFragment = {
  children?: {
    edges: {
      node: {
        id: string;
        type: DoctypeType;
      };
    }[];
  } | null;
};
export const getInsightChildren = (docType?: CustomPartialDocTypeChildrenFragment) => docType &&
  docType.children?.edges.find(edge => edge.node.type === DoctypeType.Insight)?.node;
export const hasInsightChildren = (docType?: DocTypeChildrenFragment) => !!getInsightChildren(docType);

export const findFeedback = <T extends DoctypeRelativeFragment>(docTypes: T[]) => docTypes.find(isFeedback);

export const findInsight = <T extends DoctypeRelativeFragment>(docTypes: T[]) => docTypes.find(isInsight);

/**
 * Get all ancestors or all descendants of a doc type
 * @returns doc type ids
 */
const getDocTypeDeepRelatives = (
  target: 'children' | 'parent',
  docTypes: DoctypeFragment[],
  docType?: DoctypeFragment | null,
) => {
  const docTypesById = keyBy(docTypes, 'id');
  const recursive = (d?: DoctypeFragment | null): string[] => {
    const relatives = nodeToArray(d?.[target === 'children' ? 'parents' : 'children']);
    return [
      ...relatives.map(p => p.id),
      ...relatives.flatMap(p => recursive(docTypesById[p.id])).filter(isPresent),
    ];
  };
  return uniq(recursive(docType));
};

export const getPotentialRelatives = (
  docTypes: DoctypeFragment[],
  docType?: DoctypeFragment | null,
  target: 'children' | 'parent' = 'children',
) => {
  const parents = nodeToArray(docType?.parents);
  const children = nodeToArray(docType?.children);
  const relatives = [...parents, ...children];

  const deepRelatives = docType?.type === DoctypeType.Insight
    ? []
    : getDocTypeDeepRelatives(target, docTypes, docType);

  const relativeIds = uniq([
    ...relatives.map(d => d.id),
    // cannot have cyclic hierarchy rule
    ...deepRelatives,
  ]);

  return docTypes.filter(d => !relativeIds.includes(d.id) && d.id !== docType?.id && isCustom(d));
};

type GetDocTypeNames = (
  docTypes: { type: DoctypeType; name: string }[],
  options?: {
    prefix?: string;
    suffix?: string;
  }
) => string;

export const getDocTypeNames: GetDocTypeNames = (
  docTypes,
  options,
) => {
  const prefix = options?.prefix || '';
  const suffix = options?.suffix || '';

  return docTypes.reduce((prev, docType, index, array) => {
    const comaToAdd = prev === prefix ? '' : ',';

    if (index === array.length - 1) {
      return `${prev} or ${getDocTypeName(docType).toLowerCase()}${suffix}`;
    }

    return `${prev}${comaToAdd} ${getDocTypeName(docType).toLowerCase()}`;
  }, prefix);
};

export type DocTypeLabelData = Pick<Doctype, 'emoji' | 'name'> | null | undefined;

export const getDocTypeName = (
  doctype?: { type: DoctypeType; name: string },
  plural?: boolean,
) => {
  if (!doctype) return '';
  if (doctype.type === DoctypeType.Feedback) return 'Feedback';
  if (doctype.type === DoctypeType.Insight) return plural ? 'Quotes' : 'Quote';
  return plural ? `${doctype.name}s` : doctype.name;
};

export const showDocTypeEmoji = (doctype?: { type: DoctypeType }) => doctype?.type === DoctypeType.Custom;

export const getCustomDocTypesCount = (docTypesMap: Record<string, DoctypeFragment>) => Object.values(docTypesMap)
  .filter(d => DoctypeType.Custom === d.type)
  .length;

export const getDocTypeInfo = (docType?: DoctypeFragment): string | null => {
  if (!docType) return null;

  // Insight
  if (docType.type === DoctypeType.Insight) {
    return 'Quote statuses are automated based on the features they’re linked to.';
  }

  // Parent of insight
  if (docType?.children?.edges.some(e => e.node.type === DoctypeType.Insight)) {
    const children = docType.children.edges
      .filter(e => e.node.type !== DoctypeType.Insight)
      .map(e => e.node.name);

    if (!children.length) return null;

    const formattedChildren = children.length > 1
      ? `${children.slice(0, -1).join(', ')} and ${children[children.length - 1]}`
      : children[0];

    return [
      `For ${docType.name}, statuses are automatically updated based on ${formattedChildren} statuses.`,
      'They can also be manually updated.',
    ].join(' ');
  }

  return null;
};
