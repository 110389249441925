import { DocQuotes } from 'src/components/DocInsights';
import { useReleaseNote } from 'src/hooks/releases/useReleaseNote';
import { Layer } from 'src/types/layers.types';

export const ReleaseDocInsights = ({
  noteId,
  isReadOnly = false,
}: {
  noteId: string;
  isReadOnly?: boolean;
}) => {
  const { releaseNote } = useReleaseNote(noteId);
  if (!releaseNote?.doc) return null;
  return (
    <DocQuotes
      doc={releaseNote.doc}
      context="doc-item"
      isReadOnly={isReadOnly}
      layer={Layer.DropdownModalZ1}
    />
  );
};
