import { GroupNodeDocument } from '@cycle-app/graphql-codegen';

import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useIsDocInBoard } from 'src/hooks/useIsDocInBoard';
import { useMergeQuery } from 'src/hooks/useMergeQuery';
import { FullDocWithPublicId } from 'src/types/doc.types';
import { defaultGroupPagination } from 'src/utils/pagination.util';

export const usePublishDocInCache = () => {
  const docQuery = useBoardConfig(ctx => ctx.boardConfig?.docQuery);
  const isSortByOldest = useBoardConfig(ctx => ctx.isSortByOldest);
  const isSortByMostRecent = useBoardConfig(ctx => ctx.isSortByMostRecent);
  const isSortByDate = useBoardConfig(ctx => ctx.isSortByDate);
  const isDocInBoard = useIsDocInBoard();

  const mergeGroup = useMergeQuery({
    query: GroupNodeDocument,
  });

  return (doc: FullDocWithPublicId) => {
    if (!isDocInBoard(doc)) return;

    // TODO: implement the other cases
    if (docQuery?.__typename !== 'BoardQueryWithGroupBy') return;
    if (docQuery.groupbyConfig.property.__typename !== 'StatusDefinition') return;

    const group = docQuery.docGroups.edges.find(e => e.node.propertyValue?.id === doc?.status?.id)?.node;
    if (!group) return;

    if (isSortByOldest && group.docs.pageInfo.hasNextPage) return;

    mergeGroup(
      {
        groupId: group.id,
        ...defaultGroupPagination,
      },
      {
        node: {
          docs: {
            edges: [{
              __typename: 'DocEdge',
              node: {
                ...doc,
                isDraft: false,
                docSource: null,
              },
            }],
          },
        },
      },
      !isSortByDate || isSortByMostRecent ? 'start' : 'end',
    );
  };
};
