import { useQuery } from '@apollo/client';
import { DocImportDocument } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo } from 'react';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { extract } from 'src/types/graphql.types';

import { useMaybeMeV2 } from '../useMe';

const PAGINATION_SIZE = 20;

/**
 * Fetches the list of draft feedback docs imported with the dropzone
 * Pagination is configured with a type policy in /services/cache.ts
 */
export const useDocImport = () => {
  const { me } = useMaybeMeV2();
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const {
    data, loading: isLoadingDocImport, fetchMore,
  } = useQuery(DocImportDocument, {
    skip: !me?.id,
    variables: {
      id: me?.id as string,
      productId,
      size: PAGINATION_SIZE,
      cursor: '',
    },
  });

  const node = extract('Me', data?.node);
  const hasMoreDocImport = !!node?.docImport.pageInfo?.hasNextPage;
  const docs = useMemo(() => nodeToArray(node?.docImport), [node?.docImport]);

  const loadMoreDocImport = async () => {
    if (!hasMoreDocImport) return;
    await fetchMore({
      variables: {
        cursor: node?.docImport.pageInfo?.endCursor,
      },
    });
  };

  return {
    data,
    docs,
    isLoadingDocImport,
    hasMoreDocImport,
    loadMoreDocImport,
  };
};
