import { ThreadCommentFragment } from '@cycle-app/graphql-codegen';
import { Avatar } from '@cycle-app/ui';
import { distanceToNow } from '@cycle-app/utilities';
import { useCallback, useEffect, useRef, useState } from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';

import { useDocPanelContext } from 'src/contexts/docPanelContext';
import { useGetDoc } from 'src/reactives';
import { hideRightPanelChat, setMainThread, setThreadsPanel, useThreadsPanel } from 'src/reactives/comments.reactive';
import { setCommentNavigation, useGetCommentNavigation } from 'src/reactives/notifications.reactive';
import { getUserLabel } from 'src/utils/users.util';

import { isCommandBarOpen } from '../../hooks/modals/useCommandBarModal';
import { DropdownLayer } from '../DropdownLayer';
import { CommentEditor } from '../Editor';
import { ThreadActions } from './ThreadActions';
import {
  Container, Comment, Quote, ReplyCount, Header, Meta, Actions, Author, Date, StyledEditComment, StyledInlineChat,
} from './ThreadItem.styles';

export const ThreadItem = ({
  threadId, docId, blockId, markHtml, replyCount, comment, isResolved,
}: {
  threadId: string;
  docId: string;
  blockId: string | null;
  markHtml?: string | null;
  replyCount: number;
  comment: ThreadCommentFragment;
  isResolved: boolean;
}) => {
  const {
    section, visibleThreadId,
  } = useThreadsPanel();
  const { threadId: searchThreadId } = useGetCommentNavigation();
  const editor = useDocPanelContext(ctx => ctx.editor);

  const {
    hoverBlockId, openBlockId,
  } = useThreadsPanel();
  const [isEditing, setEditing] = useState(false);

  // We can't open a thread if there is no corresponding mark in the editor or if this is a resolved main thread
  const openInRightPanel =
    (section === 'all' && ((!blockId && isResolved) || !markHtml)) ||
    (section === 'closed' && blockId === null);

  const openThread = useCallback(() => {
    if (!editor || isEditing) return;

    if (openInRightPanel) {
      setThreadsPanel({
        visibleThreadId: threadId,
        openBlockId: threadId,
        hoverBlockId: undefined,
      });
      return;
    }

    setThreadsPanel({
      visibleThreadId: undefined,
      openBlockId: threadId,
      hoverBlockId: undefined,
    });

    if (blockId === null) {
      document.querySelector('.doc-panel-comments')?.scrollIntoView();
      setMainThread({ isVisible: true });
    }

    const element = document.querySelector(`[data-mark-id="${blockId}"]`);
    if (!(element instanceof HTMLElement)) return;
    element.scrollIntoView();
    element.click();
  }, [blockId, isEditing, openInRightPanel, editor, threadId]);

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!ref.current || isEditing || !searchThreadId || markHtml === undefined) return;

    if ((searchThreadId === threadId) || openBlockId === threadId || openBlockId === blockId) {
      scrollIntoView(ref.current, {
        behavior: 'smooth',
        scrollMode: 'if-needed',
      });
    }

    if (searchThreadId === threadId && !isCommandBarOpen()) {
      openThread();
      setCommentNavigation({ threadId: undefined });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchThreadId, markHtml]);

  const isCreatedByMe = comment.creator.__typename === 'Me';

  const { isEditorSyncing } = useGetDoc();

  return (
    <DropdownLayer
      withPortal
      withWrapper={false}
      placement="left-start"
      offset={[8, 8]}
      disabled={isEditing}
      visible={visibleThreadId === threadId}
      hide={hideRightPanelChat}
      content={(
        <StyledInlineChat
          docId={docId}
          threadId={threadId}
          blockId={blockId}
          hide={hideRightPanelChat}
          isResolved={isResolved}
          firstCommentId={comment.id}
        />
      )}
    >
      <Container
        ref={ref}
        $isDisabled={isEditing}
        $isHover={hoverBlockId === blockId && (section === 'open' || !!blockId)}
        $isOpen={openBlockId === threadId || openBlockId === blockId}
        onClick={openThread}
        onMouseEnter={() => setThreadsPanel({ hoverBlockId: blockId })}
        onMouseLeave={() => setThreadsPanel({ hoverBlockId: undefined })}
      >
        <Header>
          <Meta>
            <Avatar user={comment.creator} size={20} />
            <Author>{getUserLabel(comment.creator)}</Author>
            {comment.createdAt && <Date>{distanceToNow(comment.createdAt)}</Date>}
          </Meta>

          <Actions>
            <ThreadActions
              threadId={threadId}
              docId={docId}
              blockId={blockId}
              firstCommentId={comment.id}
              isCreatedByMe={isCreatedByMe}
              onEdit={() => setEditing(true)}
              isResolved={isResolved}
            />
          </Actions>
        </Header>

        {/* eslint-disable-next-line react/no-danger */}
        {!isEditing && !isEditorSyncing && markHtml && <Quote dangerouslySetInnerHTML={{ __html: markHtml }} />}

        {!isEditing && (
          <Comment onClick={e => e.stopPropagation()}>
            <CommentEditor
              content={comment.content}
              isReadOnly
            />
          </Comment>
        )}

        {isEditing && (
          <StyledEditComment
            docId={docId}
            comment={comment}
            close={() => setEditing(false)}
          />
        )}

        {!isEditing && replyCount > 0 && (
          <ReplyCount>
            {`${replyCount} ${replyCount > 1 ? 'replies' : 'reply'}`}
          </ReplyCount>
        )}
      </Container>
    </DropdownLayer>
  );
};
