import { Skeleton } from '@cycle-app/ui';

import { QuoteCardContainer } from './QuoteCards.styles';

export const QuoteCardSkeleton = () => {
  return (
    <QuoteCardContainer className="!p-0">
      <Skeleton height={70} />
    </QuoteCardContainer>
  );
};
