import { ComponentProps, forwardRef, Ref } from 'react';
import { twJoin } from 'tailwind-merge';

import { allIconsMap } from './iconPack';

export type IconName = keyof typeof allIconsMap;

function getIconPath(name: IconName) {
  return allIconsMap[name];
}

function BaseIcon({
  name, className, ...props
}: { name: IconName } & Omit<ComponentProps<'svg'>, 'name'>, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      ref={ref}
      className={twJoin('svg-icon', className)}
      {...props}
    >
      <use href={`${getIconPath(name)}#${name}`} />
    </svg>
  );
}

export const Icon = forwardRef(BaseIcon);
