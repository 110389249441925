import { useQuery } from '@apollo/client';
import { DocDoctypeIdDocument } from '@cycle-app/graphql-codegen';
import { useMemo } from 'react';

import { getDocType } from 'src/reactives/docTypes.reactive';
import { isFeedback, isInsight } from 'src/utils/docType.util';

export const useDocDoctype = (docId: string | null) => {
  const { data } = useQuery(DocDoctypeIdDocument, {
    skip: !docId,
    variables: {
      id: docId as string,
    },
    fetchPolicy: 'cache-first',
  });
  const doc = useMemo(() => (data?.node?.__typename === 'Doc' ? data.node : null), [data]);
  const doctype = getDocType(doc?.doctype.id);
  return {
    doc,
    doctype,
    isInsight: isInsight(doctype),
    isFeedback: isFeedback(doctype),
  };
};
