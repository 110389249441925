import { Popover, PopoverProps } from '@cycle-app/ui';
import { ReactNode } from 'react';
import { twJoin } from 'tailwind-merge';

import { mappingZindex } from '../../../../constants/zIndex.constant';
import { Layer } from '../../../../types/layers.types';

type Action = {
  label: ReactNode;
  icon?: ReactNode;
  action: VoidFunction;
  hidden?: boolean;
};

export function EditableContainer({
  children,
  actions,
  empty,
  smallContainer,
  popoverProps,
}: {
  children: ReactNode;
  actions: Action[];
  empty?: boolean;
  smallContainer?: boolean;
  popoverProps?: PopoverProps;
}) {
  if (!actions.length) return <>{children}</>;

  return (
    <Popover
      placement="top-start"
      interactive
      withPortal
      withWrapper={false}
      animation
      offset={smallContainer ? [-4, 8] : [-12, 16]}
      zIndex={mappingZindex[Layer.Dropdown]}
      content={(
        <div
          // eslint-disable-next-line max-len
          className="flex divide-x divide-grey-200 overflow-clip rounded-lg border border-grey-200 bg-white text-primary shadow-sm dark:divide-grey-800 dark:border-grey-800 dark:bg-grey-850"
        >
          {actions.filter(action => !action.hidden).map((action, actionIndex) => (
            <button
              key={actionIndex}
              className="flex cursor-pointer items-center gap-1.5 px-2 py-1 text-sm font-medium hover:bg-grey-100 dark:hover:bg-grey-800"
              onClick={action.action}
            >
              {action.icon}
              {action.label}
            </button>
          ))}
        </div>
      )}
      {...popoverProps}
    >
      <div
        className="group relative cursor-pointer hover:z-10 aria-expanded:z-10"
        onClick={actions[0]?.action}
      >
        <div
          className={twJoin(
            // eslint-disable-next-line max-len
            'absolute z-0 border border-[var(--interaction-color-level-2)] bg-[var(--interaction-color-level-1)] opacity-0 transition-opacity duration-200 group-hover:opacity-100 group-aria-expanded:opacity-100',
            smallContainer ? '-inset-1 rounded-md' : '-inset-3 rounded-2xl',
            empty && 'opacity-100',
          )}
        />

        <div className="isolate">{children}</div>
      </div>
    </Popover>
  );
}
