import { TypedDocumentNode, useApolloClient } from '@apollo/client';
import { DeepPartial } from '@cycle-app/utilities';
import { mergeDeepWith } from 'ramda';

interface UseWriteQueryParams<R, V> {
  query: TypedDocumentNode<R, V>;
}

type MergingStrategy = 'start' | 'end' | 'replace';

export const useMergeQuery = <R extends Record<string, unknown>, V extends Record<string, unknown>>({ query }: UseWriteQueryParams<R, V>) => {
  const { cache } = useApolloClient();

  return (variables: V, replacing: DeepPartial<R>, mergingStrategy: MergingStrategy = 'start') => {
    const existing = cache.readQuery<R, V>({
      query,
      variables,
    });

    if (!existing) return;

    const mergingFunction = getMergingFunction(mergingStrategy);

    const data = mergeDeepWith(mergingFunction, replacing, existing);

    cache.writeQuery({
      query,
      variables,
      data,
    });
  };
};

function getMergingFunction <T>(strategy: MergingStrategy): (l1: T[], l2: T[]) => T[] {
  if (strategy === 'replace') {
    return (l1: T[]) => l1;
  }
  return strategy === 'start'
    ? (l1: T[], l2: T[]) => [...l1, ...l2]
    : (l1: T[], l2: T[]) => [...l2, ...l1];
}
