import { useMemo } from 'react';

import { getDocKey } from 'src/utils/doc.util';

import { InsightList } from './useDocInsights.types';
import { useDocChildren, UseDocChildrenOptions } from '../api/useDoc';
import { useProductBase } from '../api/useProduct';

export const useDocInsightChildren = ({
  docId, doctypeId, pagination = 'most-recent',
}: {
  docId: string;
  doctypeId?: string;
  pagination?: UseDocChildrenOptions['pagination'];
}) => {
  const product = useProductBase();
  const {
    docs,
    pageInfo,
    loading: isLoading,
    loadMore,
    count,
    isPaginationLoading,
    paginationSize,
  } = useDocChildren({
    doctypeId,
    docId,
  }, {
    pagination,
  });

  const insights = useMemo(() => {
    return docs.map(node => {
      const { docSource } = node;

      const linkedDoc = {
        ...node,
        _docKey: getDocKey(product?.key, node.publicId),
      };
      if (!docSource?.__typename) {
        return {
          __typename: 'DocTarget',
          // it is fine to set an empty id here as it is not used.
          // loops keys should be done on the doc id.
          id: '',
          doc: linkedDoc,
        };
      }
      const decodedDocSourceId = atob(docSource.id);
      return {
        __typename: 'DocTarget',
        id: btoa(decodedDocSourceId.replace(docSource.__typename, 'DocTarget')),
        blockId: docSource.blockId,
        content: docSource.content,
        doc: linkedDoc,
      };
    }) as InsightList;
  }, [docs, product]);

  return {
    count,
    insights,
    isLoading,
    loadMore,
    pageInfo,
    isPaginationLoading,
    paginationSize,
  };
};
