import { typo, truncate } from '@cycle-app/ui';
import { DownIcon } from '@cycle-app/ui/icons';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div<{ $isActive?: boolean }>`
  border-bottom: 1px solid ${p => p.theme.colors.releases.border};
`;

export const Header = styled.div`
  position: relative;
  margin: 0 1px;
  height: 60px;
`;

export const Toggle = styled.button`
  position: absolute;
  inset: 0;
  padding: 0;
  cursor: pointer;
`;

export const Content = styled.div`
  position: absolute;
  inset: 0;
  padding: 0 15px;

  pointer-events: none;
  button { pointer-events: auto; }

  display: flex;
  align-items: center;
  gap: 16px;
  > * { flex: none; }
`;

export const TextContent = styled.div`
  flex: 1;
  overflow: hidden;

  display: flex;
  align-items: center;
  gap: 8px;
  > * { flex: none; }
`;

export const ReleaseTitle = styled.div`
  flex: 1;
  ${truncate}
  ${typo.body400}
`;

export const Caret = styled(DownIcon)`
  color: ${p => p.theme.colors.releases.icon};
`;

export const ReleaseNotesContainer = styled(motion.div)`
  overflow: hidden;
`;
