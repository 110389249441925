import { useQuery, useSubscription } from '@apollo/client';
import {
  BillingPlan,
  GetRoleByProductDocument,
  ProductSubscriptionDocument,
  ProductSubscriptionSubscription,
  ProductSubscriptionSubscriptionVariables,
  Role,
  UpdateMeDocument,
  UserJourney,
} from '@cycle-app/graphql-codegen';
import { useRef } from 'react';

import { PageId } from 'src/constants/routing.constant';
import { useNavigate, useSafeMutation } from 'src/hooks';
import { resetOnboarding } from 'src/reactives/lightOnboarding.reactive';
import { extract } from 'src/types/graphql.types';

interface Props {
  productId: string;
  plan: BillingPlan;
  userId: string;
}

export const OnboardingAutologin = ({
  productId, userId, plan,
}: Props) => {
  const currentPlan = useRef(plan);
  const [updateMe] = useSafeMutation(UpdateMeDocument);
  const { navigate } = useNavigate();
  const productRoleQuery = useQuery(GetRoleByProductDocument, {
    variables: {
      userId,
      roleProductId: productId,
    },
  });
  const role = extract('Me', productRoleQuery.data?.node)?.productRole;
  useSubscription<ProductSubscriptionSubscription, ProductSubscriptionSubscriptionVariables>(ProductSubscriptionDocument, {
    variables: {
      productId,
    },
    onData: async ({ data: { data } }) => {
      if (!data?.product) return;
      const { product } = data;
      if ((currentPlan.current !== product.plan) && product.plan === BillingPlan.Standard) {
        await updateMe({ variables: { userJourney: UserJourney.Done } });
        const isFirstMaker = product.nbMakers === 1 && role === Role.Maker;
        if (isFirstMaker) {
          navigate(PageId.Inbox, {
            productSlug: product.slug,
          }, {
            isOnboarding: false,
          });
        } else {
          navigate(PageId.Main, {
            productSlug: product.slug,
          });
        }
        resetOnboarding();
      }
    },
  });
  return null;
};
