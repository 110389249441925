import { useState } from 'react';

import { useOptimizedBooleanState } from '../../hooks';
import { useProductArea } from '../../hooks/product/useProductArea';
import { useProductAreas } from '../../hooks/product/useProductAreas';
import { useWorkspaceProductAreaUpdate } from '../../hooks/product/useWorkspaceProductAreaUpdate';
import DialogModal from '../DialogModal/DialogModal';
import { SelectOptionsManager } from '../SelectOptionsManager';

type Props = {
  isMulti: boolean;
  selectedValues: string[];
  onSelect: (data: { productAreaId: string; isMulti: boolean }) => void;
  onUnselect?: (productAreaId: string) => void;
  onClearValue?: VoidFunction;
};

export const ProductAreasManager = ({
  isMulti, selectedValues, onSelect, onUnselect, onClearValue,
}: Props) => {
  const productAreas = useProductAreas();
  const {
    create, remove, update,
  } = useWorkspaceProductAreaUpdate();
  const [optionIdToDelete, setOptionIdToDelete] = useState('');
  const productAreaToDelete = useProductArea(optionIdToDelete);
  const [isDeleteConfirmed, {
    toggleCallback: toggleDeleteConfirm,
    setFalseCallback: setDeleteConformFalse,
  }] = useOptimizedBooleanState(false);
  return (
    <>
      <SelectOptionsManager
        hasSearch
        onCreate={(value) => create({
          name: value,
        })}
        onCreateKeypress={(value) => create({
          name: value,
        })}
        options={productAreas.map(area => ({
          value: area.id,
          label: area.value,
          selected: !!selectedValues?.includes(area.id),
        }))}
        onDeleteOption={productAreas.length === 1 ? undefined : value => setOptionIdToDelete(value)}
        onEditOption={(optionId, value) => update({
          productAreaId: optionId,
          name: value,
        })}
        onSelect={option => {
          if (option.selected) {
            onUnselect?.(option.value);
          } else {
            onSelect({
              productAreaId: option.value,
              isMulti,
            });
          }
        }}
        onRemove={isMulti ? undefined : onClearValue}
        selectedValue={selectedValues?.[0]}
        showClearOption={!isMulti}
        isMulti={isMulti}
        showWarningOnNoneValue={false}
        shouldCreateValueInComponent={false}
      />
      {productAreaToDelete && (
        <DialogModal
          className="w-[500px]"
          title="Delete product area"
          info={(
            <>
              {'Are you sure you want to delete the product area '}
              <span className="font-bold">{productAreaToDelete.value}</span>
              ? Docs with this value will lose it.
            </>
          )}
          confirmLabel="Delete"
          useHighMaskLayer
          hide={() => {
            setDeleteConformFalse();
            setOptionIdToDelete('');
          }}
          onConfirm={() => remove({ productAreaId: productAreaToDelete.id })}
          confirmMessage="Yes, permanently delete this product area"
          onToggleConfirmation={toggleDeleteConfirm}
          isConfirmDisabled={!isDeleteConfirmed}
        />
      )}
    </>
  );
};