import { Color } from '@cycle-app/graphql-codegen';
import styled, { css } from 'styled-components';

export type Size = 12 | 14 | 16 | 18 | 24 | 32 | 48 | 64;

type ContainerProps = {
  $size: Size;
  $inline: boolean;
  $native: boolean;
  $isIcon: boolean;
  $color?: Color;
};

export const Container = styled.span<ContainerProps>`
  max-width: ${p => p.$size}px;
  position: static;
  ${p => p.onClick && 'cursor: pointer'};
  ${p => !p.$native && css`
    max-height: ${p.$size}px;
  `};
  display: ${p => (p.$inline ? 'inline' : 'block')};
  span {
    line-height: ${p => p.$size}px;
    font-size: ${p => p.$size}px;
    ${p => !p.$inline && `
      display: block;
      width: ${p.$size}px;
      height: ${p.$size}px;
    `}
  }
  ${p => p.$color && css`
    color: ${p.theme.nuances[p.$color].main};
  `}
  ${p => (p.$color || p.$isIcon) && css`
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;
