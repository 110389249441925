import {
  CustomerFragment,
  RemoveCustomerDocument,
  RemoveCustomerMutationVariables,
} from '@cycle-app/graphql-codegen';

import useSafeMutation from 'src/hooks/useSafeMutation';
import { refetchBoardWithConfigQuery } from 'src/utils/boardConfig/boardConfig.util';

import { useProduct } from '../../useProduct';

export const useCustomerRemove = (currentCustomer: CustomerFragment) => {
  const { product } = useProduct();
  const [changeMutation, { loading }] = useSafeMutation(RemoveCustomerDocument, {
    displayErrorMessages: true,
    errorPolicy: 'all',
    refetchQueries: refetchBoardWithConfigQuery,
    update(cache, { data }) {
      if (!data?.removeCustomer || !product) return;

      const idToRemove = data.removeCustomer.id;

      cache.modify({
        id: cache.identify(product),
        fields: {
          customerCount: (count) => Math.max(0, count - 1),
          customers: (customers, { readField }) => ({
            ...customers,
            edges: customers.edges.filter((edge: any) => idToRemove !== readField('id', edge.node)),
            count: Math.max(0, customers.count - 1),
          }),
        },
      });

      if (currentCustomer.company) {
        cache.modify({
          id: cache.identify(currentCustomer.company),
          fields: {
            countCustomers: (count) => Math.max(0, count - 1),
            customers: (customers, { readField }) => ({
              ...customers,
              edges: customers.edges.filter((edge: any) => idToRemove !== readField('id', edge.node)),
              count: Math.max(0, customers.count - 1),
            }),
          },
        });
      }
      // We now have customers inside board filters, we need to evict.
      const normalizedId = cache.identify({
        id: data.removeCustomer.id,
        __typename: data.removeCustomer.__typename,
      });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  const remove = ({ customerId }: RemoveCustomerMutationVariables) => {
    if (!customerId) throw Error('Data are missing');

    return changeMutation({
      variables: {
        customerId,
      },
    });
  };

  return {
    remove,
    isLoading: loading,
  };
};
