import { GraphQLFormattedError } from 'graphql';
import { useForm, UseFormProps, Path, FieldValues } from 'react-hook-form';

import { addErrorToaster } from 'src/utils/errorToasters.utils';

import { ErrorMessage } from '../../constants/errors.constants';
import { isErrorMessage } from '../useSafeMutation';

type ErroMapCommon<T> = {
  fieldName: Path<T>;
  renderMessage?: (data: T) => string;
};

export type ErrorMap<T> = ErroMapCommon<T> & (
  { code: string; messagePattern?: never } | { messagePattern: RegExp; code?: never }
);

// Error handling is not speced yet so keep it here.
// For now we match the message with the field name.
const getFieldsMutationErrors = <T extends FieldValues>(
  errors: readonly GraphQLFormattedError[],
  errorsMap: ErrorMap<T>[],
) => errors.map(({ message }) => {
    const mappedError = errorsMap.find(mutationErrorMap => {
      if ('code' in mutationErrorMap) {
        return mutationErrorMap.code === message;
      }
      if ('messagePattern' in mutationErrorMap) {
        return mutationErrorMap.messagePattern.test(message);
      }
      return false;
    });
    return mappedError && {
      message,
      ...mappedError,
    };
  });

export const useEnhancedForm = <T extends FieldValues>(formProps: UseFormProps<T>) => {
  const formHook = useForm<T>(formProps);

  const displayFieldsErrors = (
    errors: readonly GraphQLFormattedError[],
    errorsMap: ErrorMap<T>[],
    showInToaster = false,
  ) => {
    const displayErrors = getFieldsMutationErrors(errors, errorsMap);
    // Some errors were not handled, avoid mixed error UI feedbacks.
    const errorMessages = errors.filter(isErrorMessage);
    if (displayErrors.some(displayError => !displayError?.fieldName)) {
      errorMessages.forEach(err => {
        addErrorToaster({ message: ErrorMessage[err.message] });
      });
      if (errors.length !== errorMessages.length) {
        addErrorToaster({ message: ErrorMessage._GENERIC });
      }
      return;
    }
    displayErrors.forEach(displayError => {
      if (displayError?.fieldName) {
        const message = displayError.renderMessage?.(formHook.getValues()) || displayError.message;
        formHook.setError(displayError.fieldName, {
          type: 'manual',
          message,
        });
        if (showInToaster) {
          addErrorToaster({ message });
        }
      }
    });
  };

  return {
    displayFieldsErrors,
    ...formHook,
  };
};
