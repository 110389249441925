import { useQuery } from '@apollo/client';
import { SearchHiddenSwimlanesDocument } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo, useCallback } from 'react';

export default function useSearchHiddenSwimlanes(groupConfigId: string | null) {
  const {
    data: searchHideableGroupsData,
    variables,
    refetch,
  } = useQuery(SearchHiddenSwimlanesDocument, {
    fetchPolicy: 'cache-only',
    nextFetchPolicy: 'cache-and-network',
    skip: !groupConfigId,
    variables: {
      text: '',
      groupConfigId: groupConfigId as string,
    },
  });

  const searchHideableGroups = useCallback((text: string) => refetch({ text }), [refetch]);

  const searchResult = useMemo(() => {
    if ((variables?.text ?? '').length === 0) {
      return null;
    }
    return nodeToArray(searchHideableGroupsData?.searchSwimlanes);
  }, [searchHideableGroupsData?.searchSwimlanes, variables]);

  return {
    searchResult,
    searchHideableGroups,
  };
}
