import { forwardRef } from 'react';

import { BoardGroupNameEditable, BoardGroupNameEditableProps } from './BoardGroupNameEditable';
import { BoardGroupNameReadOnly, BoardGroupNameReadOnlyProps } from './BoardGroupNameReadOnly';

type Props = BoardGroupNameEditableProps & BoardGroupNameReadOnlyProps & {
  readOnly?: boolean;
};

export const BoardGroupName = forwardRef<HTMLInputElement, Props>(({
  readOnly = true,
  groupName,
  groupIcon,
  tooltipProps,
  ...props
}, ref) => {
  if (readOnly && !groupName) return null;
  
  if (readOnly) return (
    <BoardGroupNameReadOnly
      groupName={groupName}
      groupIcon={groupIcon}
      tooltipProps={tooltipProps}
    />
  );

  return (
    <BoardGroupNameEditable
      ref={ref}
      groupName={groupName}
      {...props}
    />
  );
});
