import { forwardRef, ReactNode } from 'react';
import { Placement } from 'tippy.js';

import { ArrowContent } from './ArrowContent';
import { ShinyContent } from './ShinyContent';
import { TooltipContent, TooltipContentProps } from './TooltipContent';
import { Popover, PopoverProps } from '../Popover/Popover';

export type TooltipProps = PopoverProps & Pick<TooltipContentProps, 'color' | 'size'> & {
  className?: string;
  title?: ReactNode;
  content: ReactNode;
  disabled?: boolean;
  visible?: boolean;
  withPortal?: boolean;
  placement?: Placement;
  enableOnMobile?: boolean;
  displayFullTitle?: boolean;
  width?: number | string;
  variant?: 'default' | 'shiny' | 'arrow';
  icon?: ReactNode;
  footer?: ReactNode;
  children?: ReactNode;
};

export type TooltipPlacement = Placement;

export const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(({
  className,
  content,
  title,
  children,
  visible,
  withPortal = false,
  placement = 'auto',
  disabled = false,
  enableOnMobile = false,
  displayFullTitle = true,
  width,
  color,
  size,
  variant,
  icon,
  footer,
  ...popoverProps
}, ref) => (
  <Popover
    disabled={disabled}
    visible={visible}
    withPortal={withPortal}
    placement={placement}
    enableOnMobile={enableOnMobile}
    animation
    content={(() => {
      if (variant === 'arrow') {
        return attrs => (
          <ArrowContent
            ref={ref}
            icon={icon}
            title={title}
            content={content}
            footer={footer}
            // eslint-disable-next-line react/destructuring-assignment
            placement={attrs['data-placement']}
          />
        );
      }

      if (variant === 'shiny') {
        return attrs => (
          <ShinyContent
            ref={ref}
            icon={icon}
            title={title}
            content={content}
            footer={footer}
            hide={popoverProps.hide}
            // eslint-disable-next-line react/destructuring-assignment
            placement={attrs['data-placement']}
          />
        );
      }

      return (
        <TooltipContent
          className={className}
          title={title}
          content={content}
          displayFullTitle={displayFullTitle}
          width={width}
          color={color}
          size={size}
        />
      );
    })()}
    {...popoverProps}
  >
    {children}
  </Popover>
));
