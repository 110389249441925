import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Scroll to top on route change
 * @see https://v5.reactrouter.com/web/guides/scroll-restoration
 */
export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    for (const element of document.querySelectorAll('.scrollable')) {
      element.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
};
