import { useSubscription } from '@apollo/client';
import { CreatedDocDocument } from '@cycle-app/graphql-codegen';
import { Emoji } from '@cycle-app/ui';
import { NextArrowIcon, BackArrowIcon } from '@cycle-app/ui/icons';

import slackBot from 'src/assets/slack-bot.png';
import slackChannels from 'src/assets/slack-channels.png';
import slackMessage from 'src/assets/slack-message-2.png';
import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useProductBase } from 'src/hooks';
import { useSlackIntegration } from 'src/hooks/slack/useSlackIntegration';
import { useSlackReacji } from 'src/hooks/slack/useSlackReacji';
import { useSlackWorkspace } from 'src/hooks/slack/useSlackWorkspace';
import { setOnboardingSlackDocId, setOnboardingSlackStep } from 'src/reactives';
import { OnboardingSlackStep } from 'src/types/onboardingSlack.type';

import {
  Container, Title, SubTitle, PrevButton, ButtonsGroup, NextButton,
} from '../OnboardingSlack.styles';
import {
  Name, StepIndex, Steps, Step, StepImageContainer, StepTitle, StyledSlackMessageEmoji, StyledFooter,
} from './StepCreateFeedback.styles';

export const StepCreateFeedback = () => {
  const { isInstalled } = useSlackIntegration();
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const {
    name, url,
  } = useSlackWorkspace();
  const { emoji } = useSlackReacji();
  const product = useProductBase();
  useSubscription(
    CreatedDocDocument,
    {
      variables: {
        productId: product?.id as string,
      },
      skip: !product?.id || !isInstalled,
      onData: ({ data: { data } }) => {
        if (data?.createdDoc?.source?.__typename === 'SourceSlack') {
          setOnboardingSlackDocId(productId, data?.createdDoc.id);
          setOnboardingSlackStep(productId, OnboardingSlackStep.FeedbackCreated);
        }
      },
    },
  );
  if (!isInstalled) {
    setOnboardingSlackStep(productId, OnboardingSlackStep.Install);
    return null;
  }
  return (
    <Container>
      <Title>Try it out in your Slack workspace</Title>
      <SubTitle>
        Open your
        {' '}
        <Name href={url} target="_blank">{name}</Name>
        {' '}
        workspace and follow the below instructions
      </SubTitle>
      <Steps>
        <Step>
          <StepIndex>1</StepIndex>
          <StepImageContainer>
            <img src={slackChannels} alt="slack channels" />
          </StepImageContainer>
          <StepTitle>Open a feedback channel</StepTitle>
        </Step>
        <Step>
          <StepIndex>2</StepIndex>
          <StepImageContainer>
            <img src={slackBot} alt="slack bot" />
          </StepImageContainer>
          <StepTitle>Add @Cycle to the channel</StepTitle>
        </Step>
        <Step>
          <StepIndex>3</StepIndex>
          <StepImageContainer>
            <img src={slackMessage} alt="slack message" />
            <StyledSlackMessageEmoji>
              <Emoji emoji={emoji} size={12} />
              1
            </StyledSlackMessageEmoji>
          </StepImageContainer>
          <StepTitle>
            Add
            {' '}
            <Emoji inline emoji={emoji} />
            {' '}
            to a message
          </StepTitle>
        </Step>
      </Steps>
      <StyledFooter>
        <ButtonsGroup>
          <PrevButton onClick={() => setOnboardingSlackStep(productId, OnboardingSlackStep.Reactji)}>
            <BackArrowIcon />
            Back
          </PrevButton>
          <NextButton
            disabled
            full
            tooltipPlacement="top"
            tooltipProps={{
              content: 'Create a feedback in Slack to move to next step',
              style: { flex: 1 },
            }}
          >
            Next
            <NextArrowIcon />
          </NextButton>
        </ButtonsGroup>
      </StyledFooter>
    </Container>
  );
};
