import { ProductAreaCreateDocument, ProductAreaDeleteDocument, ProductAreaUpdateDocument } from '@cycle-app/graphql-codegen';
import { produce } from 'immer';

import { useWorkspaceContext } from '../../contexts/workspaceContext';
import { getPermission, setLimitationsModal } from '../../reactives';
import { RefConnection } from '../../types/apollo.types';
import useSafeMutation from '../useSafeMutation';

const blockAction = () => setLimitationsModal({ action: 'HANDLE_ATTRIBUTES_OPTIONS' });

export const useWorkspaceProductAreaUpdate = () => {
  const [mutateCreate, createState] = useSafeMutation(ProductAreaCreateDocument);
  const [mutateDelete, deleteState] = useSafeMutation(ProductAreaDeleteDocument);
  const [mutateUpate, updateState] = useSafeMutation(ProductAreaUpdateDocument);
  const productId = useWorkspaceContext(ctx => ctx.productId);

  const create = ({
    name, description,
  }: { name: string; description?: string }) => {
    if (!getPermission().canUpdateAttributeOption) {
      blockAction();
      return;
    }
    return mutateCreate({
      variables: {
        productId,
        name,
        description,
      },
      optimisticResponse: {
        createProductArea: {
          id: 'temp-id',
          value: name,
          description: description || null,
        },
      },
      update: (cache, { data }) => {
        if (data?.createProductArea?.id) {
          const productAreaId = data.createProductArea.id;
          cache.modify({
            id: productId,
            fields: {
              // @ts-ignore -- TOFIX later
              productAreas: (value: RefConnection, { toReference }) => {
                const node = toReference(productAreaId);
                return node ? produce(value, draft => {
                  const edge = {
                    __typename: 'ProductAreaEdge',
                    node,
                  };
                  draft.edges.push(edge);
                }) : value;
              },
            },
          });
        }
      },
    });
  };

  const remove = ({ productAreaId }: { productAreaId: string }) => {
    if (!getPermission().canUpdateAttributeOption) {
      blockAction();
      return;
    }
    return mutateDelete({
      variables: {
        id: productAreaId,
      },
      optimisticResponse: {
        removeProductArea: {
          id: productAreaId,
        },
      },
      update: (cache, { data }) => {
        if (data?.removeProductArea?.id) {
          cache.modify({
            id: productId,
            fields: {
              // @ts-ignore -- TOFIX later
              productAreas: (value: RefConnection, { readField }) => {
                return produce(value, draft => {
                  draft.edges.splice(draft.edges.findIndex(edge => {
                    return readField<string>('id', edge.node) === productAreaId;
                  }), 1);
                });
              },
            },
          });
          cache.evict({
            id: cache.identify(data.removeProductArea),
          });
          cache.gc();
        }
      },
    });
  };

  const update = ({
    productAreaId, name, description,
  }: { productAreaId: string; name?: string; description?: string }) => {
    if (!getPermission().canUpdateAttributeOption) {
      blockAction();
      return;
    }
    return mutateUpate({
      variables: {
        id: productAreaId,
        ...typeof name !== 'undefined' && { name },
        ...typeof description !== 'undefined' && { description },
      },
    });
  };

  return {
    create,
    remove,
    update,
    isCreateLoading: createState.loading,
    isDeleteLoading: deleteState.loading,
    isUpdateLoading: updateState.loading,
  };
};