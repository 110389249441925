import { ChangelogBaseFragment, ChangelogUpdateDocument } from '@cycle-app/graphql-codegen';
import differenceWith from 'lodash/differenceWith';

import { getPermission, setLimitationsModal } from '../../reactives';
import useSafeMutation from '../useSafeMutation';
import { useChangelog } from './useChangelog';

type Variables = {
  id: string;
  isPublished?: boolean;
  tags?: { id: string; value: string; color: string }[];
  logo?: { avatar: File } | null;
  favicon?: { avatar: File } | null;
} & Partial<
Pick<ChangelogBaseFragment,
// Content
| 'socialLinkText'
| 'socialLinkURL'
| 'subtitle'
| 'title'
| 'language'
// Ship
| 'shipBadgeColor'
// Typography
| 'typography'
// Text
| 'heroSection'
| 'contentSection'
// Colors
| 'headersColor'
| 'bodyColor'
| 'backgroundColor'
| 'dividerColor'
| 'linkColor'
// Release tags
| 'releaseTagStyle'
| 'releaseTagColor'
| 'subscribeLabel'
| 'subscribeToggled'
>>;

export const useChangelogUpdate = () => {
  const [updateMutation, { loading: isLoading }] = useSafeMutation(ChangelogUpdateDocument);
  const { changelog } = useChangelog();

  const changelogUpdate = async (variables: Variables) => {
    if (!getPermission().canUpdateRelease) {
      setLimitationsModal({ action: 'RELEASE_UPDATE' });
      return null;
    }

    const {
      id,
      title,
      subtitle,
      socialLinkText,
      socialLinkURL,
      isPublished,
      logo,
      favicon,
      shipBadgeColor,
      typography,
      heroSection,
      contentSection,
      headersColor,
      bodyColor,
      backgroundColor,
      dividerColor,
      linkColor,
      releaseTagStyle,
      releaseTagColor,
      tags,
      subscribeLabel,
      subscribeToggled,
      language,
    } = variables;

    // Get the tags that were removed.
    const evict = differenceWith(
      changelog?.releaseNoteTagValues ?? [],
      variables.tags?.filter(tag => tag.id) ?? [],
      (a, b) => a.id === b.id,
    );

    return updateMutation({
      variables: {
        id,
        ...typeof title !== 'undefined' ? { title } : {},
        ...typeof subtitle !== 'undefined' ? { subtitle } : {},
        ...typeof socialLinkText !== 'undefined' ? { socialLinkText } : {},
        ...typeof socialLinkURL !== 'undefined' ? { socialLinkURL } : {},
        ...typeof language !== 'undefined' ? { language } : {},
        ...typeof isPublished !== 'undefined' ? { isPublished } : {},
        logo,
        favicon,
        // Ship
        ...typeof shipBadgeColor !== 'undefined' ? { shipBadgeColor } : {},
        // Typography
        ...typeof typography !== 'undefined' ? { typography } : {},
        // Text
        ...typeof heroSection !== 'undefined' ? { heroSection } : {},
        ...typeof contentSection !== 'undefined' ? { contentSection } : {},
        // Colors
        ...typeof headersColor !== 'undefined' ? { headersColor } : {},
        ...typeof bodyColor !== 'undefined' ? { bodyColor } : {},
        ...typeof backgroundColor !== 'undefined' ? { backgroundColor } : {},
        ...typeof dividerColor !== 'undefined' ? { dividerColor } : {},
        ...typeof linkColor !== 'undefined' ? { linkColor } : {},
        // Release tags
        ...typeof releaseTagStyle !== 'undefined' ? { releaseTagStyle } : {},
        ...typeof releaseTagColor !== 'undefined' ? { releaseTagColor } : {},
        ...typeof subscribeLabel !== 'undefined' ? { subscribeLabel } : {},
        ...typeof subscribeToggled !== 'undefined' ? { subscribeToggled } : {},
        ...tags ? {
          tags: tags.map(tag => ({
            id: tag.id,
            color: tag.color,
            value: tag.value,
          })),
        } : {},
      },
      update: (cache) => {
        if (evict.length) {
          evict.forEach(tag => cache.evict({
            id: tag.id,
          }));
        }
      },
    });
  };

  return {
    changelogUpdate,
    isLoading,
  };
};
