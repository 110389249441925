import { IntegrationType } from '@cycle-app/graphql-codegen';
import { CycleLogo, Icon } from '@cycle-app/ui';
import { IntercomIcon, SortHorizontalIcon } from '@cycle-app/ui/icons';
import { FC, ReactNode } from 'react';

import { useIntegrationSync } from 'src/hooks/integration/useIntegrationSync';
import { resetIntegrationSyncModal } from 'src/reactives/integrationSyncModal.reactive';
import { Integration } from 'src/types/integrations.types';
import { integrationNameTitles } from 'src/utils/integrations.utils';

import {
  Actions, CancelButton, Description, Logos, StyledPortalModal, SyncButton, Title,
} from './IntegrationSyncModalContent.styles';

interface Props {
  integrationType: Integration;
}

const logos: Partial<Record<Integration, ReactNode>> = {
  [IntegrationType.Slack]: <Icon name="brand/slack" className="size-12" />,
  [IntegrationType.Hubspot]: <Icon name="brand/hubspot" className="size-12" />,
  [IntegrationType.Intercom]: <IntercomIcon size={48} />,
};

export const IntegrationSyncModalContent: FC<React.PropsWithChildren<Props>> = ({ integrationType }) => {
  const { customerSync } = useIntegrationSync();
  const title = integrationNameTitles[integrationType];
  return (
    <StyledPortalModal>
      <Logos>
        {logos[integrationType]}
        <SortHorizontalIcon size={24} />
        <CycleLogo size={48} />
      </Logos>
      <Title>
        {`Do you want to sync ${title} customers?`}
      </Title>
      <Description>
        {`With the sync, you'll be able to link feedback to ${title} customers without leaving Cycle.`}
      </Description>
      <Actions>
        <SyncButton
          onClick={async () => {
            resetIntegrationSyncModal();
            await customerSync(integrationType);
          }}
          size="M"
        >
          Sync customers
        </SyncButton>
        <CancelButton
          size="M"
          variant="secondary"
          onClick={resetIntegrationSyncModal}
        >
          Not now
        </CancelButton>
      </Actions>
    </StyledPortalModal>
  );
};
