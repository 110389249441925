import { Icon } from '@cycle-app/ui';
import {
  ConfluenceIcon,
  EnjoyHqIcon,
  FilloutIcon,
  GoogleDocIcon,
  GoogleDriveIcon,
  GoogleFormIcon,
  GoogleMeetIcon,
  GoogleSheetsIcon,
  GrainIcon,
  MicrosoftTeamsIcon,
  OneDriveIcon,
  SharepointIcon,
  SliteIcon,
  TallyIcon,
  TypeformIcon,
  UserVoiceIcon,
  ZoomIcon,
} from '@cycle-app/ui/icons';

import { StyledDovetailIcon } from './OnboardingUserResearch.styles';

export const items = [
  {
    id: 'zoom',
    icon: <ZoomIcon />,
    label: 'Zoom',
  },
  {
    id: 'meet',
    icon: <GoogleMeetIcon />,
    label: 'Meet',
  },
  {
    id: 'teams',
    icon: <MicrosoftTeamsIcon />,
    label: 'Teams',
  },
  {
    id: 'slack',
    icon: <Icon name="brand/slack" className="size-4" />,
    label: 'Slack',
  },
  {
    id: 'notion',
    icon: <Icon name="brand/notion" className="size-4" />,
    label: 'Notion',
  },
  {
    id: 'google-docs',
    icon: <GoogleDocIcon />,
    label: 'Google docs',
  },
  {
    id: 'typeform',
    icon: <TypeformIcon />,
    label: 'Typeform',
  },
  {
    id: 'google-forms',
    icon: <GoogleFormIcon />,
    label: 'Google Forms',
  },
  {
    id: 'dovetail',
    icon: <StyledDovetailIcon />,
    label: 'Dovetail',
  },
  {
    id: 'fillout',
    icon: <FilloutIcon />,
    label: 'Fillout',
  },
  {
    id: 'tally',
    icon: <TallyIcon />,
    label: 'Tally',
  },
  {
    id: 'airtable',
    icon: <Icon name="brand/airtable" />,
    label: 'Airtable',
  },
  {
    id: 'google-sheet',
    icon: <GoogleSheetsIcon />,
    label: 'Google Sheet',
  },
  {
    id: 'confluence',
    icon: <ConfluenceIcon />,
    label: 'Confluence',
  },
  {
    id: 'slite',
    icon: <SliteIcon />,
    label: 'Slite',
  },
  {
    id: 'onedrive',
    icon: <OneDriveIcon />,
    label: 'OneDrive',
  },
  {
    id: 'sharepoint',
    icon: <SharepointIcon />,
    label: 'SharePoint',
  },
  {
    id: 'google-drive',
    icon: <GoogleDriveIcon />,
    label: 'Google Drive',
  },
  {
    id: 'grain',
    icon: <GrainIcon />,
    label: 'Grain',
  },
  {
    id: 'enjoyhq',
    icon: <EnjoyHqIcon />,
    label: 'EnjoyHQ',
  },
  {
    id: 'uservoice',
    icon: <UserVoiceIcon />,
    label: 'Uservoice',
  },
];
