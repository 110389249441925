import {
  CreateReleaseNoteDocument,
  CreateReleaseNoteMutationVariables, CreateReleaseNotePosition, ReleaseNoteBaseFragment,
} from '@cycle-app/graphql-codegen';

import useSafeMutation from 'src/hooks/useSafeMutation';

import { useReleaseNotesCache } from './useReleaseNotesCache';

type Params = { position: CreateReleaseNotePosition };

export const useCreateReleaseNote = ({ position }: Params, onCompleted?: (data: ReleaseNoteBaseFragment) => void) => {
  const {
    prenpendReleaseNotes, appendReleaseNotes,
  } = useReleaseNotesCache();
  const [mutate, { loading }] = useSafeMutation(CreateReleaseNoteDocument, {
    errorPolicy: 'all',
    onCompleted: (data) => {
      if (!data?.createReleaseNote) return;
      onCompleted?.(data.createReleaseNote);
    },
  });

  const createReleaseNote = (variables: CreateReleaseNoteMutationVariables) => mutate({
    variables,
    update: (_, { data }) => {
      if (!data?.createReleaseNote) return;
      const note = data.createReleaseNote;
      // Position is inverted, start is actually prepend and End is actually append.
      // See in ReleaseNotes, the form is at top when poisition is end.
      if (position === CreateReleaseNotePosition.Start) {
        appendReleaseNotes({
          releaseId: variables.releaseId,
          mainReleaseNotes: note.isOther ? [] : [note],
          otherReleaseNotes: note.isOther ? [note] : [],
        });
      } else {
        prenpendReleaseNotes({
          releaseId: variables.releaseId,
          mainReleaseNotes: note.isOther ? [] : [note],
          otherReleaseNotes: note.isOther ? [note] : [],
        });
      }
    },
  });

  return {
    createReleaseNote,
    isCreatingReleaseNote: loading,
  };
};
