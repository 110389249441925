import { CustomAttributeDefinitionFragment, ListPositionInput, AttributeTextValueFragment } from '@cycle-app/graphql-codegen';
import { SelectOption } from '@cycle-app/ui';
import { useCallback, useState, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { SelectOptionsManager } from 'src/components/SelectOptionsManager';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useOptimizedBooleanState } from 'src/hooks';
import useAttributesMutations from 'src/hooks/api/mutations/useAttributesMutations';
import { getCustomAttributeTypeData } from 'src/utils/attributes.util';

import { AttributeTagIconContainer, AttributeTagStyled, OptionTag, StyledDialogModal } from './AttributeOptionsManager.styles';

interface Props {
  attributeDefinition: CustomAttributeDefinitionFragment;
  isMulti?: boolean;
  onClearValue?: VoidFunction;
  onCreate?: (newItem: AttributeTextValueFragment | string) => void;
  onSelect?: (selectedItem: SelectOption) => void;
  options: SelectOption[];
  selectedValue?: string;
  showWarningOnNoneValue?: boolean;
  warning?: string;
  shouldCreateValueInComponent?: boolean;
}

export const AttributeOptionsManager = ({
  attributeDefinition,
  isMulti = false,
  onClearValue,
  onCreate,
  onSelect,
  options,
  selectedValue,
  showWarningOnNoneValue = false,
  shouldCreateValueInComponent = true,
  warning,
}: Props) => {
  const [optionIdToDelete, setOptionIdToDelete] = useState<string | null>(null);

  const {
    changeSelectOption,
    removeSelectOption,
    moveSelectAttributeValue,
  } = useAttributesMutations();
  const debouncedChangeSelectOption = useDebouncedCallback(changeSelectOption, INPUT_ONCHANGE_DEBOUNCE);

  const registerOptionIdToDelete = useCallback((optionId: string) => setOptionIdToDelete(optionId), []);
  const resetOptionIdToDelete = useCallback(() => setOptionIdToDelete(null), []);

  const onSorted = useCallback(
    (valueId: string, position: ListPositionInput, sortedItems: Array<string>) => (
      moveSelectAttributeValue({
        attributeId: attributeDefinition.id,
        valueId,
        position,
        sortedItems,
      })
    ),
    [moveSelectAttributeValue, attributeDefinition],
  );

  const optionData = useMemo(() => options.find(option => option.value === optionIdToDelete), [options, optionIdToDelete]);

  const [isDeleteConfirmed, {
    toggleCallback: toggleDeleteConfirm,
    setFalseCallback: setDeleteConformFalse,
  }] = useOptimizedBooleanState(false);

  const { addSelectOption } = useAttributesMutations();

  const onCreateOption = async (value: string) => {
    if (value === 'temp-id') return;
    const response = await addSelectOption(attributeDefinition.id, value);
    if (response.data?.addSelectStringAttributeValue) {
      onCreate?.(response.data.addSelectStringAttributeValue);
    }
  };

  const onCreateOptionKeypress = async (value: string) => {
    if (shouldCreateValueInComponent && value !== 'temp-id') {
      const response = await addSelectOption(attributeDefinition.id, value);
      if (response.data?.addSelectStringAttributeValue) {
        onCreate?.(response.data.addSelectStringAttributeValue);
      }
    } else {
      onCreate?.(value);
    }
  };

  return (
    <>
      <SelectOptionsManager
        sortable
        hasSearch
        onSorted={onSorted}
        onCreate={onCreateOption}
        onCreateKeypress={onCreateOptionKeypress}
        options={options}
        onDeleteOption={registerOptionIdToDelete}
        onEditOption={onEditOption}
        onSelect={onSelect}
        onRemove={onClearValue}
        selectedValue={selectedValue}
        showClearOption={!!onClearValue}
        isMulti={isMulti}
        showWarningOnNoneValue={showWarningOnNoneValue}
        warning={warning}
        shouldCreateValueInComponent={shouldCreateValueInComponent}
      />
      {optionIdToDelete && optionData && (
        <StyledDialogModal
          title="Delete option"
          info={(
            <>
              {'Are you sure you want to delete '}
              <OptionTag>{optionData.label}</OptionTag>
              {' as an option for'}
              <AttributeTagStyled
                inline
                limitSize={false}
                icon={(
                  <AttributeTagIconContainer>
                    {getCustomAttributeTypeData(attributeDefinition.__typename).icon}
                  </AttributeTagIconContainer>
                )}
              >
                {attributeDefinition.name}
              </AttributeTagStyled>
              ? Docs with this value will lose it.
            </>
          )}
          confirmLabel="Delete"
          useHighMaskLayer
          hide={() => {
            setDeleteConformFalse();
            resetOptionIdToDelete();
          }}
          onConfirm={onConfirm}
          confirmMessage="Yes, permanently delete this option"
          onToggleConfirmation={toggleDeleteConfirm}
          isConfirmDisabled={!isDeleteConfirmed}
        />
      )}
    </>
  );

  async function onEditOption(optionId: string, textValue: string) {
    await debouncedChangeSelectOption(optionId, textValue);
  }

  async function onConfirm() {
    if (!optionIdToDelete) return;
    await removeSelectOption(
      attributeDefinition,
      optionIdToDelete,
    );
  }
};
