import { Typography } from '@cycle-app/graphql-codegen';

export const CHANGELOG_TYPOGRAPHY = [
  {
    value: Typography.Inter,
    label: 'Inter',
  },
  {
    value: Typography.Montserrat,
    label: 'Montserrat',
  },
  {
    value: Typography.Roboto,
    label: 'Roboto',
  },
  {
    value: Typography.SourceSans_3,
    label: 'Source Sans 3',
  },
  {
    value: Typography.Lora,
    label: 'Lora',
  },
  {
    value: Typography.Merriweather,
    label: 'Merriweather',
  },
  {
    value: Typography.PlayfairDisplay,
    label: 'Playfair Display',
  },
  {
    value: Typography.LibreBaskerville,
    label: 'Libre Baskerville',
  },
  {
    value: Typography.WorkSans,
    label: 'Work Sans',
  },
  {
    value: Typography.Hind,
    label: 'Hind',
  },
  {
    value: Typography.Mulish,
    label: 'Mulish',
  },
  {
    value: Typography.JetbrainsMono,
    label: 'Jetbrains Mono',
  },
  {
    value: Typography.Asap,
    label: 'Asap',
  },
  {
    value: Typography.Sora,
    label: 'Sora',
  },
  {
    value: Typography.Manrope,
    label: 'Manrope',
  },
  {
    value: Typography.DmSans,
    label: 'DM Sans',
  },
].sort((a, b) => a.value.localeCompare(b.value));
