import { ProductBaseFragment } from '@cycle-app/graphql-codegen';

import { makeBooleanVar } from 'src/utils/makeVar.utils';
import { make } from 'src/utils/reactives.util';

interface InitialState {
  products: Array<ProductBaseFragment>;
}

export const {
  hookValue: useGetInitial,
  getValue: getInitial,
  setValue: setInitial,
  resetValue: resetInitial,
} = make<InitialState>({
  defaultState: {
    products: [],
  },
});

export const {
  hook: useInitialLoading,
  get: getInitialLoading,
  setFalse: setInitialLoadingFalse,
  reset: resetInitialLoading,
} = makeBooleanVar(true);