import { CreateReleaseNotePosition, DocBaseFragment } from '@cycle-app/graphql-codegen';
import { DownIcon, ReleaseNoteIcon } from '@cycle-app/ui/icons';
import { AnimatePresence, motion } from 'framer-motion';
import { useReducer, useState } from 'react';

import { DocReleaseNote } from 'src/components/DocReleaseNote';
import { ReleaseEditModal } from 'src/components/ReleaseModals';
import { ReleaseNoteEditor } from 'src/components/ReleaseNote/ReleaseNoteEditor';
import { ReleaseNoteEditorTitle } from 'src/components/ReleaseNote/ReleaseNoteEditorTitle';
import { useCreateReleaseNote } from 'src/hooks/releases/useCreateReleaseNote';
import { useGetPermission } from 'src/reactives';

import {
  Card, Header, Toggle, SectionTitle, NoteTitle, EditorContainer, EditorHeader,
} from './DocReleaseNoteSection.styles';
import { useReleaseNote } from '../../hooks/releases/useReleaseNote';

export const DocReleaseNoteSection = ({
  releaseNote, docId,
}: {
  releaseNote: DocBaseFragment['releaseNote'];
  docId: string;
}) => {
  const { canUpdateReleaseNote } = useGetPermission();
  const { isReadonly } = useReleaseNote(releaseNote?.id);
  const [isExpanded, toggleExpanded] = useReducer(b => !b, true);
  const [isAnimationComplete, setIsAnimationComplete] = useState(true);
  const showSectionTitle = isExpanded || !releaseNote?.title?.trim();
  const {
    createReleaseNote, isCreatingReleaseNote,
  } = useCreateReleaseNote({ position: CreateReleaseNotePosition.Start });

  const onChange = (releaseId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    createReleaseNote({
      releaseId,
      docId,
      isOther: false,
    });
  };

  return (
    <>
      <Card>
        <Header>
          <Toggle
            onClick={toggleExpanded}
            $isDisabled={isCreatingReleaseNote}
          >
            {releaseNote && <DownIcon size={14} />}

            {!!releaseNote && showSectionTitle && (
              <SectionTitle>
                <ReleaseNoteIcon />
                Release note
              </SectionTitle>
            )}

            {!!releaseNote && !showSectionTitle && (
              <NoteTitle>
                {releaseNote.title}
              </NoteTitle>
            )}
          </Toggle>
          <DocReleaseNote releaseNote={releaseNote} />
        </Header>

        {!!releaseNote && (
          <AnimatePresence initial={false}>
            {isExpanded && (
              <motion.div
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={{
                  visible: { height: 'auto' },
                  hidden: { height: 0 },
                }}
                onAnimationStart={() => setIsAnimationComplete(false)}
                onAnimationComplete={() => setIsAnimationComplete(true)}
              >
                <EditorContainer>
                  <EditorHeader style={{ paddingTop: 0 }}>
                    <ReleaseNoteEditorTitle
                      releaseNoteId={releaseNote.id}
                      isReadOnly={isReadonly || !canUpdateReleaseNote}
                    />
                  </EditorHeader>
                  <ReleaseNoteEditor
                    releaseNoteId={releaseNote.id}
                    isReadOnly={isReadonly || !canUpdateReleaseNote}
                    isEnabled={isAnimationComplete}
                  />
                </EditorContainer>
              </motion.div>
            )}
          </AnimatePresence>
        )}
      </Card>

      <ReleaseEditModal onReleaseCreated={onChange} />
    </>
  );
};
