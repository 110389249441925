import { useIsChangelogOnly } from 'src/contexts/workspaceContext';
import { useIsOffline, useLocationSelector } from 'src/hooks';
import { PageId } from 'src/hooks/usePageId';
import { useIsMobile } from 'src/reactives';
import { useGetNewVersion } from 'src/reactives/newVersion.reactive';
import { getPageIdFromPathname } from 'src/utils/routing.utils';

export const useIsDropIslandEnabled = () => {
  const isMobile = useIsMobile();
  const isOffline = useIsOffline();
  const { hasNewVersion } = useGetNewVersion();
  const isChangelogOnly = useIsChangelogOnly();

  const isPageWithDropIsland = useLocationSelector(location => {
    const pageId = getPageIdFromPathname(location.pathname);
    return pageId === PageId.Main || pageId === PageId.Inbox || pageId === PageId.InboxView;
  });

  return !isChangelogOnly && isPageWithDropIsland && !isMobile && !isOffline && !hasNewVersion;
};
