import { ContentSection } from '@cycle-app/graphql-codegen';
import { SelectPanel } from '@cycle-app/ui';
import { EditIcon, DownIcon } from '@cycle-app/ui/icons';
import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { twJoin } from 'tailwind-merge';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { PortalModal } from 'src/components/PortalModal';
import { Layer } from 'src/types/layers.types';

import releaseImageDark from './releaseImage.dark.webp';
import releaseImageLight from './releaseImage.light.webp';
import { useChangelogBuilderTheme } from '../ChangelogBuilderThemeProvider';
import { EditableContainer } from '../shared/EditableContainer';
import { useThemeVariant } from '../shared/useThemeVariant';
import { useChangelogBuilderForm } from '../useChangelogBuilderForm';

export function ChangelogBuilderContentRelease() {
  const { watch } = useChangelogBuilderForm();
  const { isDarkTheme } = useChangelogBuilderTheme();
  const [editStyleModalOpen, setEditStyleModalOpen] = useState(false);

  const contentClasses = useThemeVariant(watch('contentSection'));

  return (
    <>
      <EditableContainer actions={[
        {
          label: 'Edit style',
          action: () => { setEditStyleModalOpen(true); },
          icon: <EditIcon />,
        },
      ]}
      >
        <div>
          <h2
            className={twJoin(
              'text-2xl font-extrabold text-[var(--changelog-headers-color)]',
              contentClasses({
                DISCREET: 'text-[32px]',
                CLASSIC: 'text-[24px]',
                SOBER: 'text-[22px]',
                SHOW_OFF: 'text-[46px]',
              }) ?? 'text-[28px]',
            )}
          >
            New bicycle
          </h2>
          <img
            src={isDarkTheme ? releaseImageDark : releaseImageLight}
            className="mt-4 aspect-[4/2] h-auto w-full rounded-xl object-cover"
            loading="lazy"
          />
          <h3
            className={twJoin(
              'mt-10 text-lg font-extrabold text-[var(--changelog-headers-color)]',
              contentClasses({
                DISCREET: 'text-[24px]',
                UNIFORM: 'text-[21px]',
                CLASSIC: 'text-[18px]',
                SOBER: 'text-[18px]',
                SHOW_OFF: 'text-[36px]',
                UNIQUE: 'text-[24px]',
              }),
            )}
          >
            New bicycle cycle
          </h3>
          <p
            className={twJoin(
              'mt-4 text-base text-[var(--changelog-body-color)]',
              contentClasses({
                'UNIFORM': 'text-[18px]/[24px]',
                'SOBER': 'text-[18px]/[24px]',
                'SHOW_OFF': 'text-[22px]/[32px]',
              }) ?? 'text-base',
            )}
          >
            Our new sick feature is a game changer. In the world of efficiency, we bring something new, the “we never know efficiency”.
            Something unpredictable. This feature is quite extraordinary in a way that we actually never know if it’s gonna work or not.
            Because like us, this feature is allowed to have up and downs.
          </p>
        </div>
      </EditableContainer>
      {editStyleModalOpen && <EditStyleModal hide={() => setEditStyleModalOpen(false)} />}
    </>
  );
}

const contentOptions = [
  {
    value: ContentSection.Classic,
    label: 'Classic',
  },
  {
    value: ContentSection.Discreet,
    label: 'Discreet',
  },
  {
    value: ContentSection.ShowOff,
    label: 'Show off',
  },
  {
    value: ContentSection.Sober,
    label: 'Sober',
  },
  {
    value: ContentSection.Uniform,
    label: 'Uniform',
  },
  {
    value: ContentSection.Unique,
    label: 'Unique',
  },
];

function EditStyleModal({ hide }: { hide: VoidFunction }) {
  const {
    getValues, setValue,
  } = useChangelogBuilderForm();
  const {
    handleSubmit, control,
  } = useForm({
    defaultValues: {
      contentSection: getValues('contentSection'),
    },
  });

  const handleFormSubmit = handleSubmit((data) => {
    setValue('contentSection', data.contentSection, { shouldDirty: true });
    hide();
  });

  return (
    <PortalModal hide={hide} className="w-96">
      <form
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          void handleFormSubmit(e);
        }}
      >
        <div className="mb-2 font-medium">Content section</div>
        <Controller
          control={control}
          name="contentSection"
          render={({ field }) => (
            <ToggleDropdown
              withWrapper={false}
              placement="bottom-start"
              layer={Layer.DropdownModal}
              content={(contentProps) => (
                <SelectPanel
                  hideSearch
                  selectedValue={field.value}
                  options={contentOptions}
                  onOptionChange={(payload) => {
                    field.onChange(payload.value);
                    contentProps.hide();
                  }}
                  style={{ width: `${contentProps.buttonRect?.width}px` }}
                />
              )}
              button={(buttonProps) => (
                <button
                  ref={buttonProps.ref}
                  type="button"
                  // eslint-disable-next-line max-len
                  className="group flex w-full cursor-pointer items-center justify-between rounded-lg border border-primary px-3 py-2 text-left hover:bg-grey-100 dark:hover:bg-grey-800"
                  onClick={buttonProps.onClick}
                >
                  {contentOptions.find((option) => option.value === field.value)?.label ?? 'Select'}
                  <DownIcon className="text-secondary group-aria-expanded:rotate-180" />
                </button>
              )}
            />
          )}
        />

        <div className="mt-8 flex items-center justify-end gap-2">
          <button
            type="button"
            className="btn-secondary"
            onClick={hide}
          >
            Discard
          </button>
          <button
            type="submit"
            className="btn-primary"
          >
            Save changes
          </button>
        </div>
      </form>
    </PortalModal>
  );
}
