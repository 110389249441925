import { lazy, Suspense } from 'react';
import { useLocation } from 'react-router-dom';

import { ChangelogBuilderLoader as ChangelogBuilderLoaderV2 } from 'src/app/Changelog/ChangelogBuilder';
import { useChangelog } from 'src/hooks/releases/useChangelog';

import { ChangelogBuilder, ChangelogBuilderLoader } from '../../ChangelogBuilder';
import { ErrorPage } from '../../ErrorPage';

const ChangelogBuilderV2 = lazy(() => import('src/app/Changelog/ChangelogBuilder').then(module => ({ default: module.ChangelogBuilder })));

export const ChangelogView = () => {
  const isV2 = useChangelogV2();
  const {
    changelog, isLoading,
  } = useChangelog();

  if (isV2) {
    if (isLoading) return <ChangelogBuilderLoaderV2 />;
    if (!changelog) return <ErrorPage />;

    return (
      <Suspense fallback={<ChangelogBuilderLoaderV2 />}>
        <ChangelogBuilderV2 changelog={changelog} />
      </Suspense>
    );
  }


  if (isLoading) return <ChangelogBuilderLoader />;

  if (!changelog) return <ErrorPage />;

  return <ChangelogBuilder changelog={changelog} />;
};

function useChangelogV2() {
  const { search } = useLocation();

  if (import.meta.env.VITE_CHANGELOG_V2 === 'on') {
    return true;
  }

  const searchParams = new URLSearchParams(search);
  return searchParams.get('v2') === 'on';
}
