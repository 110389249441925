import { ReleasePublicStatus } from '@cycle-app/graphql-codegen';
import { CheckCircleIcon } from '@cycle-app/ui/icons';

import { useReleasesContext } from 'src/contexts/releasesContext';
import { useChangelog } from 'src/hooks/releases/useChangelog';
import { useReleasePublicStatusUpdate } from 'src/hooks/releases/useReleasePublicStatusUpdate';
import { openPublishRelease } from 'src/reactives/releases.reactive';

import { StyledActionButton } from './ReleaseStatus.styles';

type Props = {
  publicStatus: ReleasePublicStatus;
  releaseId: string;
};

export const ReleaseStatus = ({
  publicStatus, releaseId,
}: Props) => {
  const {
    publish, isPublishLoading,
  } = useReleasePublicStatusUpdate(releaseId);
  const { publishedCount } = useReleasesContext();
  const { changelog } = useChangelog();

  async function onClick() {
    if (publicStatus !== ReleasePublicStatus.Published) {
      if (publishedCount === 0 && !changelog?.isPublished) {
        openPublishRelease(releaseId)();
        return;
      }
      await publish();
    }
  }

  if (publicStatus === ReleasePublicStatus.Published) return null;

  return (
    <StyledActionButton
      isLoading={isPublishLoading}
      onClick={onClick}
      $publicStatus={publicStatus}
      size="L"
      tooltipPlacement="top"
      tooltip={publicStatus === ReleasePublicStatus.Unpublished ? 'Publish release' : 'Publish changes'}
    >
      <CheckCircleIcon />
    </StyledActionButton>
  );
};
