import { Icon } from '@cycle-app/ui';
import {
  LinearIcon,
  JiraIcon,
  JiraProductDiscoveryIcon,
  GitLabIcon,
  ShortcutAppIcon,
  AsanaIcon,
  ClickUpIcon,
  MondayIcon,
} from '@cycle-app/ui/icons';

export const integrations = [
  {
    id: 'linear',
    icon: <LinearIcon />,
    label: 'Linear',
  },
  {
    id: 'jira',
    icon: <JiraIcon />,
    label: 'Jira',
  },
  {
    id: 'jira-product-discovery',
    icon: <JiraProductDiscoveryIcon />,
    label: 'Jira Product Discovery',
  },
  {
    id: 'github',
    icon: <Icon name="brand/github" className="size-4" />,
    label: 'Github',
  },
  {
    id: 'notion',
    icon: <Icon name="brand/notion" className="size-4" />,
    label: 'Notion',
  },
  {
    id: 'gitlab',
    icon: <GitLabIcon />,
    label: 'Gitlab',
  },
  {
    id: 'shortcut',
    icon: <ShortcutAppIcon />,
    label: 'Shortcut',
  },
  {
    id: 'asana',
    icon: <AsanaIcon />,
    label: 'Asana',
  },
  {
    id: 'clickup',
    icon: <ClickUpIcon />,
    label: 'ClickUp',
  },
  {
    id: 'monday',
    icon: <MondayIcon />,
    label: 'Monday',
  },
  {
    id: 'airtable',
    icon: <Icon name="brand/airtable" className="size-4" />,
    label: 'Airtable',
  },
];

export const others = integrations.slice(5);

export const sliderItems = [
  {
    id: 'github',
    icon: <Icon name="brand/github" className="size-4" />,
    label: 'Github',
  },
  {
    id: 'jira',
    icon: <JiraIcon />,
    label: 'Jira',
  },
  {
    id: 'linear',
    icon: <LinearIcon />,
    label: 'Linear',
  },
  {
    id: 'notion',
    icon: <Icon name="brand/notion" className="size-4" />,
    label: 'Notion',
  },
  {
    id: 'jira-product-discovery',
    icon: <JiraProductDiscoveryIcon />,
    label: 'Jira Product Discovery',
  },
  {
    id: 'gitlab',
    icon: <GitLabIcon />,
    label: 'Gitlab',
  },
  {
    id: 'shortcut',
    icon: <ShortcutAppIcon />,
    label: 'Shortcut',
  },
];
