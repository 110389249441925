import { useQuery } from '@apollo/client';
import { FetchSlackInfoDocument } from '@cycle-app/graphql-codegen';

import { useSlackIntegration } from './useSlackIntegration';

export const useSlackWorkspace = () => {
  const { integration } = useSlackIntegration();

  const result = useQuery(FetchSlackInfoDocument, {
    skip: !integration?.id,
    variables: {
      integrationId: integration?.id as string,
    },
  });

  // We handle only one ws for now.
  return {
    isLoading: result.loading,
    name: result.data?.slackInfo?.name,
    logo: result.data?.slackInfo?.logo,
    url: result.data?.slackInfo?.url,
  };
};
