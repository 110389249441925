import { QueryHookOptions, useQuery, useApolloClient } from '@apollo/client';
import { ProductReleasesDocument, ProductReleasesQuery } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { RELEASES_NEXT_PAGINATION_SIZE, RELEASES_INITIAL_PAGINATION_SIZE } from 'src/constants/releases.constants';
import { PageId, routing } from 'src/constants/routing.constant';
import { useRouteMatch } from 'src/hooks';
import { extract } from 'src/types/graphql.types';

import { useIsReleasesEnabled } from './useIsReleasesEnabled';

const useReleases = (
  productId?: string,
  options?: Partial<QueryHookOptions<ProductReleasesQuery>>,
) => useQuery(ProductReleasesDocument, {
  skip: !productId || options?.skip,
  onCompleted: options?.onCompleted,
  variables: getInitialReleasesVariables(productId as string),
});

export const usePaginatedReleases = (
  productId?: string,
  options?: Partial<QueryHookOptions<ProductReleasesQuery>>,
) => {
  const query = useReleases(productId, options);

  const data = extract('Product', query.data?.node)?.releases;
  const pageInfo = data?.pageInfo;
  const hasNextPage = pageInfo?.hasNextPage ?? false;
  const endCursor = pageInfo?.endCursor ?? '';

  const releasesList = useMemo(() => nodeToArray(data), [data]);

  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const loadMore = useCallback(async () => {
    if (!hasNextPage || !endCursor) return;
    setIsLoadingMore(true);
    await query.fetchMore({
      variables: {
        cursor: endCursor,
        size: RELEASES_NEXT_PAGINATION_SIZE,
      },
    });
    setIsLoadingMore(false);
  }, [endCursor, hasNextPage, query]);

  return {
    releasesList,
    isLoading: query.loading,
    isLoadingMore,
    hasNextPage,
    endCursor,
    loadMore,
  };
};

export const usePrefetchReleases = (productId?: string) => {
  const client = useApolloClient();
  const matchReleases = !!useRouteMatch(routing[PageId.Releases]);
  const isReleasesEnabled = useIsReleasesEnabled();

  useEffect(() => {
    if (!matchReleases && productId && isReleasesEnabled) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      client.query({
        query: ProductReleasesDocument,
        variables: getInitialReleasesVariables(productId),
      });
    }
  }, [client, isReleasesEnabled, matchReleases, productId]);
};

export const getInitialReleasesVariables = (productId: string) => ({
  productId,
  cursor: '',
  size: RELEASES_INITIAL_PAGINATION_SIZE,
});
