import { QueryFunctionOptions, useQuery } from '@apollo/client';
import { ProductsDocument, ProductsQuery } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo, useCallback } from 'react';

import { PRODUCTS_PAGINATION_SIZE } from 'src/constants/products.constants';
import { useGetAuth } from 'src/reactives/auth.reactive';
import { extract } from 'src/types/graphql.types';

import { useMaybeMeV2 } from './useMe';

type Props = {
  searchText?: string;
  skip?: boolean;
  onCompleted?: QueryFunctionOptions<ProductsQuery>['onCompleted'];
};

export const useProducts = ({
  searchText, skip, onCompleted,
}: Props = {}) => {
  const { userId } = useGetAuth();
  const { me } = useMaybeMeV2();

  const {
    data, previousData, loading, fetchMore,
  } = useQuery(ProductsDocument, {
    fetchPolicy: 'cache-first',
    skip: skip || !userId || !me,
    variables: {
      userId: userId as string,
      size: PRODUCTS_PAGINATION_SIZE,
      cursor: searchText ? '' : (me?.products.pageInfo.endCursor ?? ''),
      searchText: searchText || undefined,
    },
    onCompleted,
  });

  const productsConnection = loading ? extract('Me', previousData?.node)?.products : extract('Me', data?.node)?.products;
  const endCursor = productsConnection?.pageInfo?.endCursor;
  const hasNextPage = productsConnection?.pageInfo?.hasNextPage ?? false;

  const products = useMemo(() => nodeToArray(productsConnection), [productsConnection]);

  const fetchNextPage = useCallback(async () => {
    if (!hasNextPage) return;
    await fetchMore({
      variables: {
        cursor: endCursor,
      },
    });
  }, [fetchMore, endCursor, hasNextPage]);

  return {
    products,
    loading,
    fetchNextPage,
    hasNextPage,
  };
};
