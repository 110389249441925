import { useSubscription } from '@apollo/client';
import {
  DocChildrenSubscription,
  DocChildrenDocument,
  DocChildrenSubscriptionVariables,
} from '@cycle-app/graphql-codegen';

import { useUpdateChildCache } from './cache/cacheHierarchy';

export const useDocChildrenSubscription = (parentDocId?: string | null) => {
  const updateDocChild = useUpdateChildCache();
  useSubscription<DocChildrenSubscription, DocChildrenSubscriptionVariables>(
    DocChildrenDocument,
    {
      variables: {
        parentDocId: parentDocId as string,
      },
      onData: async ({ data: { data } }) => {
        if (!parentDocId || !data) return;

        updateDocChild({
          newParentId: parentDocId,
          docs: [{
            docId: data?.docChildren.child.id,
            parentId: data?.docChildren.child.parent?.id,
          }],
        });
      },
    },
  );
};
