import { RefObject } from 'react';
import { useInView } from 'react-intersection-observer';

import { useDocV2 } from 'src/hooks';

import { Doc } from './Ask.types';
import { QuoteCard } from '../CustomerCompanyProfile/QuoteCard';

type Props = {
  doc: Doc;
  rootRef: RefObject<HTMLDivElement>;
};

export const AnswerDoc = ({
  doc, rootRef,
}: Props) => {
  // The ask subscription already includes the fragment in `DocNode` query,
  // so there should not be loading/request here.
  const data = useDocV2(doc.id);

  const {
    ref, inView,
  } = useInView({
    root: rootRef.current,
    rootMargin: '100px',
  });

  if (!data.doc) return null;

  return (
    <div ref={ref}>
      {inView ? (
        <QuoteCard
          doc={data.doc}
          showCustomer
        />
      ) : (
        <div
          className="h-32 flex-none rounded-lg border border-grey-300 dark:border-grey-800"
        />
      )}
    </div>
  );
};
