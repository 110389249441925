import { Spinner } from '@cycle-app/ui';
import { ReleaseNoteIcon } from '@cycle-app/ui/icons';

import { Container, Content } from './EditorTranscriptStatusState.styles';

type EditorTranscriptStatusStateProps = {
  errorMessage?: string;
  className?: string;
  loadingText?: string;
};

export const EditorTranscriptStatusState = ({
  errorMessage,
  loadingText = 'Generating transcript…',
  className,
}:EditorTranscriptStatusStateProps) => {
  return (
    <Container className={className}>
      <Content>
        {errorMessage ? '🧐 ' : <ReleaseNoteIcon />}
        {errorMessage || loadingText}
        {!errorMessage && <Spinner />}
      </Content>
    </Container>
  );
};
