import {
  DiscardRecommendationsDocument,
  DiscardRecommendationsMutationVariables,
  SearchSuggestionDocTargetsDocument,
} from '@cycle-app/graphql-codegen';
import { produce } from 'immer';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { useInsightDocType } from 'src/reactives/docTypes.reactive';
import { useSuggestionStatusIds } from 'src/reactives/productStatus.reactive';

export const useDiscardRecommendations = (docId: string) => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const statusIds = useSuggestionStatusIds();
  const insightDoctypeId = useInsightDocType()?.id;

  const [mutate] = useSafeMutation(DiscardRecommendationsDocument);

  const discardRecommendations = async (variables: DiscardRecommendationsMutationVariables) => mutate({
    variables,
    optimisticResponse: {
      discardRecommendations: {
        __typename: 'Doc',
        id: variables.docId,
      },
    },
    update: (cache, { data }) => {
      if (!data?.discardRecommendations) return;

      const recommendationIds = variables.docRecommendationIds;
      if (!Array.isArray(recommendationIds)) return;

      // Remove the discarded recommendations from the list of recommendations
      cache.updateQuery({
        query: SearchSuggestionDocTargetsDocument,
        variables: {
          productId,
          docId,
          statusIds,
          doctypeIds: [insightDoctypeId as string],
          hasParent: false,
        },
      }, prev => produce(prev, draft => {
        if (!draft?.suggestDoc) return;
        // eslint-disable-next-line no-param-reassign
        draft.suggestDoc.edges = draft.suggestDoc.edges.filter(
          edge => !recommendationIds.some(id => id === edge.node.id),
        );
      }));
    },
  });

  return { discardRecommendations };
};
