import type { LucideIconName, IconNameOutput } from '../../types/icon.types';

export const getLucideIconName = (string: string) => {
  if (string.startsWith('lucide_')) {
    return string.replace('lucide_', '') as LucideIconName;
  }
  return null;
};

export const getIconNameOutput = (iconName: string) => {
  return `lucide_${iconName}` as IconNameOutput;
};
