import { SubscriptionHookOptions, useSubscription } from '@apollo/client';
import {
  QuotesDocument,
  QuotesSubscription,
  QuotesSubscriptionVariables,
} from '@cycle-app/graphql-codegen';

export const useDocQuotesSubscription = (
  feedbackId?: string | null,
  options?: Pick<SubscriptionHookOptions<QuotesSubscription>, 'onData'>,
) => {
  return useSubscription<QuotesSubscription, QuotesSubscriptionVariables>(
    QuotesDocument,
    {
      ...options,
      variables: {
        feedbackId: feedbackId as string,
      },
    },
  );
};
