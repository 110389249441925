import { useQuery } from '@apollo/client';
import { FetchGithubProjectsDocument, IntegrationType, GithubStatusFragment } from '@cycle-app/graphql-codegen';
import { useMemo } from 'react';
import { isPresent } from 'ts-is-present';

import { useProductIntegrations } from 'src/hooks/api/useProductIntegrations';

const BUILT_IN_GITHUB_PROJECT_STATUS_NAME = 'Status';

type ProjectId = string;
type StatusesByProjects = Record<ProjectId, GithubStatusFragment>;

export const useGithubProject = () => {
  const { getIntegration } = useProductIntegrations();
  const integration = getIntegration(IntegrationType.Github);
  const {
    data, loading,
  } = useQuery(FetchGithubProjectsDocument, {
    skip: !integration?.id,
    variables: {
      integrationId: integration?.id as string,
    },
  });

  const projects = useMemo(
    () => {
      if (
        data?.node?.__typename !== 'Integration' ||
        data?.node.provider?.__typename !== 'Github' ||
        !data?.node?.provider?.projects
      ) return null;

      return data.node.provider.projects;
    },
    [data],
  );

  const statusesByProjects = useMemo(() => {
    if (!projects || !projects.length) return null;

    return projects.reduce<StatusesByProjects>((previousValue, currentValue) => {
      if (
        !currentValue?.id ||
        previousValue[currentValue.id] ||
        !currentValue.status
      ) return previousValue;

      return {
        ...previousValue,
        [currentValue.id]: currentValue.status,
      };
    }, {});
  }, [projects]);

  /**
   * Correspond to the project setup in integration settings
   * This changed on end of April 2023, we know have all project listed from
   * GitHub API, we keep this logic in order to make sure GitHub Issues
   * created before that still works
   */
  const project = useMemo(
    () => {
      if (
        data?.node?.__typename !== 'Integration' ||
        data?.node.provider?.__typename !== 'Github' ||
        !data?.node?.provider?.project
      ) return null;

      return data.node.provider.project;
    },
    [data],
  );

  const statusOptions = useMemo(() => {
    if (
      !project ||
      project.status?.name !== BUILT_IN_GITHUB_PROJECT_STATUS_NAME ||
      !project.status.options
    ) return null;

    return project.status.options.filter(isPresent);
  }, [project]);

  return {
    isLoading: loading,
    project,
    projects,
    /**
     * Status Option will be use to support old single GtiHub project, this
     * will make sure legacy workspace having a project in GitHub integrations
     * config will still show the status
     */
    statusOptions,
    statusesByProjects,
  };
};
