import { ActionButton, Button } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';

import { useDocPanelContext } from 'src/contexts/docPanelContext';
import { useDiscardQuote } from 'src/hooks/api/mutations/quotes/useDiscardQuote';
import { useVerifyQuotes } from 'src/hooks/api/mutations/quotes/useVerifyQuotes';


import { QuoteContentEditable } from './QuoteContentEditable';
import { QuoteFeatureDropdown } from './QuoteFeatureDropdown';
import { Modal, Footer, Header, Title, Quote } from './VerifyQuoteModal.styles';
import { FeatureCardAttributes } from '../QuoteCards/FeatureCardAttributes';

type VerifyQuoteModalProps = {
  docId: string;
  quoteId: string;
  hide: VoidFunction;
};

export const VerifyQuoteModal = ({
  docId, quoteId, hide,
}: VerifyQuoteModalProps) => {
  const discardMutation = useDiscardQuote();
  const verifyMutation = useVerifyQuotes();

  const quote = useDocPanelContext(ctx => ctx.doc?.quotes.edges.find(edge => edge.node?.id === quoteId)?.node);
  if (!quote) return null;

  return (
    <Modal hide={hide}>
      <Header>
        <Title>Verify AI-generated quote</Title>
        <ActionButton onClick={hide}>
          <CloseIcon />
        </ActionButton>
      </Header>

      <Quote>
        <QuoteFeatureDropdown quote={quote} />
        
        {quote.parent && (
          <FeatureCardAttributes
            doc={quote.parent}
            showDoctype={false}
          />
        )}

        <QuoteContentEditable quote={quote} />
      </Quote>

      <Footer>
        <Button
          size="M"
          variant="secondary"
          onClick={() => {
            discardMutation.discard(docId, quoteId);
            hide();
          }}
        >
          Discard
        </Button>

        <Button
          size="M"
          onClick={async () => {
            await verifyMutation.verifyQuotes(docId, [quoteId]);
            hide();
          }}
        >
          Verify
        </Button>
      </Footer>
    </Modal>
  );
};
