import { Color, QuoteFragment, UpdateQuoteDocument } from '@cycle-app/graphql-codegen';
import { Tooltip } from '@cycle-app/ui';
import { AiIcon, ArrowRightUpIcon } from '@cycle-app/ui/icons';
import { getDocSlug } from '@cycle-app/utilities';
import uniq from 'lodash/uniq';
import { useState } from 'react';
import { isPresent } from 'ts-is-present';

import { DocParentPanel } from 'src/components/DocParentDropdown/DocParentPanel';
import { DocTypeIcon } from 'src/components/DocTypeIcon';
import { ToggleDropdown } from 'src/components/DropdownLayer';
import { PageId } from 'src/constants/routing.constant';
import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { navigateToDocFullPage, useSafeMutation } from 'src/hooks';
import { getDocType, getInsightDocType, useInsightParentDocTypeIds } from 'src/reactives/docTypes.reactive';
import { Layer } from 'src/types/layers.types';
import { getDocResultFromCache } from 'src/utils/cache.utils';
import { getUrl } from 'src/utils/routing.utils';

import { EditQuoteFeatureTitle } from './EditQuoteFeatureTitle';
import { QuoteFeatureOptions } from './QuoteFeatureOptions';
import {
  ParentLabel, ParentLink, ParentButtonContainer, ParentText, StyledAddIcon, ParentOptionsButton,
} from './VerifyQuoteModal.styles';

type Props = {
  quote: QuoteFragment;
};

export const QuoteFeatureDropdown = ({ quote }: Props) => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const [isEditing, setIsEditing] = useState(false);
  const [updateQuote] = useSafeMutation(UpdateQuoteDocument);
  const parentDocTypeIds = useInsightParentDocTypeIds();

  // The suggested feature type could be unlinked from feedback
  const suggestedParentDoctypeId = quote?.suggestedParentDoctype?.id && parentDocTypeIds.includes(quote.suggestedParentDoctype.id)
    ? quote.suggestedParentDoctype.id
    : null;

  const possibleDoctypeIds = uniq([
    quote?.parent?.doctype.id,
    suggestedParentDoctypeId,
    ...parentDocTypeIds,
  ].filter(isPresent));

  const insightDocType = getInsightDocType();
  if (!insightDocType) return null;

  const doctypeName = getDocType(possibleDoctypeIds[0])?.name.toLowerCase() ?? 'feature';

  // Possible doctype names separated by commas and "or" for the last one
  const possibleDoctypeNames = possibleDoctypeIds
    .reduce((acc, id, index) => {
      const name = getDocType(id)?.name.toLowerCase();
      if (!name) return acc;
      if (index === 0) return name;
      if (index === possibleDoctypeIds.length - 1) return `${acc} or ${name}`;
      return `${acc}, ${name}`;
    }, '');

  if (isEditing && quote?.parent) {
    return (
      <EditQuoteFeatureTitle
        parentId={quote.parent.id}
        defaultValue={quote.parent.title}
        hide={() => setIsEditing(false)}
      />
    );
  }

  return (
    <ToggleDropdown
      withPortal
      withWrapper={false}
      layer={Layer.DropdownModalZ1}
      placement="bottom-start"
      content={props => (
        <DocParentPanel
          docType={insightDocType}
          placeholder={`Link to ${possibleDoctypeNames}`}
          hide={props.hide}
          onChange={parentId => {
            const docResult = parentId ? getDocResultFromCache(parentId) : null;
            const docType = getDocType(docResult?.doctype.id);
            if (!parentId || !docResult || !docType) return;
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            updateQuote({
              variables: {
                id: quote?.id,
                parentId,
              },
              // Only the doctype id is useful in the optimistic response to immediately start the animation
              // when a parent changes category in the verification modal.
              optimisticResponse: {
                updateQuote: {
                  ...quote,
                  parent: {
                    ...docResult,
                    doctype: {
                      id: docType.id,
                      type: docType.type,
                    },
                    status: null,
                    isDraft: false,
                    childrenCount: 0,
                    insightsCount: 0,
                    docTargetsCount: 0,
                    docTargetsAiCount: 0,
                    quotesCount: 0,
                    threadsCount: 0,
                    threads: {
                      edges: [],
                    },
                    aiState: null,
                    assignee: null,
                    attributes: {
                      edges: [],
                    },
                    createdAt: new Date().toISOString(),
                    creator: {
                      id: '',
                      email: '',
                      role: null,
                      color: Color.A,
                    },
                    productAreas: {
                      edges: [],
                    },
                  },
                },
              },
            });
          }}
          showNoneOption={false}
          possibleDoctypeIds={possibleDoctypeIds}
          suggestedParentName={quote?.suggestedParentName}
          searchVariables={{
            doctypeIds: possibleDoctypeIds,
            childDoctypeId: null,
          }}
          searchSuggestionsVariables={{
            productId,
            text: quote.content,
            doctypeIds: possibleDoctypeIds[0],
            childDoctypeId: null,
          }}
          recentSearchVariables={{
            doctypeIds: possibleDoctypeIds[0],
            childDoctypeId: null,
          }}
        />
      )}
      button={props => (
        <ParentButtonContainer
          $active={props['data-active']}
        >
          <Tooltip
            withPortal
            withWrapper={false}
            placement="top"
            content={`Change ${doctypeName}`}
            disabled={!quote?.parent}
          >
            <ParentLink
              href={quote?.parent
                ? getUrl(PageId.DocFullPage, { docSlug: getDocSlug(quote.parent) })
                : undefined}
              onClick={e => {
                if (e.metaKey) return;
                e.preventDefault();
                props.toggle();
              }}
            >
              {quote?.parent ? (
                <ParentLabel>
                  <DocTypeIcon
                    size={12}
                    doctype={getDocType(quote.parent.doctype.id)}
                  />
                  <ParentText>{quote.parent.title}</ParentText>
                </ParentLabel>
              ) : (
                <ParentLabel>
                  <StyledAddIcon size={12} />
                  <ParentText $gradient>{`Link to recommended ${doctypeName}`}</ParentText>
                  <AiIcon hasGradient size={14} />
                </ParentLabel>
              )}
            </ParentLink>
          </Tooltip>

          {quote.parent && (
            <>
              <ParentOptionsButton
                tooltip="Open feature"
                tooltipPlacement="top"
                onClick={() => {
                  if (!quote.parent) return;
                  navigateToDocFullPage(quote.parent, undefined, true);
                }}
              >
                <ArrowRightUpIcon size={12} />
              </ParentOptionsButton>

              <QuoteFeatureOptions
                quote={quote}
                onSelectEdit={() => setIsEditing(true)}
              />
            </>
          )}
        </ParentButtonContainer>
      )}
    />
  );
};
