import { useSubscription } from '@apollo/client';
import {
  AllNotificationReadSubscription,
  AllNotificationReadDocument,
  AllNotificationReadSubscriptionVariables,
} from '@cycle-app/graphql-codegen';

export const useAllNotificationReadSubscription = (productId?: string | null) => useSubscription<
AllNotificationReadSubscription,
AllNotificationReadSubscriptionVariables
>(
  AllNotificationReadDocument,
  {
    variables: {
      productId: productId as string,
    },
    onData: ({ client }) => {
      client.cache.modify({
        id: productId ?? undefined,
        fields: {
          notificationsNotRead: () => 0,
        },
      });
    },
  },
);
