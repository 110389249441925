import { gql, Reference } from '@apollo/client';
import {
  Color as ColorApi,
  RemoveCustomAttributeDefinitionDocument,
  ChangeAttributeColorDocument,
  ChangeAttributeNameDocument,
  AddSelectStringAttributeValueDocument,
  RemoveSelectStringAttributeValueDocument,
  ChangeSelectStringAttributeValueDocument,
  AddAttributeDefinitionDoctypeDocument,
  RemoveAttributeDoctypeDocument,
  ProductNodeDocument,
  namedOperations,
  MoveSelectAttributeValueInValuesListDocument,
  ListPositionInput,
  AddNewAttributeDocument,
  AttributeDefinitionInput,
  UpdateAttributeDocument,
  UpdateAttributeMutationVariables,
} from '@cycle-app/graphql-codegen';
import { produce } from 'immer';
import { useCallback } from 'react';

import { Events } from 'src/constants/analytics.constants';
import { useGetAttributeDefinitionFromCache } from 'src/hooks/api/cache/cacheAttributeDefinition';
import { useGetBoardConfigFromCache } from 'src/hooks/api/cache/cacheBoardConfig';
import { useGetDoctypeFromCache } from 'src/hooks/api/cache/cacheDoctype';
import { useProduct } from 'src/hooks/api/useProduct';
import { useProductDoctypes } from 'src/hooks/api/useProductDoctypes';
import { useLoader } from 'src/hooks/useLoader';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { AttributeDefinitionsNode } from 'src/types/attribute.types';
import { FullDocWithPublicId } from 'src/types/doc.types';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { refetchBoardWithConfigQuery } from 'src/utils/boardConfig/boardConfig.util';
import { getParams } from 'src/utils/routing.utils';
import { addAttributeTextValue } from 'src/utils/update-cache/attributes-cache.util';

export interface MoveSelectAttributeValueParams {
  attributeId: string;
  valueId: string;
  position: ListPositionInput;
  sortedItems: Array<string>;
  boardConfigId?: string;
}

export default function useAttributesMutations() {
  const { product } = useProduct();
  const doctypes = useProductDoctypes();
  const getDoctype = useGetDoctypeFromCache();
  const getAttribute = useGetAttributeDefinitionFromCache();
  const getBoardConfig = useGetBoardConfigFromCache();

  const [removeAttributeMutation, { loading: loadingRemoveAttribute }] = useSafeMutation(RemoveCustomAttributeDefinitionDocument, {
    onCompleted: () => trackAnalytics(Events.AttributeDeleted),
  });
  const [changeAttributeColorMutation, { loading: loadingUpdateColor }] = useSafeMutation(ChangeAttributeColorDocument, {
    onCompleted: () => trackAnalytics(Events.AttributeUpdated),
  });
  const [changeAttributeNameMutation, { loading: loadingUpdateName }] = useSafeMutation(ChangeAttributeNameDocument, {
    onCompleted: () => trackAnalytics(Events.AttributeUpdated),
  });
  const [addSelectOptionMutation, { loading: loadingAddSelectOption }] = useSafeMutation(AddSelectStringAttributeValueDocument, {
    onCompleted: (data) => trackAnalytics(Events.AttributeValueCreated, { name: data.addSelectStringAttributeValue?.value ?? '' }),
  });
  const [removeSelectOptionMutation, { loading: loadingRemoveSelectOption }] = useSafeMutation(RemoveSelectStringAttributeValueDocument, {
    onCompleted: () => trackAnalytics(Events.AttributeValueDeleted),
  });
  const [changeSelectOptionMutation, { loading: loadingChangeSelectOption }] = useSafeMutation(ChangeSelectStringAttributeValueDocument, {
    onCompleted: () => trackAnalytics(Events.AttributeValueUpdated),
  });
  const [addAttributeDoctypeMutation, { loading: loadingAddAttributeDoctype }] = useSafeMutation(AddAttributeDefinitionDoctypeDocument, {
    onCompleted: () => trackAnalytics(Events.AttributeDoctypeCreated),
  });
  const [removeAttributeDoctypeMutation, { loading: loadingRemoveAttributeDoctype }] = useSafeMutation(RemoveAttributeDoctypeDocument, {
    onCompleted: () => trackAnalytics(Events.AttributeDoctypeDeleted),
  });
  const [
    moveSelectAttributeValueInValuesListMutation,
    { loading: loadingMoveSelectAttributeValueInValuesList },
  ] = useSafeMutation(MoveSelectAttributeValueInValuesListDocument);
  const [updateAttributeMutation, { loading: loadingUpdateAttribute }] = useSafeMutation(UpdateAttributeDocument, {
    onCompleted: () => trackAnalytics(Events.AttributeUpdated),
  });

  const [addNewAttributeMutation, { loading: loadingAddNewAttribute }] = useSafeMutation(AddNewAttributeDocument, {
    update: (cache, { data }) => {
      if (!data?.addNewAttribute || !product) return;

      const newAttributeRef = cache.writeFragment({
        data: data.addNewAttribute,
        fragment: gql`
            fragment NewAttribute on CustomAttributeDefinition {
              id
            }
          `,
      });

      if (!newAttributeRef) return;

      cache.modify({
        id: cache.identify(product),
        fields: {
          attributeDefinitions: (attributeDefinitions) => ({
            ...attributeDefinitions,
            edges: attributeDefinitions.edges.concat({
              __typename: 'CustomAttributeDefinitionEdge',
              node: newAttributeRef,
            }),
          }),
        },
      });
    },
    onCompleted: ({ addNewAttribute: newAttribute }) => {
      if (newAttribute) {
        trackAnalytics(Events.AttributeCreated);
      }
    },
  });

  const addNewAttribute = (data: Omit<AttributeDefinitionInput, 'productId'>) => product && addNewAttributeMutation({
    variables: {
      input: {
        ...data,
        productId: product.id,
      },
    },
    errorPolicy: 'all',
  });

  const updateAttribute = (data: UpdateAttributeMutationVariables) => updateAttributeMutation({
    variables: data,
    errorPolicy: 'all',
  });

  const loading =
    loadingRemoveAttribute ||
    loadingUpdateColor ||
    loadingUpdateName ||
    loadingAddSelectOption ||
    loadingRemoveSelectOption ||
    loadingChangeSelectOption ||
    loadingAddAttributeDoctype ||
    loadingRemoveAttributeDoctype ||
    loadingMoveSelectAttributeValueInValuesList ||
    loadingAddNewAttribute ||
    loadingUpdateAttribute;

  useLoader({ loading });

  const addAttributeDoctype = useCallback((attribute: AttributeDefinitionsNode, doctypeId: string) => {
    const addedDoctype = doctypes.find(({ id }) => id === doctypeId);

    return addAttributeDoctypeMutation({
      variables: {
        attributeDefinitionId: attribute.id,
        doctypeId,
      },
      ...(addedDoctype && {
        optimisticResponse: {
          addAttributeDefinitionDoctype: {
            ...attribute,
            doctypes: {
              ...attribute.doctypes,
              edges: attribute.doctypes.edges.concat({
                __typename: 'DoctypeEdge',
                node: {
                  ...addedDoctype,
                },
              }),
            },
          },
        },
      }),
      update(cache, { data }) {
        if (!data?.addAttributeDefinitionDoctype || !product) return;
        const updatedAttribute = data.addAttributeDefinitionDoctype;

        const newAttributeRef = cache.writeFragment({
          data: updatedAttribute,
          fragment: gql`
            fragment NewAttribute on CustomAttributeDefinition {
              id
            }
          `,
        });

        if (!newAttributeRef) return;

        // Updating product attributeDefinitions
        cache.modify({
          id: cache.identify(product),
          fields: {
            attributeDefinitions: (productAttributeDefinitions, { readField }) => ({
              ...productAttributeDefinitions,
              edges: productAttributeDefinitions.edges.map(({ node: nodeRef }: { node: Reference }) => {
                if (readField('id', nodeRef) === attribute.id) {
                  return { node: newAttributeRef };
                }
                return { node: nodeRef };
              }),
            }),
          },
        });

        // Updating doctype attributeDefinitions
        cache.modify({
          id: doctypeId,
          fields: {
            attributeDefinitions: (doctypeAttributeDefinitions, { readField }) => {
              // Safety check to see if the attribute is already in that doctype's attributeDefinitions
              if (doctypeAttributeDefinitions.edges.some(
                (nodeRef: Reference) => readField('id', nodeRef) === attribute.id,
              )) {
                return doctypeAttributeDefinitions;
              }

              return ({
                ...doctypeAttributeDefinitions,
                edges: doctypeAttributeDefinitions.edges.concat({
                  __typename: 'CustomAttributeDefinitionEdge',
                  node: newAttributeRef,
                }),
              });
            },
          },
        });
      },
      refetchQueries: refetchBoardWithConfigQuery,
    });
  }, [addAttributeDoctypeMutation, doctypes, product]);

  return {
    loading,
    removeAttribute,
    changeAttributeColor,
    changeAttributeName,
    addSelectOption,
    removeSelectOption,
    changeSelectOption,
    addAttributeDoctype,
    removeAttributeDoctype,
    moveSelectAttributeValue,
    loadingAddSelectOption,
    loadingChangeSelectOption,
    addNewAttribute,
    updateAttribute,
  };

  function removeAttribute(attributeId: string) {
    return removeAttributeMutation({
      variables: {
        attributeId,
      },
      update(cache, { data }) {
        if (!data?.removeCustomAttributeDefinition || !product) return;
        const deletedAttribute = data.removeCustomAttributeDefinition;

        if (!product?.id) return;
        cache.updateQuery({
          query: ProductNodeDocument,
          variables: { productId: product.id },
        }, prev => produce(prev, draft => {
          if (draft?.node?.__typename !== 'Product') return;

          draft.node.attributeDefinitions.edges =
              draft.node.attributeDefinitions.edges.filter(({ node: attributeNode }) => attributeNode.id !== deletedAttribute.id);

          for (const doctype of draft.node.doctypes.edges) {
            doctype.node.attributeDefinitions.edges =
                doctype.node.attributeDefinitions.edges.filter(({ node: attributeNode }) => attributeNode.id !== deletedAttribute.id);
          }
        }));
      },
    });
  }

  function changeAttributeColor(attribute: AttributeDefinitionsNode, color: ColorApi) {
    return changeAttributeColorMutation({
      variables: {
        attributeId: attribute.id,
        color,
      },
      optimisticResponse: {
        updateAttribute: {
          __typename: attribute.__typename,
          id: attribute.id,
          color,
        },
      },
    });
  }

  function changeAttributeName(attribute: AttributeDefinitionsNode, name: string) {
    return changeAttributeNameMutation({
      variables: {
        attributeId: attribute.id,
        name,
      },
      optimisticResponse: {
        updateAttribute: {
          __typename: attribute.__typename,
          id: attribute.id,
          name,
        },
      },
    });
  }

  function addSelectOption(attributeDefinitionId: string, value: string) {
    return addSelectOptionMutation({
      variables: {
        attributeDefinitionId,
        value,
      },
      optimisticResponse: {
        addSelectStringAttributeValue: {
          __typename: 'AttributeTextValue',
          id: 'temp-id',
          value,
        },
      },
      update(cache, { data }) {
        if (!data?.addSelectStringAttributeValue || !product) return;
        addAttributeTextValue(cache, attributeDefinitionId, data?.addSelectStringAttributeValue);
      },
      refetchQueries: () => {
        const queries = [];
        const params = getParams();
        if (params.boardId) queries.push(namedOperations.Query.boardWithConfig);
        // Refetching the docFullNode query cause the created value to be removed from the cache
        // if (params.docId) queries.push(namedOperations.Query.docFullNode);
        return queries;
      },
    });
  }

  function removeSelectOption(attribute: AttributeDefinitionsNode, valueId: string) {
    return removeSelectOptionMutation({
      variables: {
        valueId,
      },
      optimisticResponse: {
        removeSelectStringAttributeValue: {
          __typename: 'AttributeTextValue',
          id: valueId,
          text: '',
        },
      },
      update(cache, { data }) {
        if (!data?.removeSelectStringAttributeValue || !product) return;

        cache.modify({
          id: cache.identify(attribute),
          fields: {
            valuesV2: (values, { readField }) => ({
              ...values,
              edges: values.edges.filter(({ node: nodeRef }: { node: Reference }) => readField('id', nodeRef) !== valueId),
            }),
          },
        });
        const normalizedId = cache.identify({
          id: valueId,
          __typename: 'AttributeTextValue',
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
      refetchQueries: () => (getParams().docId ? [namedOperations.Query.docFullNode] : []),
    });
  }

  function changeSelectOption(valueId: string, value: string) {
    return changeSelectOptionMutation({
      variables: {
        valueId,
        value,
      },
    });
  }

  function removeAttributeDoctype(
    attribute: AttributeDefinitionsNode,
    doctypeId: string,
    docToUpdate?: FullDocWithPublicId,
  ) {
    return removeAttributeDoctypeMutation({
      variables: {
        attributeId: attribute.id,
        doctypeId,
      },
      optimisticResponse: {
        removeAttributeDoctype: {
          ...attribute,
          doctypes: {
            ...attribute.doctypes,
            edges: attribute.doctypes.edges.filter(edge => edge.node.id !== doctypeId),
          },
        },
      },
      update(cache, { data }) {
        if (!data?.removeAttributeDoctype || !product) return;
        const updatedAttribute = data.removeAttributeDoctype;
        const updatedDoctype = getDoctype(doctypeId);

        // Updating attribute doctypes
        cache.modify({
          id: cache.identify(updatedAttribute),
          fields: {
            doctypes: (cachedDoctypes, { readField }) => ({
              ...cachedDoctypes,
              edges: cachedDoctypes.edges.filter(({ node: nodeRef }: { node: Reference }) => readField('id', nodeRef) !== doctypeId),
            }),
          },
        });

        // Updating doctype attributeDefinitions
        if (updatedDoctype) {
          cache.modify({
            id: cache.identify(updatedDoctype),
            fields: {
              attributeDefinitions: (doctypeAttributeDefinitions, { readField }) => ({
                ...doctypeAttributeDefinitions,
                edges: doctypeAttributeDefinitions.edges
                  .filter(({ node: nodeRef }: { node: Reference }) => readField('id', nodeRef) !== attribute.id),
              }),
            },
          });
        }

        // If the unlinked attribute is related to an active doc (like in docPanel), update its attributes
        if (docToUpdate) {
          cache.modify({
            id: cache.identify(docToUpdate),
            fields: {
              attributes: (attributes, { readField }) => ({
                ...attributes,
                edges: attributes.edges
                  .filter(({ node: nodeRef }: { node: Reference }) => {
                    const definitionRef = readField('definition', nodeRef);
                    if (!definitionRef) return true;
                    return readField('id', definitionRef as Reference) !== attribute.id;
                  }),
              }),
            },
          });
        }
      },
      refetchQueries: refetchBoardWithConfigQuery,
    });
  }

  async function moveSelectAttributeValue({
    attributeId,
    valueId,
    position,
    sortedItems,
    boardConfigId,
  }: MoveSelectAttributeValueParams) {
    await moveSelectAttributeValueInValuesListMutation({
      variables: {
        attributeId,
        valueId,
        position,
      },
      update(cache, { data }) {
        if (!data?.moveSelectAttributeValueInValuesList) return;

        const attribute = getAttribute(attributeId);
        if (attribute?.__typename !== 'AttributeSingleSelectDefinition') return;

        cache.modify({
          id: cache.identify(attribute),
          fields: {
            valuesV2: (values) => ({
              ...values,
              edges: sortedItems.map(id => {
                const valueRef = cache.writeFragment({
                  data: { id },
                  fragment: gql`
                    fragment SelectValue on AttributeTextValue {
                      id
                    }
                  `,
                });
                return { node: valueRef };
              }),
            }),
          },
        });

        // If the boardConfigId param is present, we update the board groups as well (used on group dnd)
        if (boardConfigId) {
          const boardConfig = getBoardConfig(boardConfigId);
          if (!boardConfig) return;

          cache.modify({
            id: cache.identify(boardConfig),
            fields: {
              docQuery: (docQuery) => {
                if (docQuery.__typename !== 'BoardQueryWithGroupBy') return docQuery;
                return ({
                  ...docQuery,
                  docGroups: {
                    ...docQuery.docGroups,
                    edges: sortedItems.map(id => {
                      const docGroupRef = cache.writeFragment({
                        data: { id },
                        fragment: gql`
                          fragment DocGroup on DocGroupWithPropertyValue {
                            id
                          }
                        `,
                      });
                      return { node: docGroupRef };
                    }),
                  },
                });
              },
            },
          });
        }
      },
    });
  }
}

export const useAddAttributeSelectOption = () => {
  const [addSelectOptionMutation, { loading }] = useSafeMutation(AddSelectStringAttributeValueDocument, {
    onCompleted: (data) => trackAnalytics(Events.AttributeValueCreated, { name: data.addSelectStringAttributeValue?.value ?? '' }),
  });

  const addSelectOption = async (attributeDefinitionId: string, value: string) => {
    await addSelectOptionMutation({
      variables: {
        attributeDefinitionId,
        value,
      },
      refetchQueries: () => {
        const queries = [];
        const params = getParams();
        if (params.boardId) queries.push(namedOperations.Query.boardWithConfig);
        if (params.docId) queries.push(namedOperations.Query.docFullNode);
        return queries;
      },
      awaitRefetchQueries: true,
    });
  };

  return {
    addSelectOption,
    loading,
  };
};
