import { ListPositionInput } from '@cycle-app/graphql-codegen';
import { SelectOption, SELECT_PANEL_CLEAR_VALUE, SELECT_PANEL_CREATE_VALUE, SelectLine, Warning } from '@cycle-app/ui';
import { useFilteredOptions } from '@cycle-app/ui/components/Selects/SelectPanel/SelectPanel.hooks';
import { AddIcon, SearchIcon } from '@cycle-app/ui/icons';
import { useListNav } from '@cycle-app/utilities';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import { SortableContext } from '@dnd-kit/sortable';
import { useMemo, useState, useCallback, KeyboardEvent } from 'react';

import { useSortableDnd } from 'src/hooks/useSortableDnd';
import { useGetPermission } from 'src/reactives';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';

import { SelectOptionsEditableLineSortable } from './SelectOptionsEditableLineSortable';
import { Container, Input, SelectLineStyled, AddOptionButton } from './SelectOptionsManager.styles';

interface SelectOptionsManagerProps {
  hasSearch?: boolean;
  isMulti: boolean;
  onCreate?: (newItem: string) => void;
  onCreateKeypress?: (newItem: string) => void;
  onDeleteOption?: (optionId: string) => void;
  onEditOption?: (optionId: string, textValue: string) => void;
  onRemove?: VoidFunction;
  onSelect?: (selectedItem: SelectOption) => void;
  onSorted?: (itemId: string, position: ListPositionInput, sortedItem: Array<string>) => void;
  options: SelectOption[];
  selectedValue?: string;
  shouldCreateValueInComponent: boolean;
  showClearOption?: boolean;
  showWarningOnNoneValue: boolean;
  sortable?: boolean;
  warning?: string;
}

export const SelectOptionsManager = ({
  hasSearch = false,
  isMulti,
  onCreate,
  onCreateKeypress,
  onDeleteOption,
  onEditOption,
  onRemove,
  onSelect,
  onSorted,
  options,
  selectedValue,
  shouldCreateValueInComponent,
  showClearOption = false,
  showWarningOnNoneValue,
  sortable,
  warning,
}: SelectOptionsManagerProps) => {
  const {
    canCreateAttributeOption,
    canDeleteAttributeOption,
  } = useGetPermission();
  const {
    filteredOptions, onChange, filterText, resetFilter,
  } = useFilteredOptions({ options });
  const [isAddingOption, setIsAddingOption] = useState(!filteredOptions.length);
  const showNone = showClearOption && !filterText;
  const optionsValues = useMemo(() => [
    ...(showNone ? [SELECT_PANEL_CLEAR_VALUE] : []),
    ...filteredOptions.map(o => o.value),
    ...(onCreate ? [SELECT_PANEL_CREATE_VALUE] : []),
  ], [showNone, filteredOptions, onCreate]);
  const {
    listProps,
    itemProps,
    selected,
  } = useListNav({
    optionsValues,
    value: selectedValue,
    onSelect: async (selectedOptionValue) => {
      if (selectedOptionValue === SELECT_PANEL_CREATE_VALUE) {
        if (canCreateAttributeOption) {
          if (!items.length && !!filterText) {
            onCreate?.(filterText);
            resetFilter();
            const newOption = filteredOptions.find(o => o.label === filterText);
            if (newOption) onSelect?.(newOption);
          } else {
            setIsAddingOption(true);
          }
        } else {
          blockAction();
        }
        return;
      }
      if (selectedOptionValue === SELECT_PANEL_CLEAR_VALUE) {
        onRemove?.();
        return;
      }
      const newOption = filteredOptions.find(o => o.value === selectedOptionValue);
      if (!newOption) return;
      onSelect?.(newOption);
    },
    autoFocus: true,
    enabled: !isAddingOption,
  });
  const {
    items,
    activeId,
    dndContextProps,
  } = useSortableDnd({
    initialItems: optionsValues.filter(o => ![SELECT_PANEL_CLEAR_VALUE, SELECT_PANEL_CREATE_VALUE].includes(o)),
    onFinish: ({
      position, itemMovedId, items: sortedItems,
    }) => {
      onSorted?.(itemMovedId, position, sortedItems);
    },
  });
  const blockAction = () => setLimitationsModal({ action: 'HANDLE_ATTRIBUTES_OPTIONS' });

  return (
    <Container {...listProps}>
      {hasSearch && (
        <Input
          autoFocus
          type="text"
          iconBefore={<SearchIcon />}
          placeholder="Search"
          onChange={onChange}
        />
      )}
      <DndContext
        {...dndContextProps}
        modifiers={[
          restrictToVerticalAxis,
          restrictToWindowEdges,
        ]}
      >
        <SortableContext items={items}>
          {showNone && (
            <SelectLine
              key={SELECT_PANEL_CLEAR_VALUE}
              label="None"
              isEditable={false}
              onClick={onRemove}
              isSelected={selected === SELECT_PANEL_CLEAR_VALUE}
              endSlot={showWarningOnNoneValue || warning ? <Warning /> : undefined}
              {...itemProps}
            />
          )}
          {items.map(itemId => {
            const option = options.find(o => o.value === itemId);
            if (!option) return null;
            return (
              <SelectOptionsEditableLineSortable
                key={option.value}
                option={option}
                onDelete={onDeleteOption ? (optionId) => {
                  if (canDeleteAttributeOption) {
                    onDeleteOption?.(optionId);
                  } else {
                    blockAction();
                  }
                } : undefined}
                onEdit={onEditOption}
                isSortable={sortable && !filterText}
                disableHover={!!activeId && activeId !== itemId}
                isDragging={activeId === itemId}
                onSelect={() => onSelect?.(option)}
                filterText={filterText}
                isSelected={selected === option.value}
                isMulti={isMulti}
                {...itemProps}
              />
            );
          })}
          {!!onCreate && (
            <CreateOptionLine
              isAddingOption={isAddingOption}
              onStopAdding={() => setIsAddingOption(false)}
              onStartAdding={() => {
                if (canCreateAttributeOption) {
                  setIsAddingOption(true);
                } else {
                  blockAction();
                }
              }}
              isSelected={selected === SELECT_PANEL_CREATE_VALUE}
              shouldCreateValueInComponent={shouldCreateValueInComponent}
              value={!items.length && !!filterText ? filterText : undefined}
              onCreateClick={onCreate}
              onCreateKeypress={onCreateKeypress}
              {...itemProps}
            />
          )}
        </SortableContext>
      </DndContext>
    </Container>
  );
};

type CreateOptionLineProps = {
  isAddingOption: boolean;
  isSelected: boolean;
  onStartAdding: VoidFunction;
  onStopAdding: VoidFunction;
  onCreateClick: (value: string) => void;
  onCreateKeypress?: (value: string) => void;
  value?: string;
  shouldCreateValueInComponent: boolean;
};

const CreateOptionLine = ({
  isAddingOption,
  isSelected,
  onCreateKeypress,
  onStartAdding,
  onStopAdding,
  onCreateClick,
  value,
  ...props
}: CreateOptionLineProps) => {
  const [newOptionName, setNewOptionName] = useState('');
  // const { addSelectOption } = useAttributesMutations();
  const handleKeyPressed = useCallback(async (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Escape') {
      e.stopPropagation();
      onStopAdding();
      setNewOptionName('');
    } else if (e.key === 'Enter' && newOptionName) {
      e.stopPropagation();
      onStopAdding();
      setNewOptionName('');
      onCreateKeypress?.(newOptionName);
    }
  }, [newOptionName, onStopAdding, onCreateKeypress]);

  if (value) {
    return (
      <SelectLineStyled
        isSelected={isSelected}
        label={value}
        onChangeLabel={setNewOptionName}
        onKeyUp={handleKeyPressed}
        onBlur={onStopAdding}
        startSlot="Create"
        onClick={() => onCreateClick(value)}
      />
    );
  }

  return isAddingOption
    ? (
      <SelectLineStyled
        isEditable
        startFocused
        $isFocus
        label={newOptionName}
        placeholder="Enter to add option"
        onChangeLabel={setNewOptionName}
        onKeyUp={handleKeyPressed}
        onBlur={onStopAdding}
        {...props}
      />
    )
    : (
      <AddOptionButton
        onClick={onStartAdding}
        size={14}
        forceFocus={isSelected}
        {...props}
      >
        <AddIcon />
        Add option
      </AddOptionButton>
    );
};
